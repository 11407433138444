import { GradientBtn, ModalView, P } from '@curawella/curawella-components'
import { Image } from 'react-bootstrap'

import { getDrLevelAr } from '../../constants/AppConstants'
import { getDate, getTime } from '../../helpers/dateTime'
import { getType } from './CardController'

import styles from './AppointmentCard.module.scss'
import { useContext, useEffect, useState } from 'react'
import useFetch from '../../hooks/useFetch'
import BookCard from '../../components/book-card/BookCard'
import { Context } from '../../../context/Context'

const AppointmentUpcomingCard = ({ appointment, getAppointments }) => {
    const { patientUid, doctor } = useContext(Context)

    const [deleteAppointmentsData, deleteAppointmentsLoading, deleteAppointmentsError, deleteAppointmentsExecute] = useFetch()
    const [rescheduleAppointmentData, rescheduleAppointmentLoading, rescheduleAppointmentError, rescheduleAppointmentExecute] = useFetch()

    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [activeAppointment, setActiveAppointment] = useState(null)

    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [showBookingModal, setShowBookingModal] = useState(false)
    const [selectedAppointment, setSelectedAppointment] = useState(null)

    const [showWarningModal, setShowWarningModal] = useState(false)
    const [disClinicModal, setDisClinicModal] = useState(false)
    const [appointmentType, setAppointmentType] = useState(null)

    const [selectedClinicId, setSelectedClinicId] = useState(null)

    const rescheduleAppointmentBE = (selectedDate, selectedTime) => {
        if (selectedAppointment) {
            rescheduleAppointmentExecute('put', `v2/patient/appointments/${patientUid}/reschedule/${selectedAppointment}`, {
                timestamp: selectedTime
            })
        }
    }
    useEffect(() => {
        if (rescheduleAppointmentData && rescheduleAppointmentData.success) {
            console.log('rescheduleAppointmentData: ', rescheduleAppointmentData)
            setShowBookingModal(false)
            setShowSuccessModal(true)

            getAppointments()
        }
    }, [getAppointments, rescheduleAppointmentData])

    useEffect(() => {
        if (rescheduleAppointmentError) {
            console.log('error while reschedule Appointment', rescheduleAppointmentError)
        }
    }, [rescheduleAppointmentError])

    /////////////////////////////////////////////////////////////////////////////////////////////////////

    const deleteAppointmentBE = () => {
        if (deleteAppointmentsLoading) {
            return ''
        }
        deleteAppointmentsExecute('delete', `v2/patient/appointments/${patientUid}/cancel/${activeAppointment}`)
    }
    useEffect(() => {
        console.log('deleteAppointmentsData, ', deleteAppointmentsData)
        if (deleteAppointmentsData && deleteAppointmentsData.success) {
            if (deleteAppointmentsData.isRefunded === false) {
                setShowWarningModal(true)
            } else {
                setShowSuccessModal(true)
                getAppointments()
            }
            toggleConfirmationModal(false)
        }
    }, [deleteAppointmentsData, getAppointments])

    useEffect(() => {
        if (deleteAppointmentsError) {
            console.log('error while delete appointment', deleteAppointmentsError)
        }
    }, [deleteAppointmentsError])
    /////////////////////////////////////////////////////////////////////////////////////
    const rescheduleAppointment = () => {
        setSelectedAppointment(appointment.orderId)
        toggleBookingModal(true, appointment.appointmentType, appointment.clinicId)
    }

    const toggleConfirmationModal = (value, appointmentID) => {
        if (appointmentID) {
            setActiveAppointment(appointmentID)
        } else {
            setActiveAppointment(null)
        }

        setShowConfirmationModal(value)
    }

    const toggleBookingModal = (value, type, clinicId) => {
        const isDisables = doctor.clinics.find((clinic) => clinic.id === clinicId)

        if (value === true) {
            setAppointmentType(type)
            setSelectedClinicId(clinicId)

            if (!isDisables && type !== 'ONLINE') {
                setDisClinicModal(true)
            } else {
                setShowBookingModal(true)
            }
        } else {
            setShowBookingModal(false)
        }
    }
    return (
        <>
            <div className={styles['appointment-card-container']}>
                <Image loading='lazy' alt='dr-img' src={appointment.avatar} className={styles.icon} />
                <div className={styles.data}>
                    <P type='p1' other='dr-name'>
                        {`${appointment.firstNameAr} ${appointment.lastNameAr}`}
                    </P>
                    <P type='p2' other='dr-level'>
                        {getDrLevelAr[appointment.level]} - {appointment.specialtyAr}
                    </P>

                    <P type='p1' other='name'>
                        نوع الحجز: <span>{getType[appointment.appointmentType]}</span>
                    </P>
                    {appointment.clinicName && (
                        <P type='p1' other='name'>
                            اسم العيادة: <span>{appointment.clinicName}</span>
                        </P>
                    )}
                    <P type='p1' other='date'>
                        التاريخ: <span>{getDate(appointment.timestamp)}</span>
                    </P>
                    <P type='p1' other='price'>
                        الوقت: <span>{getTime(appointment.timestamp)}</span>
                    </P>
                </div>
                <div className={styles['actions-container']}>
                    <div className={styles['cancel-container']} onClick={() => toggleConfirmationModal(true, appointment.orderId)}>
                        <Image loading='lazy' alt='dr-img' src='assets/appointment/icons/cancel.svg' className={styles.icon} />

                        <P type='p3' other='text'>
                            الغاء الموعد
                        </P>
                    </div>
                    <div className={styles['delay-container']} onClick={() => rescheduleAppointment()}>
                        <Image loading='lazy' alt='icon' src='assets/appointment/icons/delay.svg' className={styles.icon} />
                        <P type='p3' other='text'>
                            اعادة جدولة
                        </P>
                    </div>
                </div>
            </div>
            <ModalView
                show={showConfirmationModal}
                setShow={(value) => toggleConfirmationModal(value)}
                type='custom'
                title='إلغاء الموعد'
                customView={
                    <div className={styles['appoint-modal']}>
                        <p>هل تريد إلغاء الموعد ؟</p>
                        <GradientBtn
                            title={deleteAppointmentsLoading ? 'جار الأرسال' : 'إلغاء'}
                            action={deleteAppointmentBE}
                            disabled={deleteAppointmentsLoading}
                            type='redGradient'
                        />
                    </div>
                }
            />

            {showSuccessModal && (
                <ModalView
                    show={showSuccessModal}
                    setShow={(value) => setShowSuccessModal(value)}
                    type='success'
                    title='تأكيد'
                    description='تم تنفيذ طلبك بنجاح'
                    btn={{ title: 'اغلاق', action: () => setShowSuccessModal(false) }}
                />
            )}

            {showWarningModal && (
                <ModalView
                    show={showWarningModal}
                    setShow={(value) => setShowWarningModal(value)}
                    type='confirmation'
                    title='إلغاء موعد '
                    description='لا يمكن تنفيذ طلبك'
                    btn={{ title: 'تواصل معنا', action: () => history.push('/contact-us') }}
                    onCancel={() => setShowWarningModal(false)}
                />
            )}

            {showBookingModal && (
                <ModalView
                    type='custom'
                    customView={
                        <BookCard
                            center={true}
                            selectedAppointmentType={appointmentType}
                            patientUid={patientUid}
                            selectedClinicId={selectedClinicId}
                            disabled={rescheduleAppointmentLoading}
                            onSubmit={(d, t) => rescheduleAppointmentBE(d, t)}
                        />
                    }
                    title='تغيير ميعاد الحجز'
                    show={showBookingModal}
                    setShow={(value) => toggleBookingModal(value)}
                    btn={{ title: '' }}
                />
            )}

            {disClinicModal && (
                <ModalView
                    show={disClinicModal}
                    setShow={(value) => setDisClinicModal(value)}
                    type='custom'
                    title='إعاده جدولة موعد '
                    onCancel={() => setDisClinicModal(false)}
                    customView={
                        <div className={styles['appoint-modal']}>
                            <p>لا يمكن اتمام العملية فى الوقت الحالى , يمكنك التواصل مع الدكتور عبر الرسائل لإختيار موعد جديد</p>
                            <GradientBtn title='أرسل رسالة' action={() => history.push('/chat')} type='gradient' />
                        </div>
                    }
                />
            )}
        </>
    )
}

export default AppointmentUpcomingCard
