import React, { useEffect, useState } from 'react'
import styles from './Login.module.scss'
import { OutlinedInput, GradientBtn, H4, P } from '@curawella/curawella-components'
import useFetch from '../../hooks/useFetch'
import { packageName } from '../../constants/AppConstants'
import { Link } from 'react-router-dom'
import { doctorUid } from '../../../constants'
import firebaseApp from '../../constants/firebaseApp'

const Login = () => {
    const [phone, setPhone] = useState()
    const [password, setPassword] = useState()
    const [phoneError, setPhoneError] = useState()
    const [passwordError, setPasswordError] = useState()
    const [errorMsg, setErrorMsg] = useState('')
    const [data, loading, error, execute] = useFetch()

    const validatePhone = () => {
        // CHECK IF EGYPTIAN NUMBER ADD +2
        const phoneReg = /^[0]{1}[1]{1}[0-9]{9}$/
        const egyptCheck = phoneReg.test(phone)
        // let type = ''

        if (phone.search('@') > -1) {
            return [true, 'email']
        } else if (egyptCheck) {
            return [true, 'egyptPhone']
        }
        return [true, 'globalPhone']
    }

    const handleLogin = () => {
        resetErrors()

        if (!phone) {
            setPhoneError('مطلوب')
            return [false]
        }
        if (!password) {
            setPasswordError('مطلوب')
            return
        }
        const [result, type] = validatePhone()
        if (!result) return

        const body = {
            credential: phone,
            password: password,
            packageName: packageName
        }

        if (type === 'egyptPhone') {
            body.credential = '+2' + phone
        }
        console.log('body: ', body)

        execute('post', 'v2/user/auth/login', body)
    }

    const resetErrors = () => {
        setErrorMsg('')
        setPhoneError('')
        setPasswordError('')
    }

    useEffect(() => {
        if (data) {
            const { authToken, user } = data
            console.log('data', data)
            if (user.results.uid === doctorUid) {
                setErrorMsg('لا يمكنك تسجيل الدخول بهذا البريد الإلكتروني')
            } else {
                firebaseApp
                    .auth()
                    .signInWithCustomToken(authToken)
                    .then(async (response) => firebaseApp.analytics().login())
                    .catch((err) => console.log('error in firebase sign in with custom token: ', err))
            }
        }
    }, [data])

    useEffect(() => {
        if (error) {
            console.log('error while login in : ', error)
            const errorCode = error.code
            if (errorCode === 'auth/user-not-found') {
                setErrorMsg('بيانات غير صحيحة')
            }
        }
    }, [error])

    useEffect(() => {
        window.scrollTo(0, 0)

        const analytics = firebaseApp.analytics()
        analytics.screenView(analytics.screens.login)
    }, [])

    return (
        <div className={styles['login-container']}>
            <div className={styles['login-content']}>
                <div className={styles['form-container']}>
                    <H4>سجل دخولك وكمل رحلتك معنا</H4>

                    <OutlinedInput
                        title='رقم الهاتف/البريد الإلكتروني'
                        type='text'
                        width='100%'
                        value={phone}
                        setValue={(e) => setPhone(e.target.value)}
                        error={phoneError}
                        lang='AR'
                    />
                    <OutlinedInput
                        title='كلمة السر'
                        type='password'
                        width='100%'
                        value={password}
                        setValue={(e) => setPassword(e.target.value)}
                        error={passwordError}
                        lang='AR'
                    />
                    {errorMsg && (
                        <P type='p2' other='error-text'>
                            {errorMsg}
                        </P>
                    )}

                    <GradientBtn
                        title={loading ? 'جار الأرسال' : 'تسجيل الدخول'}
                        type='gradient'
                        align='center'
                        action={() => handleLogin()}
                    />
                    <Link to='/forget-password' className={styles['forget-password-link']}>
                        <P type='p2'>هل نسيت كلمة المرور ؟</P>
                    </Link>
                    <Link to='/register' className={styles['forget-password-link']}>
                        <P type='p2'>لا تملك حساب ؟</P>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Login
