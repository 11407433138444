import React from 'react'
import PropTypes from 'prop-types'
import CardTitle from '../card-title/CardTitle'
import styles from './DoctorAbout.module.scss'

const DoctorAbout = ({ doctor }) => {
    DoctorAbout.propTypes = {
        doctor: PropTypes.object
    }
    return (
        <div className={styles['doctor-about-container']}>
            <CardTitle title='عن الدكتور' icon='/assets/doctor-profile/icons/about.svg' />
            <p className={styles['data-container']}>{doctor.about}</p>
        </div>
    )
}

export default DoctorAbout
