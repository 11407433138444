import React, { useEffect } from 'react'

import styles from './Privacy.module.scss'

import Terms from '../../components/legal-terms/terms'
import firebaseApp from '../../constants/firebaseApp'

const Blog = () => {
    useEffect(() => {
        const analytics = firebaseApp.analytics()
        analytics.screenView(analytics.screens.privacy)
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={styles['container']}>
            <Terms />
        </div>
    )
}

export default Blog
