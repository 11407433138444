import React from 'react'
import styles from './DoctorClinicCard.module.scss'
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react'
import { P, GradientBtn } from '@curawella/curawella-components'

import CardTitle from '../card-title/CardTitle'
import CardTextItem from '../card-text-item/CardTextItem'
import { getWeekDay } from '../../../constants/AppConstants'
import Loading from '../../../components/loading/Loading'

const mapStyles = { width: '100%', height: '100%' }
const containerStyle = { position: 'relative', width: '100%', height: '15em' }

const DoctorClinicCard = ({ google, clinic, handleShowModal }) => {
    const { address, name, phones, price, availableDays, mapLat, mapLong, id } = clinic

    return (
        <div className={styles['clinic-card-container']}>
            <CardTitle title={name} icon='/assets/doctor-profile/icons/clinic.svg' />
            <div className={styles['content']}>
                <CardTextItem title='العنوان' value={address} />

                <div className='map-container'>
                    <Map
                        containerStyle={containerStyle}
                        google={google}
                        zoom={14}
                        style={mapStyles}
                        initialCenter={{ lat: mapLat, lng: mapLong }}
                    >
                        <Marker title={name} name={name} position={{ lat: mapLat, lng: mapLong }} />
                    </Map>
                </div>
                {phones.length > 0 && (
                    <CardTextItem title='رقم الهاتف' value={phones.map((phone) => phone.phone)} />
                )}

                <CardTextItem title='السعر' value={`${price} ج.م`} />
                {availableDays.length > 0 ? (
                    <>
                        <CardTextItem
                            title='الأيام المتاحة'
                            value={availableDays
                                .map((day) => getWeekDay[day])
                                .join('، ')
                                .substring(-2)}
                            errorText='لا يوجد أيام متاحة'
                        />
                        <GradientBtn
                            title='احجز الان'
                            align='left'
                            type='gradient'
                            action={() => handleShowModal('CLINIC', price, id, availableDays)}
                        />
                    </>
                ) : (
                    <P type='p1' other='error-text'>
                        لا يوجد ايام متاحة
                    </P>
                )}
            </div>
        </div>
    )
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyBNNdq9XGEL3Fwe5bE-TfiwLyc71xzL_qg',
    LoadingContainer: Loading
})(DoctorClinicCard)
