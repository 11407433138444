function replaceURLs(message) {
    if (!message) {
        return
    }
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g
    return message.replace(urlRegex, function (url) {
        let hyperlink = url
        if (!hyperlink.match('^https?://')) {
            hyperlink = 'http://' + hyperlink
        }
        console.log('url', url)
        return `<a  href="${url}" target='_blank'>${url}</a>`
    })
}

export default replaceURLs
