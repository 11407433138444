import React, { useState, useEffect, useContext, useCallback } from 'react'
import { SubHeader, EmptyContainer } from '@curawella/curawella-components'

import { doctorUid } from '../../constants/AppConstants'
import useFetch from '../../hooks/useFetch'
import MedicalRecordCard from './medical-record-card/MedicalRecordCard'
import MedicalRecordCustomCard from './medical-record-custom-card/MedicalRecordCustomCard'
import Loading from '../../components/loading/Loading'

import styles from './MedicalRecord.module.scss'
import firebaseApp from '../../constants/firebaseApp'

const MedicalRecord = ({ custom, Context }) => {
    const [activeCard, setActiveCard] = useState(null)
    const [medicalRecordsData, medicalRecordsLoading, medicalRecordsError, medicalRecordsExecute] = useFetch()

    const { patientUid } = useContext(Context)

    // GET DATA OF ALL MEDICAL RECORDS
    const getMedicalRecords = useCallback(() => {
        medicalRecordsExecute('get', `v2/patient/medicalRecords/${patientUid}/?doctorUid=${doctorUid}`)
    }, [medicalRecordsExecute, patientUid])

    useEffect(() => {
        const analytics = firebaseApp.analytics()

        analytics.screenView(analytics.screens.medicalRecord)
    }, [])

    useEffect(() => {
        getMedicalRecords()
    }, [getMedicalRecords])

    // toggle active card

    const toggleActiveCard = (id) => {
        setActiveCard(id)
    }

    // ================RECEIVE DATA ================

    // WHEN GET DATA OF ALL MEDICAL RECORDS
    useEffect(() => {
        console.log('medicalRecordsData: ', medicalRecordsData)
        if (medicalRecordsData) {
            const { results } = medicalRecordsData
            if (results.length > 0) {
                setActiveCard(results[0].id)
            }
        }
    }, [medicalRecordsData])

    // ================ERRORS================

    useEffect(() => {
        console.log('medicalRecordsError: ', medicalRecordsError)
    }, [medicalRecordsError])

    if (medicalRecordsLoading) {
        return <Loading />
    }
    if (!medicalRecordsData || medicalRecordsData.results.length === 0) {
        return <EmptyContainer title='لا يوجد سجل طبي حتي الان' img='/assets/medical-record/imgs/medicalRecord.png' custom={true} />
    }

    return (
        <div className={styles['medical-record-container']}>
            <SubHeader title='السجل الطبي' />

            <div className={styles['medical-record-content']}>
                <div className={styles['first-content']}>
                    {medicalRecordsData.results.map((item, index) =>
                        custom ? (
                            <MedicalRecordCustomCard
                                data={item}
                                key={index}
                                setActiveCard={() => toggleActiveCard(item.id)}
                                activeId={activeCard}
                            />
                        ) : (
                            <MedicalRecordCard data={item} key={index} />
                        )
                    )}
                </div>
            </div>
        </div>
    )
}

export { MedicalRecord }
