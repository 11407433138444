import React from 'react'
import PropTypes from 'prop-types'
import styles from './Msg.module.scss'
import { Image } from 'react-bootstrap'
import { P } from '@curawella/curawella-components'
import { getTime } from '../../../helpers/dateTime'
import replaceURLs from '../../../helpers/urlReplacer'

const Msg = ({ msg, setRenderGallery, setImgs, patientUid }) => {
    Msg.propTypes = {
        msg: PropTypes.object,
        setRenderGallery: PropTypes.func,
        setImgs: PropTypes.func,
        patientUid: PropTypes.string
    }
    const html = replaceURLs(msg.message)

    const handleImgClick = () => {
        const arr = []
        if (msg.sources) {
            msg.sources.forEach((img) => {
                arr.push({
                    original: img.link,
                    thumbnail: img.link
                })
            })
            setImgs(arr)
        } else if (msg.source) {
            setImgs([{ original: msg.source, thumbnail: msg.source }])
        }
        setRenderGallery(true)
    }
    return (
        <div
            id={msg.messageId}
            className={`${styles['msg-container']} ${msg.senderUid === patientUid ? styles['sender'] : styles['receiver']}`}
        >
            <div className={styles['msg-content']}>
                {msg.sources.length > 0 ? (
                    <RenderImgs sources={msg.sources && msg.sources} handleImgClick={() => handleImgClick()} />
                ) : (
                    <P type='p2' other='content'>
                        <p dangerouslySetInnerHTML={{ __html: html }} />
                    </P>
                )}
            </div>
            <P type='p2' other='time'>
                {getTime(msg.timeStamp)}
            </P>
        </div>
    )
}

export default Msg

const RenderImgs = ({ source, sources, handleImgClick }) => {
    if (sources) {
        return (
            sources &&
            sources.map((image, index) => (
                <Image src={image.link} key={index} loading='lazy' className={styles['image']} onClick={() => handleImgClick()} />
            ))
        )
    }

    return <Image src={source} loading='lazy' className={styles['image']} onClick={() => handleImgClick()} />
}
