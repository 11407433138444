import React from 'react'
import PropTypes from 'prop-types'

import ServiceUpcomingCard from './ServiceUpc'
import HistoryServiceCrd from './ServiceHis'
import AppointmentUpcomingCard from './AppointmentUpcCard'
import AppointmentHisCard from './AppointmentHisCard '

export const getType = {
    ONLINE: 'مكالمة فيديو',
    CLINIC: 'عيادة'
}

const APPOINTMENT_TYPE = {
    history: 'history',
    appointment: 'appointment',
    service: 'service'
}

const CardController = ({ appointment, type, getAppointments }) => {
    CardController.propTypes = {
        appointment: PropTypes.object,
        type: PropTypes.oneOf(Object.keys(APPOINTMENT_TYPE))
    }

    if (type === 'service') {
        return <ServiceUpcomingCard appointment={appointment} />
    }
    if (type === 'appointment') {
        return <AppointmentUpcomingCard appointment={appointment} getAppointments={getAppointments} />
    }

    if (type === 'history' && appointment.type === 'SERVICE') {
        return <HistoryServiceCrd appointment={appointment} />
    }
    if (type === 'history' && appointment.type === 'APPOINTMENT') {
        return <AppointmentHisCard appointment={appointment} />
    }
}

export default CardController
