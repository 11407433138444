import React, { useEffect, useState } from 'react'
import styles from './ForgetPassword.module.scss'
import { Image } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { OutlinedInput, GradientBtn, SubHeader, ModalView, H4, P, useFetch } from '@curawella/curawella-components'
import { appId } from '../../constants/AppConstants'
import firebaseApp from '../../constants/firebaseApp'
const ForgetPassword = () => {
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(null)

    const [data, loading, error, execute] = useFetch()

    const [showModal, setShowModal] = useState(false)
    const [msg, setMsg] = useState('')
    const history = useHistory()

    const navigateToLogin = () => {
        history.push('/login')
    }

    const validateEmail = () => {
        let err = ''
        let isValid = true

        if (!email) {
            err = 'مطلوب'
            isValid = false
        } else if (email.length < 6) {
            isValid = false
            err = 'غير صحيح'
        }

        return { err, isValid }
    }

    const submitForm = (e) => {
        e.preventDefault()
        if (loading) {
            return
        }
        setEmailError(null)

        const { err, isValid } = validateEmail()
        setEmailError(err)

        if (!isValid) {
            return
        }

        const body = {
            credential: email,
            appId: appId
        }

        execute('post', 'v2/user/auth/forgetPassword', body)
    }

    useEffect(() => {
        if (data) {
            console.log('data: ', data)
            setEmail('')
            if (data.code === 'email-sent') {
                setMsg('لقد أرسلنا بريد الإلكتروني لك ، يرجى التحقق من بريدك الإلكتروني لاختيار كلمة مرور جديدة ')
            } else if (data.code === 'sms-sent') {
                setMsg('لقد أرسلنا رسالة لك ، يرجى التحقق من هاتفك لاختيار كلمة مرور جديدة')
            } else {
                setShowModal(true)
            }
        }
    }, [data])

    useEffect(() => {
        if (error) {
            console.log('error: ', error)
            if (error.code === 'auth/user-not-found') {
                setEmailError('البريد الإلكتروني أو رقم هاتف غير صحيح')
            }
            if (error.code === 'bad-credential') {
                setShowModal(true)
            }
        }
    }, [error])

    useEffect(() => {
        const analytics = firebaseApp.analytics()
        analytics.screenView(analytics.screens.forgetPassword)
    }, [])

    return (
        <div className={styles['forget-password-container']}>
            <SubHeader title='استعادة كلمة المرور' />
            <div className={styles['forget-password-content']}>
                <form className={styles['data-container']} onSubmit={submitForm}>
                    <H4>هل نسيت كلمة المرور !</H4>
                    <P type='p1'>من فضلك ادخل البريد الإلكتروني او رقم الهاتف الخاص بك لاعادة تعيين كلمة المرور</P>
                    <OutlinedInput
                        title='البريد الإلكتروني او رقم الهاتف'
                        value={email}
                        setValue={(e) => setEmail(e.target.value)}
                        placeholder='البريد الإلكتروني او رقم الهاتف'
                        error={emailError}
                    />

                    <GradientBtn
                        title={<input className={styles['submit-btn']} type='submit' value='ارسال' />}
                        type='gradient'
                        align='start'
                        disabled={loading}
                    />
                </form>
                <Image
                    loading='lazy'
                    className={styles['password-img']}
                    alt='password-img'
                    src='/assets/forget-password/imgs/password.png'
                />
            </div>

            <ModalView
                show={msg !== ''}
                setShow={() => setMsg('')}
                type='success'
                title='استعادة كلمة المرور'
                description={msg}
                btn={{ title: 'تسجيل الدخول', action: () => navigateToLogin() }}
            />

            <ModalView
                show={showModal}
                setShow={(v) => setShowModal(v)}
                type='custom'
                title='استعادة كلمة المرور'
                customView={
                    <div className={styles['pass-modal']}>
                        <p>لا يمكن استعادة كلمة المرور بإستخدام هذا الرقم برجاء التواصل مع الدعم الفني لإستعاده كلمة المرور</p>
                        <GradientBtn title='الدعم الفنى' action={() => history.push('/contact-us')} type='gradient' />
                    </div>
                }
            />
        </div>
    )
}

export default ForgetPassword
