import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import styles from './DownloadAppClinic.module.scss'
import { Image } from 'react-bootstrap'
import SVG from 'react-inlinesvg'
import { H2, H3 } from '@curawella/curawella-components'
import { Context } from '../../../../context/Context'

const images = [
    '/assets/home-clinic/img/chat.png',
    '/assets/home-clinic/img/booking.png',
    '/assets/home-clinic/img/doctorProfile.png',
    '/assets/home-clinic/img/medicalRecord.png',
    '/assets/home-clinic/img/home.png'
]
const direction = 'rtl'

const DownloadAppClinic = () => {
    const [currentImgIndex, setCurrentImgIndex] = useState(0)
    const { doctor } = useContext(Context)
    const { playStoreLink, appStoreLink } = doctor

    useEffect(() => {
        const time = setTimeout(() => {
            if (currentImgIndex < images.length - 1) {
                setCurrentImgIndex(currentImgIndex + 1)
            } else {
                setCurrentImgIndex(0)
            }
        }, 2000)

        return () => clearTimeout(time)
    }, [currentImgIndex])

    if (!playStoreLink && !appStoreLink) {
        return ''
    }

    return (
        <div className={`${styles['download-app-container']} ${styles[direction]}`} style={{ direction: direction }}>
            <div className={styles['download-app-content']}>
                <div className={styles['texts-container']}>
                    <Image src='/assets/home-clinic/icons/Group 2233.svg' loading='lazy' className={styles.decoration} alt='decoration' />
                    <H2>حمل التطبيق</H2>
                    <hr />
                    <H3>حمل تطبيق طبيبك الان واحجز مواعيدك وتابع سجلك الطبي بسهولك</H3>

                    <div className={styles['btns-container']}>
                        {appStoreLink && (
                            <a href={appStoreLink} target='_blank'>
                                <div className={styles['btn-container']}>
                                    <div className={styles['text-container']}>
                                        <p className={styles['small']}>Download on the</p>
                                        <p className={styles['title']}>App Store</p>
                                    </div>
                                    <SVG src='/assets/home-clinic/icons/apple.svg' className={styles['icon']} />
                                </div>
                            </a>
                        )}
                        {playStoreLink && (
                            <a href={playStoreLink} target='_blank'>
                                <div className={styles['btn-container']}>
                                    <div className={styles['text-container']}>
                                        <p className={styles['small']}>Get it on</p>
                                        <p className={styles['title']}>Google Play</p>
                                    </div>
                                    <SVG src='/assets/home-clinic/icons/google.svg' className={styles['icon']} />
                                </div>
                            </a>
                        )}
                    </div>
                </div>
                <div className={styles['preview-container']}>
                    <div className={styles['preview-content']}>
                        <Image src={images[currentImgIndex]} loading='lazy' className={`${styles.mockup}`} alt='decoration' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DownloadAppClinic

DownloadAppClinic.propTypes = {
    direction: PropTypes.string,
    title: PropTypes.string
}
