import React from 'react'
import styles from './ContactUsForm.module.scss'
import { Image } from 'react-bootstrap'
import { GradientBtn, OutlinedInput } from '@curawella/curawella-components'

const msg = ` 
من فضلك إشرح مشكلتك هنا بشكل مفصل لكي نتمكن من تقديم أفضل مساعدة لك`

const ContactUsForm = ({
    data,
    setData,
    errors,
    setErrors,
    onSubmit,
    loading,
    isAuthenticated
}) => {
    const onChange = (e) => {
        const { name, value } = e.target

        setData((prev) => ({ ...prev, [name]: value }))
        setErrors((prev) => ({ ...prev, [name]: validateString(name, value) }))
    }

    return (
        <div className={styles['contact-us-container']}>
            <div className={styles['form']}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        onSubmit()
                    }}
                >
                    {!isAuthenticated && (
                        <div className={styles['inputs-container']}>
                            <OutlinedInput
                                title='الأسم'
                                width='49%'
                                value={data.name}
                                setValue={onChange}
                                error={errors.name}
                                name={'name'}
                                required
                            />
                            <OutlinedInput
                                title='رقم الهاتف'
                                width='49%'
                                value={data.phone}
                                setValue={onChange}
                                error={errors.phone}
                                name={'phone'}
                                required
                            />
                        </div>
                    )}

                    <OutlinedInput
                        title='موضوع الرسالة'
                        type='text'
                        value={data.subject}
                        setValue={onChange}
                        error={errors.subject}
                        name={'subject'}
                        required
                    />
                    <OutlinedInput
                        title='الرسالة'
                        type='textarea'
                        placeholder={msg}
                        value={data.body}
                        setValue={onChange}
                        error={errors.body}
                        name={'body'}
                        required
                    />
                    <GradientBtn
                        title='إرسال'
                        direction='right'
                        type='gradient'
                        action={onSubmit}
                        disabled={loading}
                    />
                </form>
            </div>
            <Image
                src='/assets/contact-us/imgs/contact.png'
                loading='lazy'
                className={styles['contact-us-img']}
                alt='contact-us'
            />
        </div>
    )
}

export default ContactUsForm

const validations = {
    name: {
        invalidErr: 'مطلوب',
        min: 5,
        minErr: 'أسم غير صحيح',
        max: 45,
        maxErr: 'أسم غير صحيح'
    },
    phone: {
        customPattern: /^[0-9+]{7,15}$/,
        invalidErr: 'رقم هاتف غير صحيح',
        min: 7,
        minErr: '',
        max: 15,
        maxErr: ''
    },
    subject: {
        invalidErr: 'مطلوب',
        min: 3,
        minErr: 'مطلوب',
        max: 45,
        maxErr: 'موضوع الرسالة لا يجب ان يتخطى ال ٤٥ حرف'
    },
    body: {
        invalidErr: 'مطلوب',
        min: 3,
        minErr: 'مطلوب',
        max: 500,
        maxErr: 'الرسالة لا يجب ان تتخطى ال ٥٠٠ حرف'
    }
}

function validateString(name, value) {
    const valid = validations[name]
    let err = ''

    if (!value || value.trim() === '') {
        err = valid.invalidErr
    } else if (valid.customPattern && !valid.customPattern.test(value)) {
        err = valid.invalidErr
    } else if (value.length < valid.min) {
        err = valid.minErr
    } else if (value.length > valid.max) {
        err = valid.maxErr
    }

    return err
}
