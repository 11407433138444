import React from 'react'
import PropTypes from 'prop-types'
import styles from './BlogCard.module.scss'
import { Link } from 'react-router-dom'
import { Image } from 'react-bootstrap'
import { P } from '@curawella/curawella-components'
import { getDate } from '../../../helpers/dateTime'

const BlogCard = ({ blog }) => {
    return (
        <div className={styles['blog-card-container']}>
            <div className={styles['img-container']}>
                <Image
                    src={blog.image ? blog.image[0] : '/assets/blog/img/no-img.png'}
                    alt='blog-img'
                    className={styles['blog-img']}
                    loading='lazy'
                />
            </div>
            <div className={styles['content']}>
                <div className={styles['date-container']}>
                    <P type='p1' other='date'>
                        {getDate(blog.postTime)}
                    </P>
                </div>
                <P type='title' other='title'>
                    {blog.title}
                </P>

                <P type='p1' other='description'>
                    {blog.content}
                </P>

                <Link to={{ pathname: `/blog/${blog.key}` }}>
                    <P type='p2'>اقرأ المزيد</P>
                </Link>
            </div>
        </div>
    )
}

export default BlogCard

BlogCard.propTypes = {
    blog: PropTypes.any
}
