import React from 'react'
import PropTypes from 'prop-types'
import styles from './CardTitle.module.scss'
import { Image } from 'react-bootstrap'
import { P } from '@curawella/curawella-components'

const CardTitle = ({ title, icon }) => {
    CardTitle.propTypes = {
        title: PropTypes.string
    }
    return (
        <div className={styles['card-title-container']}>
            <div className={styles['icon-container']}>
                <Image loading='lazy' alt='about-icon' src={icon} className={styles['icon']} />
            </div>
            <P type='p1'>{title}</P>
        </div>
    )
}

export default CardTitle
