import React from 'react'
import PropTypes from 'prop-types'
import styles from './CardItem.module.scss'
import { Image } from 'react-bootstrap'
import cardIcon from './assets/icons/card.svg'
import TrashIcon from './assets/icons/trash.svg'
import correctIcon from './assets/icons/check.svg'
import { P } from '@curawella/curawella-components'
import SVG from 'react-inlinesvg'

const CardItem = ({ card, canRemove, deleteCard, hover, action, active }) => {
    const handleClick = () => {
        if (action) {
            action()
        }
    }

    return (
        <div
            className={`${styles['saved-card-container']} ${hover ? styles['hover'] : ''} ${active ? styles['active'] : ''}`}
            onClick={handleClick}
        >
            <Image src={cardIcon} loading='lazy' alt='card-icon' className={styles['card-icon']} />
            <div className={styles['data-content']}>
                <P type='p1' other='type'>
                    {card.type}
                </P>
                <P type='title' other='last-chars'>
                    {card.pan}
                </P>
            </div>
            {canRemove && (
                <div className={styles['trash-container']} onClick={() => deleteCard(true, card.id)}>
                    <SVG src={TrashIcon} className={styles['trash-icon']} />
                </div>
            )}

            {active && <SVG src={correctIcon} className={styles['check-icon']} />}
        </div>
    )
}

export default CardItem

CardItem.propTypes = {
    card: PropTypes.object,
    canRemove: PropTypes.bool,
    deleteCard: PropTypes.func,
    hover: PropTypes.bool,
    action: PropTypes.func,
    active: PropTypes.bool
}
