import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import ContextProvider from './context/Context'

ReactDOM.render(
    <ContextProvider>
        <Router>
            <App />
        </Router>
    </ContextProvider>,
    document.getElementById('root')
)

//  yarn add -D prettier@2.4.1  @react-native-community/eslint-config@3.0.1 eslint-plugin-prettier
