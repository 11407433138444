import React, { useEffect, useRef, useState } from 'react'
import styles from './Registration.module.scss'
import { useHistory } from 'react-router-dom'
import legalTerms from '../../constants/LegalTerms.json'
import PrivacyPolicy from '../../constants/PrivacyPolicy.json'
import { GradientBtn, useFetch, ModalView } from '@curawella/curawella-components'
import FullForm from '../../components/full-form/FullForm'
import { getCountries } from 'countrycitystatejson'
import moment from 'moment'
import Regex from '../../constants/Regex'
import firebaseApp from '../../constants/firebaseApp'
import { doctorUid } from '../../../constants'

const nameReg = /^[(\u0621-\u064Aa-zA-Z]+$/
const emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]{2,}.[A-Z]{2,4}$/i

const countriesAll = getCountries()

const Registration = () => {
    const [user, setUser] = useState({
        firstName: null,
        secondName: '',
        thirdName: '',
        lastName: null,
        email: null,
        phone: null,
        bd: null,
        country: countriesAll[64].native,
        city: null,
        gender: null,
        password: null,
        policy: null
    })
    const [phoneCode, setPhoneCode] = useState(null)

    const [userErrors, setUserErrors] = useState({
        firstName: null,
        secondName: null,
        thirdName: null,
        lastName: null,
        email: null,
        phone: null,
        bd: null,
        country: null,
        city: null,
        gender: null,
        weight: null,
        height: null,
        password: null,
        policy: null
    })
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [data, loading, error, execute] = useFetch()
    const history = useHistory()
    const ref = useRef(null)

    useEffect(() => {
        const analytics = firebaseApp.analytics()
        analytics.screenView(analytics.screens.registration)
    }, [])

    const handleRegister = () => {
        const analytics = firebaseApp.analytics()
        analytics.logEvent(analytics.events.signup)

        const { firstName, secondName, thirdName, lastName, email, phone, country, city, bd, gender, password, policy } = user

        const { isValid, errors } = validateInputs(
            firstName,
            secondName,
            thirdName,
            lastName,
            email,
            phone,
            city,
            bd,
            country,
            gender,
            password
        )
        console.log('errors', errors)
        setUserErrors({
            ...errors,
            policy: !policy ? 'يجب عليك الموافقة على شروط الإستخدام وسياسة الخصوصية' : ''
        })

        const body = {
            firstName,
            secondName,
            thirdName,
            lastName,
            password,
            email,
            bd: bd ? bd : undefined,
            country,
            city: city && city !== 'اختار' ? city : undefined,
            phone: `${phoneCode}${user.phone}`,
            gender: user.gender === '1' ? true : false,
            createdBy: doctorUid
        }

        if (!isValid || !policy) {
            ref.current.scrollIntoView({ behavior: 'smooth' })
            return
        }

        execute('post', 'v2/user/auth', body)
    }

    useEffect(() => {
        if (data) {
            console.log('created successfully: ', data)
            setUser({})
            setShowConfirmationModal(true)
        }
    }, [data])

    useEffect(() => {
        if (error) {
            const errors = handleErrors(error.code)
            setUserErrors((prev) => ({ ...prev, ...errors }))
        }
        console.log('error in creating: ', error)
    }, [error])

    const navigateToLogin = () => {
        setShowConfirmationModal(false)
        history.push('/login')
    }

    return (
        <div className={styles['registration-container']}>
            <div className={styles['registration-content']}>
                <div className={styles['data']}>
                    <h4 ref={ref}>سجل حسابك وابدأ رحلتك معنا</h4>

                    <FullForm
                        user={user}
                        setUser={(x) => setUser(x)}
                        userErrors={userErrors}
                        legalTerms={legalTerms}
                        PrivacyPolicy={PrivacyPolicy}
                        phoneCode={phoneCode}
                        setPhoneCode={setPhoneCode}
                    />

                    <GradientBtn title='تسجيل الحساب' type='gradient' align='center' action={() => handleRegister()} disabled={loading} />
                </div>
            </div>

            <ModalView
                show={showConfirmationModal}
                setShow={(value) => setShowConfirmationModal(value)}
                type='success'
                title='مبروك'
                description='تم تسجيل حسابك بنجاح'
                btn={{ title: 'الذهاب لتسجيل الدخول', action: () => navigateToLogin(false) }}
            />
        </div>
    )
}

export default Registration

/**
 *
 * @param {string} code
 * @returns {Object}
 */
function handleErrors(code) {
    switch (code) {
        case 'auth/email-already-exists':
            return { email: 'البريد الإلكتروني مستخدم' }
        case 'auth/phone-number-already-exists':
            return { phone: 'رقم الهاتف مستخدم' }
        case 'auth/invalid-phone-number':
            return { phone: 'رقم الهاتف غير صحيح' }
        case 'auth/invalid-password':
            return { password: 'كلمة مرور غير صالحة' }
        case 'auth/invalid-email':
            return { email: 'البريد الألكتروني غير صحيح' }
        default:
            return { password: 'حدث خطأ برجاء إعادة المحاولة' }
    }
}

/**
 *
 * @param {string} firstName
 * @param {string} secondName
 * @param {string} thirdName
 * @param {string} lastName
 * @param {string} email
 * @param {string} phone
 * @param {string} country
 * @param {string} city
 * @param {string} bd
 * @param {string} gender
 * @param {string} password
 * @returns {{isValid: boolean, errors: Object}}
 */
function validateInputs(firstName, secondName, thirdName, lastName, email, phone, city, bd, country, gender, password) {
    let isValid = true
    const errors = {
        firstName: '',
        secondName: '',
        thirdName: '',
        lastName: '',
        email: '',
        phone: '',
        country: '',
        city: '',
        bd: '',
        gender: '',
        password: ''
    }
    if (!firstName) {
        errors.firstName = 'أدخل أسمك الاول'
        isValid = false
    } else if (!nameReg.test(firstName)) {
        errors.firstName = 'يجب أن يكون الأسم باللغة العربية أو الإنجليزية فقط'
        isValid = false
    } else if (firstName.length < 2) {
        errors.firstName = 'يجب أن يكون الاسم بحد أدنى حرفين '
        isValid = false
    } else if (firstName.length > 15) {
        errors.firstName = 'يجب أن يكون الأسم بحد أقصى 15 حرفًا '
        isValid = false
    }

    if (secondName !== '') {
        if (!nameReg.test(secondName)) {
            errors.secondName = 'يجب أن يكون الأسم باللغة العربية أو الإنجليزية فقط'
            isValid = false
        } else if (secondName.length < 2) {
            errors.secondName = 'يجب أن يكون الاسم بحد أدنى حرفين '
            isValid = false
        } else if (secondName.length > 15) {
            errors.secondName = 'يجب أن يكون الأسم بحد أقصى 15 حرفًا '
            isValid = false
        }
    }
    if (thirdName !== '') {
        if (!nameReg.test(thirdName)) {
            errors.thirdName = 'يجب أن يكون الأسم باللغة العربية أو الإنجليزية فقط'
            isValid = false
        } else if (thirdName.length < 2) {
            errors.thirdName = 'يجب أن يكون الاسم بحد أدنى حرفين '
            isValid = false
        } else if (thirdName.length > 15) {
            errors.thirdName = 'يجب أن يكون الأسم بحد أقصى 15 حرفًا '
            isValid = false
        }
    }

    if (!lastName) {
        errors.lastName = 'أدخل أسمك الأخير'
        isValid = false
    } else if (!nameReg.test(lastName)) {
        errors.lastName = 'يجب أن يكون الأسم باللغة العربية أو الإنجليزية فقط '
        isValid = false
    } else if (lastName.length < 2) {
        errors.lastName = 'يجب أن يكون الأسم بحد أدنى حرفين '
        isValid = false
    } else if (lastName.length > 15) {
        errors.lastName = 'يجب أن يكون الأسم بحد أقصى 15 حرفًا '
        isValid = false
    }

    if (bd && !Regex.date.test(bd)) {
        errors.bd = 'تاريخ الميلاد غير صحيح'
        isValid = false
    } else if (bd && moment(bd).isAfter(moment())) {
        errors.bd = 'لا يمكن تحديد تاريخ الميلاد في المستقبل'
        isValid = false
    }

    if (!gender) {
        errors.gender = 'حدد نوع الجنس'
        isValid = false
    }

    if (!country || country.localeCompare('اختار') === 0) {
        errors.country = 'أختر الدولة'
        isValid = false
    }

    if (!phone) {
        errors.phone = 'أدخل رقم الهاتف'
        isValid = false
    } else if (!/^[0-9]+$/.test(phone)) {
        errors.phone = 'رقم الهاتف غير صحيح'
        isValid = false
    }
    if (!email) {
        errors.email = 'أدخل بريدك الإلكتروني'
        isValid = false
    } else if (!emailReg.test(email)) {
        errors.email = 'البريد الإلكتروني غير صحيح'
        isValid = false
    }
    if (!password) {
        errors.password = 'أدخل كلمة السر'
        isValid = false
    } else if (password.length < 6) {
        errors.password = 'كلمة السر يجب أن تكون أكثر من 5 أرقام أو حروف'
        isValid = false
    }

    return { isValid, errors }
}
