import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Form, Image } from 'react-bootstrap'
import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'

import Loading from '../../../components/loading/Loading'
import Msg from '../msg/Msg'

import styles from './Msgs.module.scss'

const Msgs = ({
    msgs,
    selectedFiles,
    setRenderGallery,
    setImages,
    msg,
    setMsg,
    sendMsg,
    setSelectedFiles,
    show,
    setShow,
    custom,
    patientUid,
    uploadState,
    fillChat,
    topReached,
    sendLoading
}) => {
    const handleSelectedImages = (files) => {
        const arr = []
        for (const key in files) {
            const element = files[key]
            if (typeof element === 'object') {
                arr.push(element)
            }
        }
        setSelectedFiles([...selectedFiles, ...arr])
    }

    const handleRemoveImg = (file) => {
        const arr = selectedFiles.filter((x) => x !== file)
        setSelectedFiles([...arr])
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handelSend()
        }
    }
    const handelSend = () => {
        if (msg === '' && selectedFiles.length < 1) {
            return ''
        } else {
            sendMsg()
        }
    }
    if (uploadState) {
        return <Loading />
    }
    return (
        <div
            className={`${styles['msgs-container']} ${show === 'contacts' && styles['hide']} ${
                custom ? styles['custom'] : ''
            }`}
        >
            <RenderMsgs
                msgs={msgs}
                setRenderGallery={(value) => setRenderGallery(value)}
                setImgs={(value) => setImages(value)}
                patientUid={patientUid}
                fillChat={fillChat}
                topReached={topReached}
            />

            <div className={styles['msgs-input-container']}>
                <div className={styles['msg-input-file-container']}>
                    <Form.Control
                        type='file'
                        className={styles['msg-input-file']}
                        accept='image/png, image/jpg'
                        multiple={true}
                        onChange={(e) => {
                            handleSelectedImages(e.target.files)
                        }}
                    />
                    <Image src='/assets/chat/icons/gallery.svg' alt='gallery-icon' />
                </div>
                {selectedFiles.length > 0 ? (
                    <div className={styles['selected-imgs-container']}>
                        {selectedFiles &&
                            selectedFiles.map((file, index) => (
                                <div className={styles['selected-img-container']} key={index}>
                                    <SVG
                                        src='/assets/chat/icons/remove.svg'
                                        className={styles['delete-icon-container']}
                                        onClick={() => handleRemoveImg(file)}
                                    />

                                    {/* <div
                                        className={styles['delete-icon-container']}
                                        onClick={() => handleRemoveImg(file)}>
                                        {/* <TrashIcon className={styles['delete-icon']} /> */}
                                    {/* <SVG src={TrashIcon} className={styles['delete-icon']} />
                                    </div> */}

                                    <Image
                                        src={file && URL.createObjectURL(file)}
                                        alt='selected-img'
                                        className={styles['selected-img']}
                                    />
                                </div>
                            ))}
                    </div>
                ) : (
                    <Form.Control
                        type='text'
                        className={styles['msg-input']}
                        placeholder='اكتب رسالتك'
                        value={msg}
                        onChange={(e) => setMsg(e.target.value)}
                        onKeyPress={(e) => handleKeyPress(e)}
                    />
                )}
                <button
                    className={styles['msg-send-container']}
                    onClick={sendLoading ? () => {} : handelSend}
                >
                    <Image
                        src='/assets/chat/icons/send.svg'
                        alt='send-icon'
                        className={styles['msg-send-icon']}
                    />
                </button>
            </div>
        </div>
    )
}

export default Msgs

const RenderMsgs = ({ msgs, setRenderGallery, setImgs, patientUid, fillChat, topReached }) => {
    const fetchMore = () => fillChat(msgs[msgs.length - 1].messageId)

    return (
        <>
            <div
                id='msgs-data'
                // eslint-disable-next-line react-native/no-inline-styles
                style={{
                    height: 500,
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column-reverse'
                }}
                className={styles['msgs-data']}
            >
                <InfiniteScroll
                    dataLength={msgs.length}
                    next={fetchMore}
                    // eslint-disable-next-line react-native/no-inline-styles
                    style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
                    inverse={true} //
                    hasMore={topReached ? false : true}
                    loader={<Loading />}
                    // children={msgs}
                    scrollableTarget='msgs-data'
                    endMessage={''}
                >
                    {msgs.map((msg, index) => (
                        <Msg
                            msg={msg}
                            key={index}
                            setRenderGallery={(value) => setRenderGallery(value)}
                            setImgs={(value) => setImgs(value)}
                            patientUid={patientUid}
                        />
                    ))}
                </InfiniteScroll>
            </div>
        </>
    )
}

Msgs.propTypes = {
    msgs: PropTypes.array,
    selectedFiles: PropTypes.array,
    setRenderGallery: PropTypes.func,
    setImages: PropTypes.func,
    msg: PropTypes.string,
    setMsg: PropTypes.func,
    sendMsg: PropTypes.func,
    setSelectedFiles: PropTypes.func,
    show: PropTypes.string,
    setShow: PropTypes.func,
    selectedContact: PropTypes.any,
    custom: PropTypes.bool,
    patientUid: PropTypes.string,
    uploadState: PropTypes.string,
    fillChat: PropTypes.func,
    topReached: PropTypes.bool
}
