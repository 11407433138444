import React, { useEffect, useState } from 'react'
import { EmptyContainer, H4 } from '@curawella/curawella-components'
import { Image } from 'react-bootstrap'

import PropTypes from 'prop-types'
import CardController from './CardController'
import Pagination from '../../components/pagination/pagination'
import styles from './ListOfAppointments.module.scss'

const PERIOD_TYPES = {
    next: 'next',
    prev: 'prev'
}
const APPOINTMENT_TYPE = {
    history: 'history',
    appointment: 'appointment',
    service: 'service'
}
const ListOfAppointments = ({ title, icon, data, period, type, getAppointments, custom, noDataTitle, loading }) => {
    const [pages, setPages] = useState({})

    if ((!Array.isArray(data) || data.length === 0) && type !== 'service') {
        return (
            <EmptyContainer
                img='/assets/appointment/imgs/appointment.png'
                title={noDataTitle}
                description='اختار طبيب واحجز موعدك القادم الان'
                custom={custom}
            />
        )
    }

    // Empty list of services
    if ((!Array.isArray(data) || data.length === 0) && type === 'service') {
        return ''
    }

    return (
        <div className={styles['appointments-data']}>
            {period === 'next' && (
                <div className={styles['title-container']}>
                    <Image loading='lazy' alt='icon' src={icon} className={styles['icon']} />
                    <H4>{title}</H4>
                </div>
            )}
            <div className={styles['list-of-appointments-cards']}>
                {data.slice(pages.min, pages.max).map((appointment, index) => (
                    <CardController appointment={appointment} key={index} period={period} type={type} getAppointments={getAppointments} />
                ))}
            </div>
            <div className={styles['pagination-container']}>
                <Pagination setPages={setPages} data={data} />
            </div>
        </div>
    )
}

export default ListOfAppointments

ListOfAppointments.propTypes = {
    title: PropTypes.string,
    data: PropTypes.array,
    period: PropTypes.oneOf(Object.keys(PERIOD_TYPES)),
    type: PropTypes.oneOf(Object.keys(APPOINTMENT_TYPE)),
    getAppointments: PropTypes.func,
    custom: PropTypes.bool,
    noDataTitle: PropTypes.string,
    loading: PropTypes.bool
}
