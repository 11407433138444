import React from 'react'
import styles from './MedicalRecordImagesCard.module.scss'
import { Image } from 'react-bootstrap'
import { GradientBtn, P } from '@curawella/curawella-components'

import SVG from 'react-inlinesvg'

const btnTitle = (
    <>
        رفع ملف <SVG src='/assets/medical-record/icons/upload.svg' className={styles['upload-icon']} />
    </>
)
const MedicalRecordImagesCard = ({ icon, title, data, handleClick, multi, setShowModal, diagnosis }) => {
    console.log('diagnosis', diagnosis)

    if (!diagnosis && data.length === 0) {
        return ''
    }

    return (
        <div className={styles['medical-record-images-card-container']}>
            <div className={styles['icon-container']}>
                <Image loading='lazy' alt='icon' src={icon} className={styles['icon']} />
            </div>
            <div className={styles['title-row']}>
                <div className={styles['title-data']}>
                    <P type='p2' other='title'>
                        {title}
                    </P>
                    {diagnosis && (
                        <P type='p1' other='sub-header'>
                            {diagnosis}
                        </P>
                    )}
                </div>
                {title !== 'التشخيص' && (
                    <div className={styles['btn-container']}>
                        <GradientBtn title={btnTitle} type='outLinedPrimary' hover='gradient' action={setShowModal} />
                    </div>
                )}
            </div>

            {multi ? (
                data.map((item, index) => (
                    <div className={styles['item']} key={index}>
                        <P type='p1' other='sub-header'>
                            {item.title}
                        </P>

                        <div className={styles['images-container']}>
                            {item.attachments.map((file, index) => (
                                <RenderImage file={file} handleClick={() => handleClick(item.attachments, item.id, index)} />
                            ))}
                        </div>
                    </div>
                ))
            ) : (
                <div className={styles['images-container']}>
                    {data.map((file, index) => (
                        <RenderImage file={file} handleClick={() => handleClick(data, undefined, index)} />
                    ))}
                </div>
            )}
        </div>
    )
}

export default MedicalRecordImagesCard

const RenderImage = ({ file, handleClick }) => {
    if (file.contentType === 'application/pdf') {
        return (
            <Image
                loading='lazy'
                alt='pdf-file'
                src='/assets/medical-record/icons/pdf.svg'
                className={styles['image']}
                onClick={handleClick}
            />
        )
    } else {
        return <Image loading='lazy' alt='medical-image' src={file.link} className={styles['image']} onClick={handleClick} />
    }
}
