import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Image } from 'react-bootstrap'
import { P, SubHeader } from '@curawella/curawella-components'

import { doctorUid } from '../../../constants'
import styles from './Blog.module.scss'
import Loading from '../../components/loading/Loading'
import useFetch from '../../hooks/useFetch'
import { useHistory } from 'react-router-dom'
import replaceURLs from '../../helpers/urlReplacer'

const Blog = () => {
    const { blogId } = useParams()
    const history = useHistory()
    useEffect(() => window.scrollTo(0, 0), [])

    const [blogsData, loading, error, execute] = useFetch()
    const blog = blogsData?.data

    useEffect(() => {
        execute('get', `v2/patient/blogs/${blogId}?doctorUid=${doctorUid}`)
    }, [blogId, execute])

    useEffect(() => {
        if (error) {
            console.log('error', error)
            history.replace('/')
        }
    }, [error, history])

    if (loading || !blogsData) {
        return <Loading />
    }

    return (
        <div className={styles['blog-container']}>
            <SubHeader title={`${blog.title}`} />

            <div className={styles['blog-content']}>
                {blog.image && (
                    <div className={styles['img-container']}>
                        <Image src={blog.image ? blog.image[0] : '/assets/blog/img/no-img.png'} alt='img' loading='lazy' />
                    </div>
                )}
                <P type='p1'>
                    <p dangerouslySetInnerHTML={{ __html: replaceURLs(blog.content) }} />
                </P>

                {/* <bdi className={styles['pre-content']}>{blog.content}</bdi> */}
            </div>
        </div>
    )
}

export default Blog
