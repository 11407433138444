export const API = process.env.REACT_APP_API_LINK
export const packageName = process.env.REACT_APP_PACKAGE_NAME
export const doctorUid = process.env.REACT_APP_DR_UID
export const appId = process.env.REACT_APP_APP_ID
export const isProduction = process.env.REACT_APP_IS_PRODUCTION
export const firebaseConfig =
    isProduction === 'true'
        ? {
              apiKey: 'AIzaSyBNNdq9XGEL3Fwe5bE-TfiwLyc71xzL_qg',
              authDomain: 'el3eyada-cdb0f.firebaseapp.com',
              databaseURL: 'https://el3eyada-cdb0f.firebaseio.com',
              projectId: 'el3eyada-cdb0f',
              storageBucket: 'el3eyada-cdb0f.appspot.com',
              messagingSenderId: '801454395931',
              appId: '1:801454395931:web:95758d09a02fb383935ebe',
              measurementId: 'G-7BQCZ8DFNM'
          }
        : {
              apiKey: 'AIzaSyAHPrWPDgtdfg2ihSxwQ6PLYAvS_zd19N8',
              authDomain: 'curawella-development.firebaseapp.com',
              databaseURL: 'https://curawella-development.firebaseio.com',
              projectId: 'curawella-development',
              storageBucket: 'curawella-development.appspot.com',
              messagingSenderId: '434693192696',
              appId: '1:434693192696:web:c60e20b4f407f366',
              measurementId: 'G-G0YZ5Q3LV9'
          }

export const egGovAr = [
    'القاهرة',
    'الاسكندرية',
    'بورسعيد',
    'السويس',
    'دمياط',
    'الدقهلية',
    'الإسماعيلية',
    'الشرقية',
    'القليوبية',
    'كفر الشيخ',
    'الغربية',
    'المنوفية',
    'بني سويف',
    'أسوان',
    'الأقصر',
    'أسيوط',
    'البحيرة',
    'الجيزة',
    'الفيوم',
    'المنيا',
    'سوهاج',
    'قنا',
    'البحر الاحمر ',
    'مرسى مطروح',
    'الوادي الجديد',
    'شمال سيناء',
    'جنوب سيناء'
]

export const paymentMethods = {
    TOKEN: 'TOKEN',
    CARD: 'CARD'
}
export const getWeekDay = {
    6: 'السبت',
    0: 'الأحد',
    1: 'الأثنين',
    2: 'الثلاثاء',
    3: 'الأربعاء',
    4: 'الخميس',
    5: 'الجمعة'
}

export const getDrLevelEn = {
    GENERAL_PRACTITIONER: 'General Practitioner',
    SPECIALIST: 'Specialist',
    ASSISTANT_LECTURER: 'Assistant Lecturer',
    CONSULTANT: 'Consultant',
    LECTURER: 'Lecturer',
    ASSISTANT_PROFESSOR: 'Assistant professor',
    PROFESSOR: 'Professor'
}

export const getDrLevelAr = {
    GENERAL_PRACTITIONER: 'ممارس عام',
    SPECIALIST: 'أخصائى',
    ASSISTANT_LECTURER: 'محاضر مساعد',
    CONSULTANT: 'استشارى',
    LECTURER: 'محاضر',
    ASSISTANT_PROFESSOR: 'أستاذ مساعد',
    PROFESSOR: 'أستاذ'
}
