import React, { useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { API, appId } from '../constants/AppConstants'
import imageCompression from 'browser-image-compression'
import useFetch from '../hooks/useFetch'
import Chat from '../pages/chat/Chat'
import Loading from '../components/loading/Loading'
import moment from 'moment'
import firebaseApp from '../constants/firebaseApp'

const io = require('socket.io-client')

const options = { maxSizeMB: 1, maxWidthOrHeight: 1920, useWebWorker: true }
let socket

const isDoctor = false

const ChatController = ({ doctor, Context }) => {
    const [Images, setImages] = useState([])
    const [selectedFiles, setSelectedFiles] = useState([])
    const doctorUid = doctor.uid
    const [renderGallery, setRenderGallery] = useState(false)
    const [show, setShow] = useState('chat')
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [showNoCreditModal, setShowNoCreditModal] = useState(false)
    const [sendLoading, setSendLoading] = useState(false)
    const [chatData, setChatData] = useState([])
    const [msg, setMsg] = useState('')
    const [uploadState, setUploadState] = useState(false)
    const [topReached, setTopReached] = useState(false)
    const [shouldPayData, shouldPayLoading, shouldPayError, shouldPayExecute] = useFetch()
    const [payPrivilege, setPayPrivilege] = useState()
    const [socketConnected, setSocketConnected] = useState(false)
    const { patientUid } = useContext(Context)

    useEffect(() => {
        const analytics = firebaseApp.analytics()

        analytics.screenView(analytics.screens.chat)
    }, [])

    // get previous 10 messages
    const fillChat = (lastMessageId) => {
        socket.emit('getPrevious', { lastMessageId, doctorUid, patientUid }, (error, result) => {
            // console.log('error: ', error);
            console.log('socket getPrevious result: ', result, error)

            if (result.length === 0) {
                setTopReached(true)
            }
            const messages = chatData.concat([])
            const newMsgs = [...messages, ...result]

            setChatData(newMsgs)
        })
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (socket && socketConnected !== socket.connected) {
                setSocketConnected(socket.connected)
            }
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [socketConnected])

    // ================ Send Msg=====================

    const sendMessage = useCallback(
        async (sources = []) => {
            const senderUid = isDoctor ? doctorUid : patientUid
            const messageValue = sources.length > 0 ? 'image' : String(msg).trim()
            setSendLoading(true)
            socket.emit('send', { doctorUid, patientUid, senderUid, message: messageValue, sources, appId }, (error, newMessage) => {
                setSendLoading(false)
                // TODO: Validate errors
                if (error) {
                    console.error('Send Message error: ', error)
                    if (error === 'not-enough-balance') {
                        setShowNoCreditModal(true)
                    }
                } else {
                    addNewMessage(newMessage)
                    setMsg('')
                    setSelectedFiles([])
                    setUploadState(false)
                }
            })
        },
        [doctorUid, msg, patientUid]
    )

    // ================ Upload Images ================
    const uploadImages = useCallback(async () => {
        const arr = []
        setUploadState(true)

        for (let i = 0; i < selectedFiles.length; i++) {
            const element = selectedFiles[i]
            const compressedElement = await imageCompression(element, options)
            const imageRef = firebaseApp.storage().message(patientUid, moment().unix())
            await imageRef.put(compressedElement)

            // get the download url of the image
            const url = await imageRef.getDownloadURL()

            arr.push(url)
        }
        sendMessage(arr)
    }, [patientUid, selectedFiles, sendMessage])
    // ================ Main Functions ================

    const beginConnection = useCallback(() => {
        socket = io.connect(`${API}/room`, {
            reconnection: true,
            reconnectionDelay: 500,
            reconnectionAttempts: 200
        })

        socket.on('newMessage', addNewMessage)
        socket.emit('join', doctorUid, patientUid, appId, (error, messages) => {
            // TODO:
            // console.log('error', error);
            setChatData(messages)
            if (messages.length === 0) {
                setTopReached(true)
            }
        })
    }, [doctorUid, patientUid])

    useEffect(() => {
        beginConnection()
        return () => socket.emit('leave')
    }, [beginConnection])

    //send msg
    const sendMsg = useCallback(() => {
        setShowConfirmModal(false)
        if (selectedFiles.length > 0) {
            uploadImages()
            return
        }
        sendMessage()
        return
    }, [selectedFiles.length, sendMessage, uploadImages])

    //check send msg privilege
    const handleSendMsg = useCallback(() => {
        if (payPrivilege) {
            setShowConfirmModal(true)
        } else {
            sendMsg()
        }
    }, [payPrivilege, sendMsg])

    //execute check send msg privilege
    useEffect(() => {
        shouldPayExecute('GET', `v2/user/chat/checkMessagePay/${patientUid}/${doctorUid}`)
    }, [doctorUid, patientUid, shouldPayExecute])

    useEffect(() => {
        if (shouldPayData) {
            if (shouldPayData.data.isPaymentRequired && shouldPayData.data.messageFees !== 0) {
                setPayPrivilege(true)
            }
        }
    }, [handleSendMsg, shouldPayData])

    const addNewMessage = (newMessage) => {
        setChatData((prev) => [newMessage, ...prev])
    }

    // ================ERRORS================

    console.log('shouldPayData', shouldPayData)
    useEffect(() => {
        if (shouldPayError) {
            console.log('shouldPayError: ', shouldPayError)
        }
    }, [shouldPayError])

    if (!socketConnected || shouldPayLoading || shouldPayData === null) {
        return <Loading />
    }

    return (
        <Chat
            doctor={doctor}
            chatValues={chatData}
            msg={msg}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            setImages={(res) => setImages(res)}
            setRenderGallery={(res) => setRenderGallery(res)}
            renderGallery={renderGallery}
            show={show}
            setShow={(res) => setShow(res)}
            patientUid={patientUid}
            uploadState={uploadState}
            Images={Images}
            setShowConfirmModal={(res) => setShowConfirmModal(res)}
            handleSendMsg={handleSendMsg}
            sendMsg={sendMsg}
            setShowNoCreditModal={(res) => setShowNoCreditModal(res)}
            showConfirmModal={showConfirmModal}
            showNoCreditModal={showNoCreditModal}
            shouldPayData={shouldPayData.data.isPaymentRequired}
            topReached={topReached}
            fillChat={fillChat}
            setMsg={setMsg}
            sendLoading={sendLoading}
        />
    )
}

export default ChatController

ChatController.propTypes = {
    custom: PropTypes.bool,
    doctor: PropTypes.object
}
