import { useCallback, useContext, useEffect, useState } from 'react'
import CurrentState from '../current-state/CurrentState'
import MedicalRecordDrugsCard from '../medical-record-drugs-card/MedicalRecordDrugsCard'
import MedicalRecordImagesCard from '../medical-record-images-card/MedicalRecordImagesCard'
import { doctorUid } from '../../../constants/AppConstants'
import { Context } from '../../../../context/Context'

import styles from './MedicalRecordDetails.module.scss'

import useFetch from '../../../hooks/useFetch'
import { Gallery, ModalView, Upload } from '@curawella/curawella-components'
import Loading from '../../../components/loading/Loading'
import firebaseApp from '../../../constants/firebaseApp'

const MedicalRecordDetails = ({ activeCard }) => {
    const [showUploadModal, setShowUploadModal] = useState(false)
    const [galleryImages, setGalleryImages] = useState([])
    const [initIndex, setInitIndex] = useState(0)
    const [preStep, setPreStep] = useState({ value: null })
    const [canRemove, setCanRemove] = useState(true)
    const [activeDiagnosisLabId, setActiveDiagnosisLabId] = useState(null)
    const [uploadFilesData, uploadFilesLoading, uploadFilesError, uploadFilesExecute] = useFetch()
    const [deleteFileData, deleteFileLoading, deleteFileError, deleteFileExecute] = useFetch()
    const [recordData, recordLoading, recordError, recordExecute] = useFetch()
    const { patientUid } = useContext(Context)
    const data = recordData?.data

    console.log('data', data)
    // GET DATA OF SPECIFIC MEDICAL RECORD
    const getSpecificMedicalRecord = useCallback(() => {
        recordExecute('get', `v2/patient/medicalRecords/${patientUid}/${doctorUid}/${activeCard}`)
    }, [activeCard, patientUid, recordExecute])

    // format images to shape required by gallery to be rendered well
    const filterImages = (images, labId, index, editable = true) => {
        setActiveDiagnosisLabId(labId)

        const arr = images.map((img) => ({
            original: img.link,
            thumbnail: img.contentType === 'application/pdf' ? '/assets/medical-record/icons/pdf.svg' : img.link,
            type: img.contentType === 'application/pdf' ? 'pdf' : 'img',
            id: img.id,
            timestamp: img.timestamp
        }))

        console.log(arr)
        setCanRemove(editable)
        setGalleryImages(arr)
        setInitIndex(index)
    }
    useEffect(() => {
        if (activeCard) {
            getSpecificMedicalRecord()
        }
    }, [activeCard, getSpecificMedicalRecord])
    useEffect(() => {
        if (deleteFileData) {
            console.log('deleteFileData: ', deleteFileData)
            getSpecificMedicalRecord()
            setGalleryImages([])
        }
    }, [deleteFileData, getSpecificMedicalRecord])
    useEffect(() => {
        if (uploadFilesData) {
            console.log('uploadFilesData: ', uploadFilesData)
            getSpecificMedicalRecord()
            setShowUploadModal(false)
            setPreStep({ value: null })
        }
    }, [getSpecificMedicalRecord, uploadFilesData])

    useEffect(() => {
        if (recordError) {
            console.log('recordError: ', recordError)
        }
    }, [recordError])

    useEffect(() => {
        if (uploadFilesError) {
            console.log('uploadFilesError: ', uploadFilesError)
        }
    }, [uploadFilesError])

    useEffect(() => {
        if (deleteFileError) {
            console.log('deleteFileError: ', deleteFileError)
        }
    }, [deleteFileError])
    useEffect(() => {
        if (preStep.value) {
            setPreStep((prev) => ({ ...prev, show: false }))
        }
    }, [preStep.value])

    const afterFinishUpload = (files) => {
        const arr = []

        files.map((item) => arr.push({ link: item.url, contentType: item.image.type }))
        const diagnosisLabId = recordData.data[preStep.type].find((item) => item.title === preStep.value)

        const path = `v2/patient/medicalRecords/${patientUid}/${doctorUid}/${activeCard}/${diagnosisLabId.id}`

        uploadFilesExecute('post', path, {
            attachments: arr
        })
    }

    const handleShowUploadModal = (type) => {
        if (type !== 'diagnosis') {
            setPreStep({
                show: true,
                exist: true,
                value: '',
                options: type === 'labs' ? recordData.data.labs : recordData.data.scans,
                title: type === 'labs' ? 'أختر تحليل' : 'أختر أشعة',
                type: type
            })
        }
        setShowUploadModal(true)
    }

    const handleRemove = (id) => {
        let path = ''
        const elementId = galleryImages[id].id
        const url = galleryImages[id].original

        if (activeDiagnosisLabId) {
            path = `v2/patient/medicalRecords/${patientUid}/${doctorUid}/${activeCard}/${activeDiagnosisLabId}/${elementId}`
        } else {
            path = `v2/patient/medicalRecords/${patientUid}/${doctorUid}/${activeCard}/${elementId}`
        }
        firebaseApp
            .storage()
            .refFromURL(url)
            .delete()
            .then(() => {
                // File deleted successfully
                deleteFileExecute('delete', path)
            })
            .catch((error) => {
                // Uh-oh, an error occurred!
                console.log('error in deleting file from firebase storage: ', error)
            })
    }

    if (deleteFileLoading || uploadFilesLoading || recordLoading || !data) {
        return <Loading />
    }

    return (
        <div>
            <div className={styles['second-content']}>
                <CurrentState data={data} />
                <MedicalRecordImagesCard
                    title='التشخيص'
                    icon='/assets/medical-record/icons/medical.svg'
                    data={data.attachments}
                    diagnosis={data.diagnosis}
                    handleClick={(images, labId, index) => filterImages(images, labId, index, false)}
                    multi={false}
                    setShowModal={() => handleShowUploadModal('diagnosis')}
                />
                <MedicalRecordDrugsCard title='الأدوية' icon='/assets/medical-record/icons/drugs.svg' data={data.drugs} />
                <MedicalRecordImagesCard
                    title='التحاليل'
                    icon='/assets/medical-record/icons/labs.svg'
                    data={data.labs}
                    handleClick={filterImages}
                    multi={true}
                    setShowModal={() => handleShowUploadModal('labs')}
                />
                <MedicalRecordImagesCard
                    title='الأشعه'
                    icon='/assets/medical-record/icons/scans.svg'
                    data={data.scans}
                    handleClick={filterImages}
                    multi={true}
                    setShowModal={() => handleShowUploadModal('scans')}
                />
            </div>
            {showUploadModal && (
                <ModalView
                    show={showUploadModal}
                    setShow={(value) => {
                        setShowUploadModal(value)
                        setPreStep({})
                    }}
                    title='رفع صورة او ملف'
                    type='customView'
                    customView={
                        <Upload
                            afterUpload={afterFinishUpload}
                            preStep={preStep}
                            acceptPDF={true}
                            setPreStep={(values) => setPreStep(values)}
                            lang='AR'
                            mainStorageRef={firebaseApp.storage().medicalRecord(patientUid)}
                        />
                    }
                />
            )}
            {galleryImages.length > 0 && (
                <Gallery
                    images={galleryImages}
                    closeAction={() => {
                        setGalleryImages([])
                        setActiveDiagnosisLabId(null)
                    }}
                    canRemove={canRemove}
                    removeAction={handleRemove}
                    initIndex={initIndex}
                />
            )}
        </div>
    )
}

export default MedicalRecordDetails
