import React, { useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './BookCard.module.scss'
import './Calendar.scss'
import 'react-calendar/dist/Calendar.css'
import Calendar from 'react-calendar'
import { Image } from 'react-bootstrap'

import moment from 'moment'
import useFetch from '../../hooks/useFetch'
import { doctorUid } from '../../constants/AppConstants'
import { getTime } from '../../helpers/dateTime'
import { GradientBtn, P } from '@curawella/curawella-components'
import Loading from '../loading/Loading'

const BookCard = ({ center, selectedAppointmentType, selectedClinicId, onSubmit }) => {
    //  ===============how component work===============
    //  1- first we execute doctorDataExecute to get doctorData which has available dates and set the result in
    //   availableDates based on the selectedType 'ONLINE' OR 'CLINIC'
    //  2- then when select any day in calendar the executeTimes get fired to get available times in selected day
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null)

    const [timesData, timesLoading, timesError, executeTimes] = useFetch()
    const [datesData, datesLoading, datesError, executeDates] = useFetch()
    const [times, setTimes] = useState([])
    const availableDates = datesData?.results
    const formatDate = useCallback((date) => moment(date, 'YYYY-MM-DD').locale('en').set('hour', 12).format(), [])

    useEffect(() => {
        const tz = moment().locale('en').format('Z')
        let route = `v2/patient/doctor/availableDates/${doctorUid}/${tz}/${selectedAppointmentType}`
        if (selectedAppointmentType === 'CLINIC') {
            route = route.concat(`?clinicId=${selectedClinicId}`)
        }

        executeDates('PATCH', route)
    }, [executeDates, selectedAppointmentType, selectedClinicId])

    const tz = moment().locale('en').format('Z')

    useEffect(() => {
        if (datesData) {
            console.log('datesData', datesData)
        }
    }, [datesData])

    useEffect(() => {
        if (datesError) {
            console.log('error in timesData: ', datesError)
        }
    }, [datesError])

    useEffect(() => {
        if (selectedDate) {
            const route = `v2/patient/appointments/${doctorUid}/${selectedAppointmentType}/${formatDate(selectedDate)}${
                selectedAppointmentType !== 'ONLINE' ? `?clinicId=${selectedClinicId}` : ''
            }`

            executeTimes('GET', route)
        }
    }, [selectedDate, executeTimes, formatDate, selectedAppointmentType, selectedClinicId])

    useEffect(() => {
        if (timesData) {
            console.log('timesData', timesData)
            setTimes(timesData.data.dayAppointments)
        }
    }, [timesData])

    useEffect(() => {
        if (timesError) {
            console.log('error in timesData: ', timesError)
        }
    }, [timesError])

    if (datesLoading) {
        return <Loading />
    }

    return (
        <div className={`${styles['book-video-container']} ${center ? styles['center'] : ''}`}>
            <P type='p1' other='set-date-title'>
                حدد التاريخ المناسب لك
            </P>
            {availableDates && (
                <>
                    <Calendar
                        value={selectedDate && selectedDate}
                        onClickDay={(day) => setSelectedDate(day)}
                        className={styles['react-calendar']}
                        next2Label={null}
                        prev2Label={null}
                        calendarType='Arabic'
                        locale='ar'
                        minDate={new Date()}
                        maxDate={moment().add(1, 'month').toDate()}
                        tileDisabled={({ date }) => !availableDates.includes(moment(date).locale('en').format('YYYY-MM-DD'))}
                    />
                    {selectedDate && (
                        <>
                            {timesLoading ? (
                                <Loading />
                            ) : times && times.length > 0 ? (
                                <>
                                    <P type='p1' other='set-date-title'>
                                        اختر الموعد المناسب لك
                                    </P>
                                    <div className={styles['list-of-particular-days-container']}>
                                        {times.map((time, index) => (
                                            <div
                                                className={`${styles['particular-day-container']} ${
                                                    selectedTime === time ? styles['active'] : ''
                                                }`}
                                                key={index}
                                                onClick={() => setSelectedTime(time)}
                                            >
                                                <span>{getTime(time)}</span>

                                                <Image
                                                    loading='lazy'
                                                    className={styles['icon']}
                                                    src={
                                                        selectedTime === time
                                                            ? '/assets/book-card/icons/clockWhite.svg'
                                                            : '/assets/book-card/icons/clock.svg'
                                                    }
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </>
                            ) : (
                                <P type='p1' other='set-date-title'>
                                    لا يوجد مواعيد متاحه فى هذا اليوم
                                </P>
                            )}
                        </>
                    )}
                    {selectedDate && selectedTime && (
                        <GradientBtn
                            title='تأكيد الحجز'
                            align={'center'}
                            action={() => onSubmit(selectedDate, selectedTime)}
                            type='gradient'
                        />
                    )}
                </>
            )}
        </div>
    )
}

export default BookCard

BookCard.propTypes = {
    selectedDate: PropTypes.any,
    selectedTime: PropTypes.any,
    setSelectedDate: PropTypes.func,
    setSelectedTime: PropTypes.func,
    center: PropTypes.bool,
    selectedAppointmentType: PropTypes.string,
    selectedClinicId: PropTypes.number,
    onSubmit: PropTypes.func
}
