import React from 'react'
import styles from './ContactUsLandingClinic.module.scss'
import { GradientBtn, SectionTitle, H4 } from '@curawella/curawella-components'
import { Link } from 'react-router-dom'

const ContactUsLandingClinic = () => {
    return (
        <div className={styles['contact-us-clinic-landing']}>
            <SectionTitle title='تواصل معنا' />
            <H4>
                اذا كان لديك استفسار او مشكلة يمكنك التواصل مع الدعم الفني بارسال رسالة وسنقوم بالرد
                عليك في اقرب وقت
            </H4>
            <Link to='/contact-us'>
                <GradientBtn title='ارسال رسالة' type='gradient' />
            </Link>
        </div>
    )
}

export default ContactUsLandingClinic
