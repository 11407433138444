// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app'
// import 'firebase/compat/auth'
// import 'firebase/compat/analytics'
import 'firebase/compat/storage'

import { initializeApp, getApp, getApps } from 'firebase/app'
import { getAuth, onAuthStateChanged, signInWithCustomToken, signOut } from 'firebase/auth'
// import { getStorage, ref as storageRef } from 'firebase/storage'
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics'

import { firebaseConfig } from './AppConstants'
import { appId, doctorUid } from '../../constants'

class FirebaseConfigs {
    firebaseApp
    firebaseAppCompat

    constructor() {
        if (getApps().length === 0) {
            this.firebaseApp = initializeApp(firebaseConfig)
            this.firebaseAppCompat = firebase.initializeApp(firebaseConfig)
        } else {
            this.firebaseApp = getApp()
            this.firebaseAppCompat = firebase.app()
        }
    }

    auth() {
        const auth = getAuth(this.firebaseApp)

        return {
            ...auth,
            /**
             *
             * @param {string} customToken
             * @returns
             */
            signInWithCustomToken: (token) => signInWithCustomToken(auth, token),
            /**
             * Adds an observer for changes to the user's sign-in state.
             * @param {NextOrObserver<User>} nextOrObserver callback triggered on change
             * @param {ErrorFn} [error] callback triggered on error.
             * @param {CompleteFn} [completed] callback triggered when observer is removed.
             * @returns
             */
            onAuthStateChanged: (nextOrObserver, error, completed) => onAuthStateChanged(auth, nextOrObserver, error, completed),
            /**
             * Signs out the current user.
             */
            signOut: () => signOut(auth)
        }
    }

    storage() {
        const storage = this.firebaseAppCompat.storage()

        return {
            ...storage,
            ref: (r) => storage.ref(storage, `${r}`),
            refFromURL: (r) => storage.refFromURL(`${r}`),
            avatar: (uid) => storage.ref(`/Avatar/${uid}`),
            medicalRecord: (patientUid, timestamp = undefined) => {
                const ref = storage.ref(`/MedicalRecord/${patientUid}`)
                if (typeof timestamp !== 'undefined') {
                    return ref.child(timestamp)
                } else {
                    return ref
                }
            },
            message: (patientUid, timestamp) => storage.ref(`Messages/${patientUid}/${timestamp}`)
        }
    }

    /**
     * 1. Enable extention https://chrome.google.com/webstore/detail/google-analytics-debugger/jnkmfdileelhofjcijamephohjechhna?hl=en
     * 2. https://console.firebase.google.com/ => analytics => Debug View
     */
    analytics() {
        const analytics = getAnalytics(this.firebaseApp)
        return {
            ...analytics,
            /**
             *
             * @param {string} screenName
             * @param {string} [screenClass]
             * @returns
             */
            screenView: (screenName, screenClass) =>
                logEvent(analytics, 'screen_view', { firebase_screen: screenName, firebase_screen_class: screenClass || screenName }),
            /**
             *
             * @param {string} event
             * @returns
             */
            logEvent: (event) => logEvent(analytics, event),
            login: () => {
                setUserProperties(analytics, { appId, doctorUid })
                logEvent(analytics, 'login', { firebase_screen: analyticsScreens.login, firebase_screen_class: analyticsScreens.login })
            },
            screens: analyticsScreens,
            events: analyticsEvents
        }
    }
}

export default new FirebaseConfigs()

const analyticsScreens = {
    home: 'Home Clinic',
    login: 'Login',
    medicalRecord: 'Medical Records',
    blogs: 'Blogs',
    wallet: 'Wallet',
    appointments: 'Appointments',
    doctorProfile: 'Doctor Profile',
    patientProfile: 'Patient Profile',
    chat: 'Chat',
    notifications: 'Notifications',
    paymentConfirm: 'Payment Confirm',
    registration: 'Registration',
    forgetPassword: 'Forget Password',
    privacy: 'privacy Screen',
    ContactUs: 'ContactUs'
}

const analyticsEvents = {
    Curawella: 'Curawella event',
    Vcliniq: 'Vcliniq event',
    addCard: 'Add Saved card',
    removeCard: 'Delete Saved card',
    becomeADoctor: 'Become A Doctor',
    rescheduleAppointment: 'Reschedule Appointment',
    cancelAppointment: 'Cancel Appointment',
    booking: 'Booking',
    reschedule: 'Reschedule',
    signup: 'Signup',
    logout: 'logout',
    CHARGE: {
        TOKEN: 'Pay CashBox by saved Credit Card',
        WALLET: 'Pay CashBox by E-Wallet',
        AGGREGATOR: 'Pay CashBox by Masari or Aman',
        CARD: 'Pay CashBox by Credit Card'
    },
    SERVICE: {
        TOKEN: 'Pay Service by saved Credit Card',
        CARD: 'Pay Service by Credit Card',
        WALLET: 'Pay Service by E-Wallet',
        AGGREGATOR: 'Pay Service by Masari or Aman',
        VAL_U: 'Pay Service by Val-u'
    },
    CLINIC: {
        TOKEN: 'Pay Appointment by saved Credit Card',
        CARD: 'Pay Appointment by Credit Card',
        WALLET: 'Pay Appointment by E-Wallet',
        AGGREGATOR: 'Pay Appointment by Masari or Aman',
        CASH_BOX: 'Pay Appointment by cash box',
        ONSITE: 'Pay Appointment Onsite'
    },
    ONLINE: {
        TOKEN: 'Pay Video Call by saved Credit Card',
        CARD: 'Pay Video Call by Credit Card',
        WALLET: 'Pay Video Call by E-Wallet',
        AGGREGATOR: 'Pay Video Call by Masari or Aman',
        CASH_BOX: 'Pay Video Call by cash box'
    }
}
