import { P } from '@curawella/curawella-components'
import { Image } from 'react-bootstrap'

import { getDrLevelAr } from '../../constants/AppConstants'
import { getDate } from '../../helpers/dateTime'

import styles from './AppointmentCard.module.scss'
const HistoryServiceCrd = ({ appointment }) => {
    return (
        <div className={styles['appointment-card-container']}>
            <Image loading='lazy' alt='dr-img' src={appointment.avatar} className={styles.icon} />
            <div className={styles.data}>
                <P type='p1' other='dr-name'>
                    {`${appointment.firstNameAr} ${appointment.lastNameAr}`}
                </P>
                <P type='p2' other='dr-level'>
                    {getDrLevelAr[appointment.level]} - {appointment.specialtyEn}
                </P>

                <div className={styles['type-container']}>
                    <div className={styles['icon-container']}>
                        <Image loading='lazy' alt='icon' src='assets/appointment/icons/surgeries.svg' className={styles.icon} />
                    </div>
                    <P type='p1'>{appointment.serviceNameAr}</P>
                </div>
            </div>
            <div className={styles['finished-container']}>
                <P type='p1' other='date'>
                    {getDate(appointment.date)}
                </P>
            </div>
        </div>
    )
}

export default HistoryServiceCrd
