import React from 'react'
import { P } from '@curawella/curawella-components'
import PropTypes from 'prop-types'
import { Image } from 'react-bootstrap'
import styles from './MedicalRecordCard.module.scss'

const MedicalRecordCard = ({ data }) => {
    MedicalRecordCard.propTypes = {
        data: PropTypes.object
    }
    return (
        <div className={styles['medical-record-card-container']}>
            <div className={styles['icon-container']}>
                <Image
                    loading='lazy'
                    alt='icon'
                    src='/assets/medical-record/icons/lungs.svg'
                    className={styles['icon']}
                />
            </div>
            <div className={styles['first-row']}>
                <div className={styles['dr-data']}>
                    <P type='p1' other='dr-name'>
                        {data.firstNameAr}
                    </P>
                    <P type='p2' other='dr-level'>
                        {data.dr.level} - {data.dr.specialty}
                    </P>
                </div>
                <P type='p1' other='date'>
                    {data.date}
                </P>
            </div>
            <P type='p1'>{data.type}</P>
        </div>
    )
}

export default MedicalRecordCard
