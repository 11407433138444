import { GradientBtn, P } from '@curawella/curawella-components'
import { Image } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import { getDrLevelAr } from '../../constants/AppConstants'
import { getDate } from '../../helpers/dateTime'

import styles from './AppointmentCard.module.scss'

const ServiceUpcomingCard = ({ appointment }) => {
    const history = useHistory()
    const navigateToPayment = () => {
        history.push({
            pathname: '/paymentConfirm',
            state: {
                date: appointment.date,
                type: 'SERVICE',
                price: appointment.servicePrice,
                serviceName: appointment.serviceNameAr,
                servicesAppointmentId: appointment.serviceAppointmentId
            }
        })
    }
    return (
        <div className={styles['appointment-card-container']}>
            <Image loading='lazy' alt='dr-img' src={appointment.avatar} className={styles.icon} />
            <div className={styles.data}>
                <P type='p1' other='dr-name'>
                    {`${appointment.firstNameAr} ${appointment.lastNameAr}`}
                </P>
                <P type='p2' other='dr-level'>
                    {getDrLevelAr[appointment.level]} - {appointment.specialtyAr}
                </P>

                <P type='p1' other='name'>
                    الأسم: <span>{appointment.serviceNameAr}</span>
                </P>
                <P type='p1' other='date'>
                    التاريخ: <span>{getDate(appointment.date)}</span>
                </P>
                <P type='p1' other='price'>
                    السعر: <span>{appointment.servicePrice} ج.م</span>
                </P>
            </div>
            {appointment.orderId ? (
                <div className={`${styles.btn} ${appointment.orderId ? styles['not-clickable'] : ''}`}>
                    <GradientBtn title='تم الدفع' type='light' hover='light' />
                </div>
            ) : (
                <div className={styles.btn}>
                    <GradientBtn title='ادفع' type='outLinedPrimary' hover='gradient' action={navigateToPayment} />
                </div>
            )}
        </div>
    )
}

export default ServiceUpcomingCard
