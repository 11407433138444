import React from 'react'
import PropTypes from 'prop-types'
import styles from './WalletAction.module.scss'
import { P, OutlinedInput, GradientBtn } from '@curawella/curawella-components'

const WalletAction = ({
    title,
    placeHolder,
    btnTitle,
    btnAction,
    value,
    setValue,
    error,
    btnDisable
}) => {
    console.log(error, btnDisable)

    return (
        <div className={styles['wallet-action-container']}>
            <P type='title'>{title}</P>
            <OutlinedInput
                title={placeHolder}
                value={value}
                setValue={(value) => setValue(value)}
            />
            {error && <P other='error-text'>{error}</P>}
            <GradientBtn
                title={btnTitle}
                direction='left'
                type='gradient'
                action={btnAction}
                disabled={btnDisable}
            />
        </div>
    )
}

export default WalletAction
WalletAction.propTypes = {
    title: PropTypes.any,
    placeHolder: PropTypes.string,
    btnTitle: PropTypes.string,
    btnAction: PropTypes.func,
    value: PropTypes.string,
    setValue: PropTypes.func,
    error: PropTypes.string,
    btnDisable: PropTypes.bool
}
