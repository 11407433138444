/* eslint-disable react-native/no-inline-styles */
import React, { useEffect, useState } from 'react'
import { ModalView, OutlinedInput, GradientBtn, P } from '@curawella/curawella-components'

import { getCountries, getStatesByShort } from 'countrycitystatejson'
import { egGovAr } from '../../constants/AppConstants'
import Terms from '../legal-terms/terms'
import styles from './FullForm.module.scss'

const getFields = (input, field) => {
    const output = []
    for (let i = 0; i < input.length; ++i) {
        output.push(input[i][field])
    }
    return output
}
const countriesAll = getCountries()
const countries = getFields(countriesAll, 'native')

const FullForm = ({ user, setUser, userErrors, handleClick, legalTerms, PrivacyPolicy, phoneCode, setPhoneCode }) => {
    const [showModal, setShowModal] = useState(false)
    const [cities, setCities] = useState([])

    useEffect(() => {
        if (user && user.country) {
            const countryIdx = countries.findIndex((tmp) => tmp === user.country)

            if (countryIdx < 0) {
                return
            }

            const shortName = countriesAll[countryIdx].shortName
            const countryCode = '+' + countriesAll[countryIdx].phone
            const citiesTmp = shortName.toLowerCase() === 'eg' ? egGovAr : getStatesByShort(shortName)

            setPhoneCode(countryCode)
            setCities(citiesTmp)
        }
    }, [setPhoneCode, user])

    return (
        <div className={styles['form-container']}>
            {/* Name */}
            <div className={styles['inputs-row']}>
                <OutlinedInput
                    title='الأسم الأول'
                    required
                    width='48.5%'
                    value={user.firstName}
                    setValue={(e) => setUser({ ...user, firstName: e.target.value.trim() })}
                    error={userErrors.firstName}
                    lang='AR'
                />

                <OutlinedInput
                    title='الأسم الثاني'
                    width='48.5%'
                    value={user.secondName}
                    setValue={(e) => setUser({ ...user, secondName: e.target.value.trim() })}
                    error={userErrors.secondName}
                    lang='AR'
                />
            </div>
            <div className={styles['inputs-row']}>
                <OutlinedInput
                    title='الأسم الثالث'
                    width='48.5%'
                    value={user.thirdName}
                    setValue={(e) => setUser({ ...user, thirdName: e.target.value.trim() })}
                    error={userErrors.thirdName}
                    lang='AR'
                />

                <OutlinedInput
                    title='الأسم الأخير'
                    required
                    width='48.5%'
                    value={user.lastName}
                    setValue={(e) => setUser({ ...user, lastName: e.target.value.trim() })}
                    error={userErrors.lastName}
                    lang='AR'
                />
            </div>

            {/* Date of birth */}
            <OutlinedInput
                title={'تاريخ الميلاد'}
                type='date'
                value={user.bd}
                setValue={(e) => setUser({ ...user, bd: e.target.value })}
                error={userErrors.bd}
                lang='AR'
                max='today'
            />

            {/* Gender */}
            <div className={styles['gender-container']}>
                <P type='p2' other='title'>
                    النوع
                </P>
                <div className={styles['btns-container']}>
                    <GradientBtn
                        title='ذكر'
                        type={user.gender === '1' ? 'solidPrimary' : 'light'}
                        hover='solidPrimary'
                        width='48%'
                        action={() => {
                            setUser({ ...user, gender: '1' })
                        }}
                    />
                    <GradientBtn
                        title='انثي'
                        type={user.gender === '0' ? 'solidPrimary' : 'light'}
                        hover='solidPrimary'
                        width='48%'
                        action={() => setUser({ ...user, gender: '0' })}
                    />
                </div>
            </div>
            {userErrors.gender && (
                <P type='p2' other='error-text'>
                    * {userErrors.gender}
                </P>
            )}

            {/* country & city */}
            <div className={styles['inputs-row']}>
                <OutlinedInput
                    required
                    title={'البلد'}
                    type='select'
                    options={countries}
                    width='48.5%'
                    value={user.country}
                    setValue={(e) => setUser({ ...user, country: e.target.value, city: '' })}
                    error={userErrors.country}
                    lang='AR'
                />

                <OutlinedInput
                    title={'المحافظة'}
                    type='select'
                    options={cities}
                    width='48.5%'
                    value={user.city}
                    setValue={(e) => setUser({ ...user, city: e.target.value })}
                    error={userErrors.city}
                    lang='AR'
                />
            </div>

            {/* Phone number */}
            <div className={styles['input-container']}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <OutlinedInput
                        required
                        title='رقم الهاتف'
                        value={user.phone}
                        setValue={(e) => setUser({ ...user, phone: e.target.value })}
                        handleClick={handleClick}
                        name='phone'
                        width='82%'
                        error={userErrors.phone}
                        lang='AR'
                    />
                    <OutlinedInput lang='EN' width='15%' value={phoneCode} disabled className={styles['country-code']} />
                </div>
            </div>

            {/* Email */}
            <div className={styles['input-container']}>
                <OutlinedInput
                    title='البريد الألكترونى'
                    required
                    value={user.email}
                    setValue={(e) => setUser({ ...user, email: e.target.value })}
                    handleClick={handleClick}
                    name='email'
                    error={userErrors.email}
                    lang='AR'
                />
            </div>

            <OutlinedInput
                required
                title='كلمة المرور'
                type='password'
                autoComplete='new-password'
                value={user.password}
                setValue={(e) => setUser({ ...user, password: e.target.value })}
                error={userErrors.password}
                lang='AR'
            />

            {/* Checkbox */}
            <div className={styles['check-box-container']}>
                <input type='checkbox' checked={user.policy} onChange={(e) => setUser({ ...user, policy: e.target.checked })} />
                <p>
                    لقد قمت بقراءة
                    <button onClick={(e) => setShowModal(!showModal)}>شروط الاستخدام و سياسة الخصوصية</button>و الموافقة عليها
                </p>
            </div>

            <ModalView
                show={showModal}
                setShow={setShowModal}
                type='customViewIframeLarge'
                customView={<Terms legalTermsData={legalTerms} PrivacyPolicy={PrivacyPolicy} />}
            />
            {userErrors.policy && (
                <P type='p2' other='error-text'>
                    {userErrors.policy}
                </P>
            )}
        </div>
    )
}

export default FullForm
