import { useState, useEffect } from 'react'
import styles from './pagination.module.scss'

export const Pagination = ({ setPages, data, pageSize = 6 }) => {
    const [pageNum, setPageNum] = useState()
    const [disableNext, setDisableNext] = useState(false)
    const [disablePrev, setDisablePrev] = useState(false)
    const [current, setCurrent] = useState(1)

    const updateCurrent = (type) => {
        if (type === 'Previous') {
            if (current > 1) {
                setCurrent(current - 1)
                setDisablePrev(false)
                setDisableNext(false)
            }
            if (current === 1) {
                setDisablePrev(true)
            }
        }
        if (type === 'Next') {
            if (current < pageNum) {
                setCurrent(current + 1)
                setDisableNext(false)
                setDisablePrev(false)
            }
            if (current === pageNum) {
                setDisableNext(true)
            }
        }
    }

    useEffect(() => {
        setPageNum(Math.ceil(data.length / pageSize))
    }, [data.length, pageSize])

    useEffect(() => {
        setPages({ min: pageSize * (current - 1), max: current * pageSize })
    }, [current, setPages, pageSize])

    return (
        <div className={styles['pagination-container']}>
            <div className={styles['page-guid']}>
                <p>
                    صفحة
                    <input value={current} onChange={(e) => setCurrent(e.target.value)} disabled />
                    من {pageNum}{' '}
                </p>
            </div>
            <div className={styles['move-between-pages']}>
                <img
                    disabled={disablePrev}
                    src='/assets/wallet/icons/chevron.svg'
                    className={styles['next']}
                    alt=''
                    onClick={() => updateCurrent('Previous')}
                />

                <img disabled={disableNext} src='/assets/wallet/icons/chevron.svg' alt='' onClick={() => updateCurrent('Next')} />
            </div>
        </div>
    )
}

export default Pagination
