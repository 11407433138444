import React from 'react'
import PropTypes from 'prop-types'
import styles from './MedicalRecordDrugsCard.module.scss'
import { Image } from 'react-bootstrap'
import { P } from '@curawella/curawella-components'

const MedicalRecordDrugsCard = ({ icon, title, data }) => {
    MedicalRecordDrugsCard.propTypes = {
        title: PropTypes.string,
        data: PropTypes.array
    }

    if (data.length === 0) {
        return ''
    }
    return (
        <div className={styles['medical-record-drugs-card-container']}>
            <div className={styles['icon-container']}>
                <Image loading='lazy' alt='icon' src={icon} className={styles['icon']} />
            </div>
            <P type='p2' other='title'>
                {title}
            </P>

            {data && (
                <div className={styles['images-container']}>
                    {data.map((drug, index) => (
                        <div className={styles['first-row']} key={index}>
                            <div className={styles['drug-data']}>
                                <P type='p1' other='drug-name'>
                                    {drug.commercialName}
                                </P>
                                <P type='p2' other='drug-type'>
                                    {drug.type}
                                </P>
                            </div>
                            <P type='p2' other='drug-frequency'>
                                {`${drug.frequency} مرات`}
                            </P>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default MedicalRecordDrugsCard
