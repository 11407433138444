import { P } from '@curawella/curawella-components'
import { Image } from 'react-bootstrap'

import { getDrLevelAr } from '../../constants/AppConstants'
import { getDate, getTime } from '../../helpers/dateTime'
import { getType } from './CardController'

import styles from './AppointmentCard.module.scss'

const AppointmentHisCard = ({ appointment }) => {
    return (
        <div className={styles['appointment-card-container']}>
            <Image loading='lazy' alt='dr-img' src={appointment.avatar} className={styles.icon} />
            <div className={styles.data}>
                <P type='p1' other='dr-name'>
                    {`${appointment.firstNameAr} ${appointment.lastNameAr}`}
                </P>
                <P type='p2' other='dr-level'>
                    {getDrLevelAr[appointment.level]} - {appointment.specialtyAr}
                </P>
                {appointment.appointmentType === 'CLINIC' && (
                    <P type='p1' other='name'>
                        اسم العيادة: <span>{appointment.clinicName}</span>
                    </P>
                )}
                <div className={styles['type-container']}>
                    {appointment.appointmentType === 'CLINIC' ? (
                        <div className={styles['icon-container']}>
                            <Image loading='lazy' alt='icon' src='assets/appointment/icons/clinic.svg' className={styles.icon} />
                        </div>
                    ) : (
                        <div className={styles['icon-container']}>
                            <Image loading='lazy' alt='icon' src='assets/appointment/icons/video.svg' className={styles.icon} />
                        </div>
                    )}

                    <P type='p1'>{getType[appointment.appointmentType]}</P>
                </div>
            </div>
            <div className={styles['finished-container']}>
                <P type='p1' other='date'>
                    {getDate(appointment.timestamp)}
                </P>
                {getTime(appointment.timestamp)}
            </div>
        </div>
    )
}

export default AppointmentHisCard
