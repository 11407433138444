import React, { useCallback, useContext, useEffect, useState } from 'react'
import styles from './Notifications.module.scss'
import { Image } from 'react-bootstrap'
import { EmptyContainer, SubHeader } from '@curawella/curawella-components'
import Notification from '../../Models/NotificationsModel'
import { Context } from '../../../context/Context'
import NotificationCard from './notification-card/NotificationCard'
import Loading from '../../components/loading/Loading'
import Pagination from '../../components/pagination/pagination'
import firebaseApp from '../../constants/firebaseApp'

const Notifications = () => {
    const { patientUid } = useContext(Context)
    const [loading, execute, data] = Notification.getNotification()

    const [pages, setPages] = useState({})

    useEffect(() => {
        const analytics = firebaseApp.analytics()

        analytics.screenView(analytics.screens.notifications)
    }, [])

    const getNotificationData = useCallback(() => {
        execute({ uid: patientUid })
    }, [execute, patientUid])

    useEffect(() => {
        getNotificationData()
    }, [getNotificationData])

    if (loading) {
        return <Loading />
    }

    if (!Array.isArray(data) || data.length === 0) {
        return <EmptyContainer img='/assets/notifcation/imgs/Notifications.png' title='لا يوجد أشعارات' />
    }
    return (
        <div className={styles['notifications-container']}>
            <SubHeader title='الأشعارات' />
            <div className={styles['notifications-content']}>
                <div className={styles['list-of-notifications']}>
                    {data.slice(pages.min, pages.max).map((notification, index) => (
                        <NotificationCard
                            type={notification.type}
                            description={notification.ar}
                            time={notification.timestamp}
                            key={index}
                        />
                    ))}

                    <Pagination setPages={setPages} data={data} />
                </div>
                <Image
                    src='/assets/notifcation/imgs/Notifications.png'
                    alt='Notifications-img'
                    className={styles['notifications-img']}
                    loading='lazy'
                />
            </div>
        </div>
    )
}

export default Notifications
