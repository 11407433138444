import React from 'react'
import PropTypes from 'prop-types'
import styles from './InfoCard.module.scss'
import { P, GradientBtn } from '@curawella/curawella-components'
import { getDate, getTime } from '../../../helpers/dateTime'
import Loading from '../../../components/loading/Loading'
const CARD_TYPES = {
    appointment: 'appointment',
    payment: 'payment'
}

const getType = {
    ONLINE: 'مكالمة فيديو',
    CHARGE: 'شحن رصيد محفظتي',
    CLINIC: 'عيادة',
    SERVICE: 'عمليه'
}
const InfoCard = ({ type, data, setShowModal, paymentType, loading }) => {
    InfoCard.propTypes = {
        type: PropTypes.oneOf(Object.keys(CARD_TYPES)),
        data: PropTypes.object,
        setShowModal: PropTypes.func,
        paymentType: PropTypes.string,
        currentSelectedType: PropTypes.any,
        loading: PropTypes.bool
    }

    if (type === 'appointment') {
        return (
            <div className={styles['data-item-container']}>
                <div className={styles['data-item-content']}>
                    <P type='title' other='title'>
                        تفاصيل الحجز
                    </P>
                    <div className={styles['row']}>
                        <P type='p1'>النوع:</P>

                        <span>{getType[data.type]} </span>
                    </div>
                    <div className={styles['row']}>
                        <P type='p1'>التاريخ:</P>
                        <span>{getDate(data.date)}</span>
                    </div>
                    <div className={styles['row']}>
                        <P type='p1'>الوقت:</P>
                        <span>{getTime(data.time)}</span>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={styles['data-item-container']}>
            <div className={styles['data-item-content']}>
                <P type='title' other='title'>
                    تفاصيل الدفع
                </P>

                {!data ? (
                    <P type='p2' other='total-description'>
                        اختر طريقة الدفع ليظهر لك الأجمالى
                    </P>
                ) : (
                    <>
                        {data.price && (
                            <div className={styles['row']}>
                                <P type='p1'>السعر:</P>
                                <span>{data.price} ج.م</span>
                            </div>
                        )}
                        {data.discount && paymentType !== 'CHARGE' ? (
                            <div className={styles['row']}>
                                <P type='p1'>الخصم:</P>
                                <span>{data.discount} ج.م</span>
                            </div>
                        ) : (
                            ''
                        )}

                        {data.fees && (
                            <div className={styles['row']}>
                                <P type='p1'> المصاريف:</P>
                                {data.fees === '0' ? (
                                    <span>لا يوجد</span>
                                ) : (
                                    <span>{parseFloat(data.fees).toFixed(2)} ج.م</span>
                                )}
                            </div>
                        )}

                        <div className={`${styles['row']} ${styles['total']}`}>
                            <>
                                <P type='p1' other='total'>
                                    اجمالي المبلغ:
                                </P>
                                <span>{data.total} ج.م</span>
                            </>
                        </div>
                        <div className={`${styles['row']} ${styles['total']}`}>
                            <div className={`${styles['row']}`}>{loading && <Loading />}</div>
                        </div>
                    </>
                )}
            </div>
            {(paymentType === 'CLINIC' || paymentType === 'ONLINE') && (
                <GradientBtn
                    title='استخدام بروموكود'
                    type='outLinedPrimary'
                    hover='gradient'
                    action={() => setShowModal(true)}
                />
            )}
        </div>
    )
}

export default InfoCard
