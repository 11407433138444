export const doctorUid = process.env.REACT_APP_DR_UID
export const doctorName = process.env.REACT_APP_DR_NAME
export const doctorLogo = process.env.REACT_APP_DR_LOGO
export const API = process.env.REACT_APP_API_LINK
export const packageName = process.env.REACT_APP_PACKAGE_NAME
export const appId = process.env.REACT_APP_APP_ID
export const isProduction = process.env.REACT_APP_IS_PRODUCTION
const REACT_APP_DR_NAME = process.env.REACT_APP_DR_SHORT_NAME || doctorName
export const doctorShortName = REACT_APP_DR_NAME.length > 15 ? `..${REACT_APP_DR_NAME.substring(0, 12)}` : REACT_APP_DR_NAME

export const firebaseConfig =
    isProduction === 'true'
        ? {
              apiKey: 'AIzaSyBNNdq9XGEL3Fwe5bE-TfiwLyc71xzL_qg',
              authDomain: 'el3eyada-cdb0f.firebaseapp.com',
              databaseURL: 'https://el3eyada-cdb0f.firebaseio.com',
              projectId: 'el3eyada-cdb0f',
              storageBucket: 'el3eyada-cdb0f.appspot.com',
              messagingSenderId: '801454395931',
              appId: '1:801454395931:web:95758d09a02fb383935ebe',
              measurementId: 'G-7BQCZ8DFNM'
          }
        : {
              apiKey: 'AIzaSyAHPrWPDgtdfg2ihSxwQ6PLYAvS_zd19N8',
              authDomain: 'curawella-development.firebaseapp.com',
              databaseURL: 'https://curawella-development.firebaseio.com',
              projectId: 'curawella-development',
              storageBucket: 'curawella-development.appspot.com',
              messagingSenderId: '434693192696',
              appId: '1:434693192696:web:c60e20b4f407f366'
          }
