import React from 'react'
import DoctorServiceCard from './DoctorServiceCard'
import styles from './DoctorServices.module.scss'
import { P } from '@curawella/curawella-components'
import PropTypes from 'prop-types'
import CardTitle from '../card-title/CardTitle'

const DoctorServices = ({ doctor }) => {
    DoctorServices.propTypes = {
        doctor: PropTypes.object
    }

    // console.log('doctor', doctor)
    return (
        <div className={styles['doctor-services-container']}>
            <CardTitle title='الخدمات' icon='/assets/doctor-profile/icons/medical.svg' />

            {doctor.services && doctor.services.length === 0 ? (
                <P type='p1' other='error-text'>
                    لا يوجد اى عمليات حتى الأن
                </P>
            ) : (
                <div className={styles['list-of-services']}>
                    {doctor.services &&
                        doctor.services.map((service, index) => (
                            <DoctorServiceCard service={service} key={index} />
                        ))}
                </div>
            )}
        </div>
    )
}

export default DoctorServices
