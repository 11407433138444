import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styles from './DoctorVideoCallCard.module.scss'
import './Calendar.scss'
import 'react-calendar/dist/Calendar.css'
import CardTitle from '../card-title/CardTitle'
import { GradientBtn, P } from '@curawella/curawella-components'
import { getWeekDay } from '../../../constants/AppConstants'

/**
 *
 * @param {{
 *  price: string;
 *  days: string[];
 *  handleShowModal: Function;
 * }} props
 * @returns
 */
const DoctorVideoCallCard = ({ price, days, handleShowModal }) => {
    DoctorVideoCallCard.propTypes = {
        price: PropTypes.number,
        days: PropTypes.array,
        handleShowModal: PropTypes.func
    }

    return (
        <div className={styles['doctor-video-call-card-container']}>
            <CardTitle title='مكالمة فيديو' icon='/assets/doctor-profile/icons/video.svg' />
            <div className={styles.content}>
                <P type='p1' other='price'>
                    السعر: <span>{price} ج.م</span>
                </P>
                {days && days.length > 0 ? (
                    <>
                        <P type='p1' other='days'>
                            الأيام المتاحة:{' '}
                            <span>
                                {days
                                    .map((day) => getWeekDay[day])
                                    .join('، ')
                                    .substring(-2)}
                            </span>
                        </P>

                        <GradientBtn
                            title='احجز الأن'
                            align='left'
                            type='gradient'
                            action={() => handleShowModal('ONLINE', price)}
                        />
                    </>
                ) : (
                    <P type='p1' other='error-text'>
                        لا يوجد ايام متاحة
                    </P>
                )}
            </div>
        </div>
    )
}

export default DoctorVideoCallCard
