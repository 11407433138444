import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import { doctorLogo, doctorName } from '../constants'
import { Context } from '../context/Context'
import { MedicalRecord, Wallet } from '../lib'
import CommonRouter from './common'
import PATHS from './paths'

const HomeClinic = lazy(() => import('../lib/pages/home-clinic/HomeClinic'))
// const Wallet = lazy((): any => import('../lib/pages/wallet/Wallet').then((module) => module.Wallet))
// const MedicalRecord = lazy((): any => import('../lib/pages/medical-record/MedicalRecord').then((module) => module.MedicalRecord))
const Appointments = lazy(() => import('../lib/pages/appointments/Appointments'))
const ChatController = lazy(() => import('../lib/controllers/chat-controller'))
const ContactUs = lazy(() => import('../lib/pages/contact-us/ContactUs'))
const Blogs = lazy(() => import('../lib/pages/blogs/Blogs'))
const Blog = lazy(() => import('../lib/pages/blog/Blog'))
const PatientProfile = lazy(() => import('../lib/pages/patient-profile/PatientProfile'))
const Notifications = lazy(() => import('../lib/pages/notifications/Notifications'))
const PaymentConfirm = lazy(() => import('../lib/pages/payment-confirm/PaymentConfirm'))
const Privacy = lazy(() => import('../lib/pages/privacy/Privacy'))
const DoctorProfile = lazy(() => import('../lib/pages/doctor-profile/DoctorProfile'))

type PublicRouterProps = {
    initialPath: string
    doctor: any
    emailAvailable: boolean
    setEmailAvailable: React.SetStateAction<any>
    context: React.Context<{}>
}
/**
 *
 * @param {{
 * initialPath: string
 * }} Props
 * @returns
 */
const PatientRouter = ({ initialPath, doctor, emailAvailable, setEmailAvailable }: PublicRouterProps) => {
    return (
        <CommonRouter
            emailAvailable={emailAvailable}
            initialPath={initialPath}
            isAuthenticated={true}
            custom={true}
            doctorName={doctorName}
            doctorLogo={doctorLogo}
            setEmailAvailable={setEmailAvailable}
        >
            <Route exact path={PATHS.home} component={() => <HomeClinic />} />
            <Route exact path={PATHS.wallet} component={() => <Wallet Context={Context} />} />
            <Route exact path={PATHS.medicalRecord} component={() => <MedicalRecord custom={true} Context={Context} />} />
            <Route exact path={PATHS.contactUs} component={() => <ContactUs Context={Context} />} />
            <Route exact path={PATHS.appointments} component={() => <Appointments custom={true} Context={Context} />} />
            <Route path={PATHS.patientProfile} component={() => <DoctorProfile Context={Context} />} />
            <Route path={PATHS.account} component={() => <PatientProfile custom={true} Context={Context} />} />
            <Route exact path={PATHS.chat} component={() => <ChatController doctor={doctor} Context={Context} />} />
            <Route exact path={PATHS.notifications} component={() => <Notifications />} />
            <Route exact path={PATHS.paymentConfirm} component={() => <PaymentConfirm Context={Context} />} />
            <Route exact path={PATHS.blogs} component={Blogs} />
            <Route path={PATHS.blog} component={Blog} />
            <Route path={PATHS.privacy} component={Privacy} />
        </CommonRouter>
    )
}
export default PatientRouter
