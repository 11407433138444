import React from 'react'
import PropTypes from 'prop-types'
import styles from './CardTextItem.module.scss'
import { P } from '@curawella/curawella-components'

const CardTextItem = ({ title, value }) => {
    CardTextItem.propTypes = {
        title: PropTypes.string,
        value: PropTypes.any
    }

    return (
        <div className={styles['card-text-item-container']}>
            <P type='p1' other='title'>
                {title}:
            </P>
            <div className={styles['title-container']}>
                <RenderData value={value} />
            </div>
        </div>
    )
}

export default CardTextItem

const RenderData = ({ value }) => {
    if (Array.isArray(value)) {
        return value.map((item, index) => (
            <P type='p1' other='value' key={index}>
                {item}
            </P>
        ))
    }

    return <P className={styles['value']}>{value}</P>
}
