import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import StarRatings from 'react-star-ratings'
import { H1, H2, H4, P, GradientBtn } from '@curawella/curawella-components'
import useFetch from '../../../hooks/useFetch'
import Loading from '../../../components/loading/Loading'
import { getDrLevelAr } from '../../../constants/AppConstants'
import { Context } from '../../../../context/Context'

import styles from './ClinicHero.module.scss'
import { getTime } from '../../../helpers/dateTime'
import { doctorUid } from '../../../../constants'

const REMINDER_TITLE_TYPES = {
    CLINIC: ' أستشارة',
    ONLINE: 'أستشارة فيديو'
}
const REMINDER_TYPES = {
    CLINIC: 'CLINIC',
    ONLINE: 'ONLINE'
}

const ClinicHero = ({ bookRef }) => {
    const { doctor, patient } = useContext(Context)

    const [data, loading, error, execute] = useFetch()
    const reminders = data?.data.todayAppointments.length > 0 ? data.data.todayAppointments : undefined

    useEffect(() => {
        if (patient) {
            execute('get', `v2/patient/home/${doctorUid}?patientUid=${patient.uid}`)
        }
    }, [execute, patient])

    useEffect(() => {
        if (data) {
            console.log('data', data)
        }
    }, [data])

    useEffect(() => {
        if (error) {
            console.log('error', error)
        }
    }, [error])

    if (!doctor) {
        return ''
    }

    if (loading) {
        return <Loading />
    }
    return (
        <div className={styles['hero-container']}>
            <div className={styles['hero-content']}>
                <div className={styles['hero-title']}>
                    <H1 type='h2'>{`د/ ${doctor.firstNameAr} ${doctor.lastNameAr}`}</H1>
                    <H2 type='h4'>{`${getDrLevelAr[doctor.level]} ${doctor.specialtyNameAr}`}</H2>

                    <div className={`row ${styles['rating-container']}`}>
                        <div className={styles['star-rating-container']}>
                            <StarRatings starRatedColor='#FFD500' rating={doctor && doctor.rate} starDimension='23px' starSpacing='0' />
                        </div>

                        <P type='p1'>({parseFloat(doctor.rate).toFixed(1)})</P>
                    </div>

                    {reminders ? (
                        reminders.map((reminder) => <ReminderContent doctor={doctor} reminder={reminder} />)
                    ) : (
                        <>
                            <H4 className={styles.about}>{doctor.aboutSummary}</H4>

                            <div className={`row ${styles['btns-container']}`}>
                                <Link to=''>
                                    <GradientBtn title='احجز موعد' type='gradient' action={() => bookRef.current.scrollIntoView()} />
                                </Link>
                                <Link to='/profile'>
                                    <GradientBtn title='عن الدكتور' type='outLinedPrimary' hover='gradient' />
                                </Link>
                            </div>
                        </>
                    )}
                </div>

                <Image src='/assets/home-clinic/img/clinic-hero.png' className={styles['hero-img']} alt='curApp-hero' loading='lazy' />
            </div>
        </div>
    )
}

export default ClinicHero

ClinicHero.propTypes = { bookRef: PropTypes.object }

const ReminderContent = ({ reminder, doctor }) => {
    switch (reminder.appointmentType) {
        case REMINDER_TYPES.CLINIC:
            return (
                <H4 className={styles['clinic-reminder']}>
                    {`لديك ${REMINDER_TITLE_TYPES[reminder.appointmentType]} اليوم الساعه ${getTime(reminder.timestamp)} فى عيادة ${
                        reminder.clinicName
                    }`}
                </H4>
            )
        case REMINDER_TYPES.ONLINE:
            return (
                <div className={styles['reminders-container']}>
                    <H4 className={styles.reminder}>
                        {`لديك ${REMINDER_TITLE_TYPES[reminder.appointmentType]} اليوم الساعه ${getTime(reminder.timestamp)} `}
                    </H4>
                    <a
                        target='target _blank'
                        href={`https://app.call.curawella.com/?roomId=${reminder.callId}&name=Dr. ${doctor.firstName} ${doctor.lastName}`}
                    >
                        <GradientBtn
                            title={
                                <>
                                    <img src='/assets/home-clinic/icons/Path 3345.png' alt='videoCall' />
                                    {' بدء مكالمه الفيديو '}
                                </>
                            }
                            type='gradient'
                        />
                    </a>
                </div>
            )

        default:
            return ''
    }
}
