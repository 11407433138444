import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import styles from './NavBar.module.scss'
import { Nav, Navbar } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { GradientBtn, Brand } from '@curawella/curawella-components'
import NavLinks from '../nav-links/NavLinks'
import SVG from 'react-inlinesvg'
import { Context } from '../../../context/Context'
import { doctorShortName } from '../../../constants'

const links = [
    { title: 'الأطباء', link: '/doctors' },
    { title: 'محفظتي', link: '/wallet' },
    { title: 'المواعيد', link: '/appointments' },
    { title: 'السجل الطبي', link: '/medical-records' },
    { title: 'الأشعارات', link: '/notifications' },
    { title: 'اتصل بنا', link: '/contact-us' }

    // {title: 'EN', link: '/en'}
]

const customLinks = [
    { title: 'عن الدكتور', link: '/profile' },
    { title: 'محفظتي', link: '/wallet' },
    { title: 'المواعيد', link: '/appointments' },
    { title: 'السجل الطبي', link: '/medical-records' },
    { title: 'الأشعارات', link: '/notifications' },
    { title: 'اتصل بنا', link: '/contact-us' }
]
const notAuthLinks = [
    { title: 'عن الدكتور', link: '/profile' },
    { title: 'المقالات', link: '/blogs' },
    { title: 'اتصل بنا', link: '/contact-us' }
]

const authPaths = ['/login', '/register', '/404']
const classNames = {
    '/': 'out',
    '/login': 'login-nav',
    '/register': 'login-nav',
    '/404': 'login-nav'
}
const NavBar = ({ isAuthenticated, custom, app }) => {
    const currentLocation = useLocation().pathname
    const [whiteLinks, setWhiteLinks] = useState(false)
    const [location, setLocation] = useState(currentLocation)
    const { doctor } = useContext(Context)

    useEffect(() => {
        setLocation(currentLocation)
    }, [currentLocation])

    useEffect(() => {
        setLocation(currentLocation)
        setWhiteLinks(authPaths.includes(currentLocation))
    }, [currentLocation])

    return (
        <div className={`${styles['nav-bar-container']} ${styles[classNames[location]] ? styles[classNames[location]] : styles['in']} `}>
            <Navbar expand='lg' className={`${styles.navbar}`} collapseOnSelect>
                <Link to='/'>
                    <Brand
                        custom={custom}
                        // title={`د/ ${doctor.firstNameAr} ${doctor.lastNameAr}`}
                        title={doctorShortName}
                        logo={app && app.logo}
                    />
                </Link>
                <Navbar.Toggle aria-controls='basic-navbar-nav' className={styles['toggle-btn']} />
                <Navbar.Collapse id='basic-navbar-nav' className={`${styles['navbar-collapse']} ${whiteLinks ? styles['white'] : ''}`}>
                    <NavLinks
                        links={!isAuthenticated ? notAuthLinks : custom ? customLinks : links}
                        margin=''
                        activePath={location}
                        whiteLinks={whiteLinks}
                        type='navBar'
                    />
                    <RenderAuth isAuthenticated={isAuthenticated} whiteLinks={whiteLinks} activePath={location} custom={custom} />
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default NavBar

const RenderAuth = ({ isAuthenticated, whiteLinks, activePath, custom }) => {
    if (isAuthenticated) {
        if (custom) {
            return (
                <div className={styles['auth-container']}>
                    <Nav.Link
                        as={Link}
                        eventKey='chat'
                        to='/chat'
                        className={`${styles['auth-icon-container']} ${activePath === '/chat' ? styles['active'] : ''}`}
                    >
                        <SVG src='/assets/navbar/icons/chat.svg' className={styles['auth-icon']} />
                    </Nav.Link>

                    <Nav.Link
                        as={Link}
                        eventKey='account'
                        to='/account'
                        className={`${styles['auth-icon-container']} ${activePath === '/account' ? styles['active'] : ''}`}
                    >
                        <SVG src='/assets/navbar/icons/user.svg' className={styles['auth-icon']} />
                    </Nav.Link>
                </div>
            )
        }
        return (
            <div className={styles['auth-container']}>
                <Nav.Link
                    as={Link}
                    eventKey='notifications'
                    to='/notifications'
                    className={`${styles['auth-icon-container']} ${activePath === '/notifications' ? styles['active'] : ''}`}
                >
                    <SVG src='/assets/navbar/icons/notification.svg' className={styles['auth-icon']} />
                </Nav.Link>
                <Nav.Link
                    as={Link}
                    eventKey='chat'
                    to='/chat'
                    className={`${styles['auth-icon-container']} ${activePath === '/chat' ? styles['active'] : ''}`}
                >
                    <SVG src='/assets/navbar/icons/chat.svg' className={styles['auth-icon']} />
                </Nav.Link>

                <Nav.Link
                    as={Link}
                    eventKey='account'
                    to='/account'
                    className={`${styles['auth-icon-container']} ${activePath === '/account' ? styles['active'] : ''}`}
                >
                    <SVG src='/assets/navbar/icons/user.svg' className={styles['auth-icon']} />
                </Nav.Link>
            </div>
        )
    }

    return (
        <Nav.Link to='/login' as={Link} eventKey='login'>
            <GradientBtn
                title='تسجيل الدخول'
                type={whiteLinks ? 'outLinedWhite' : 'gradient'}
                hover={whiteLinks ? 'solidWhite' : 'gradient'}
                // action={() => navigateToLogin()}
            />
        </Nav.Link>
    )
}

NavBar.propTypes = {
    isAuthenticated: PropTypes.bool,
    custom: PropTypes.bool,
    app: PropTypes.object
}

RenderAuth.propTypes = {
    isAuthenticated: PropTypes.bool,
    whiteLinks: PropTypes.bool,
    activePath: PropTypes.string,
    custom: PropTypes.bool
}
