import { useCallback, useContext, useEffect, useState } from 'react'

import moment from 'moment'
import publicIp from 'public-ip'
import './App.scss'
import { appId, doctorUid } from './constants'
import { Context } from './context/Context'
import Loading from './lib/components/loading/Loading'
import firebaseApp from './lib/constants/firebaseApp'
import useFetch from './lib/hooks/useFetch'
import backendConfig from './lib/Models/backendConfig'
import PublicRouter from './routers/public'
import PatientRouter from './routers/patient'

const initialPath = window.location.pathname

function App() {
    if (process.env && process.env.NODE_ENV !== 'development') {
        console.log = () => {}
    }

    const [loading, setLoading] = useState(true)
    const [patientData, patientLoading, patientError, patientExecute] = useFetch()
    const [doctorData, doctorLoading, doctorError, doctorExecute] = useFetch()
    const { doctor, setDoctor, patient, setPatient, setIP } = useContext(Context)
    const [emailAvailable, setEmailAvailable] = useState()
    const getDrData = useCallback(
        async (uid) => {
            if (typeof uid !== 'undefined') {
                patientExecute('get', `v2/patient/profile/${uid}`)
            } else {
                setPatient(undefined)
            }
            const ip = await publicIp.v4()
            const body = { appId, ip, patientUid: uid }
            doctorExecute('patch', `v2/patient/doctor/profile/${doctorUid}/${moment().locale('en').format('Z')}`, body)

            setLoading(false)
            setIP(ip)
        },
        [doctorExecute, patientExecute, setIP, setPatient]
    )

    useEffect(() => {
        if (patientData) {
            console.log('patientData', patientData)
            setPatient(patientData.results)
            if (patientData.results.email === null) {
                setEmailAvailable(true)
            }
        } else {
            setPatient(undefined)
        }
    }, [patientData, setPatient])

    useEffect(() => {
        firebaseApp.auth().onAuthStateChanged(async (user) => {
            backendConfig.updateAuthToken()
            if (user) {
                getDrData(user.uid)
            } else {
                console.log('user NOT logged in')
                getDrData()
            }
        })
    }, [getDrData])

    useEffect(() => {
        if (doctorData) {
            setDoctor(doctorData.data)
        }
    }, [doctorData, setDoctor])

    // ====================Errors====================

    useEffect(() => {
        if (patientError) {
            console.log('patient error:  ', patientError)
        }
    }, [patientError])

    useEffect(() => {
        if (doctorError) {
            // TODO: Handle doctor not found !
            console.log('doctor error:  ', doctorError)
        }
    }, [doctorError])

    if (loading || doctorLoading || patientLoading) {
        return (
            <div className='app-container'>
                <Loading />
            </div>
        )
    } else if (!patient) {
        return <PublicRouter initialPath={initialPath} />
    }

    return <PatientRouter initialPath={initialPath} doctor={doctor} setEmailAvailable={setEmailAvailable} emailAvailable={emailAvailable} />
}

export default App
