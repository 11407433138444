import { GradientBtn, ModalView, OutlinedInput } from '@curawella/curawella-components'
import { useEffect, useState, useContext, useCallback } from 'react'
import { Context } from '../../../context/Context'
import useFetch from '../../hooks/useFetch'

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]{2,}.[A-Z]{2,4}$/i

const EmailChecker = ({ setEmailAvailable, nextAction }) => {
    const { patientUid, setPatient } = useContext(Context)

    const [showEmailModal, setShowEmailModal] = useState(true)
    const [showSuccessModal, setShowSuccessModal] = useState(false)

    const [customInputs, setCustomInputs] = useState({ email: '', password: '' })
    const [customInputsErrors, setCustomInputsErrors] = useState({ email: '', password: '' })
    const [data, loading, error, execute] = useFetch()
    const [userData, userLoading, userError, userExecute] = useFetch()

    const getUserData = useCallback(() => {
        userExecute('get', `v2/patient/profile/${patientUid}`)
    }, [patientUid, userExecute])

    useEffect(() => {
        if (userData) {
            console.log('user data:  ', userData.results)
            setPatient(userData.results)
            if (nextAction) {
                nextAction()
            }
        }
    }, [nextAction, setPatient, userData])

    /**
     * Handle submit data
     */
    const submitAddEmail = () => {
        const { email, password } = customInputs
        const { isValid, errors } = checkCustomValues(email, password)

        setCustomInputsErrors(errors)
        if (!isValid) {
            return
        }
        execute('put', `v2/patient/profile/${patientUid}/email`, {
            email: customInputs.email,
            password: customInputs.password
        })
    }

    useEffect(() => {
        if (data) {
            setShowEmailModal(false)
            setShowSuccessModal(true)
            getUserData()
        }
    }, [data, getUserData])

    useEffect(() => {
        if (error) {
            setCustomInputsErrors((prev) => ({ ...prev, ...handleError(error.code) }))
        }
    }, [error])

    return (
        <>
            {/* Edit modal */}
            <ModalView
                show={showEmailModal}
                setShow={(v) => {
                    setShowEmailModal(v)
                    setEmailAvailable(false)
                    setCustomInputsErrors({})
                }}
                type='custom'
                title='أضف بريدك الإلكترونى'
                customView={
                    <>
                        <OutlinedInput
                            title='البريد الإلكترونى'
                            type='email'
                            value={customInputs.email}
                            setValue={(e) => setCustomInputs({ ...customInputs, email: e.target.value })}
                            name='email'
                            error={customInputsErrors.email}
                            placeholder='Email'
                        />
                        <OutlinedInput
                            title='كلمة السر'
                            value={customInputs.password}
                            setValue={(e) => setCustomInputs({ ...customInputs, password: e.target.value })}
                            name='Password'
                            autoComplete='new-password'
                            error={customInputsErrors.password}
                            type='password'
                            placeholder='password'
                        />
                        <GradientBtn
                            title={loading || userLoading ? 'جار الأرسال' : 'ارسال'}
                            type='gradient'
                            action={() => submitAddEmail('email')}
                            disabled={loading || userLoading}
                        />
                    </>
                }
            />

            {/* Success modal */}
            {showSuccessModal && (
                <ModalView
                    show={showSuccessModal}
                    setShow={(v) => setShowSuccessModal(v)}
                    type='success'
                    title='تأكيد'
                    description='تم إاضافة بريدك الإلكترونى بنجاح'
                    btn={{ title: 'اغلاق', action: () => setShowSuccessModal(false) }}
                />
            )}
        </>
    )
}

export default EmailChecker

/**
 *
 * @param {string} valueToBeChecked
 * @returns
 */
const checkCustomValues = (email, password) => {
    let isValid = true
    const errors = { phone: '', email: '', newPassword: '', password: '' }

    if (!email) {
        errors.email = 'البريد الإلكترونى مطلوب'
        isValid = false
    } else if (!emailRegex.test(email)) {
        errors.email = 'بريد إليكترونى غير صحيح'
        isValid = false
    }

    // Validate password
    if (typeof password !== 'string' || !password) {
        errors.password = 'كلمة المرور مطلوبة'
        isValid = false
    } else if (password.length < 6) {
        errors.password = 'كلمة المرور غير صحيحة'
        isValid = false
    }

    return { isValid, errors }
}

function handleError(code) {
    switch (code) {
        case 'auth/invalid-email':
            return { email: 'بريد الإكترونى غير صحيح' }
        case 'auth/email-already-exists':
            return { email: 'البريد الإلكتروني مستخدم' }
        case 'auth/wrong-password':
            return { password: 'كلمة السر غير صحيحة' }
    }
}
