import React, { useEffect, useState } from 'react'
import { Image } from 'react-bootstrap'
import { OutlinedInput, GradientBtn, H4, P } from '@curawella/curawella-components'

import { getCountries, getStatesByShort } from 'countrycitystatejson'
import { egGovAr } from '../../../constants/AppConstants'
import styles from './PersonalInfo.module.scss'

const getFields = (input, field) => {
    const output = []
    for (let i = 0; i < input.length; ++i) {
        output.push(input[i][field])
    }
    return output
}
const countriesAll = getCountries()
const countries = getFields(countriesAll, 'native')

const PersonalInfo = ({ user, setUser, userErrors, handleClick, setNewAvatar }) => {
    const [cities, setCities] = useState([])

    useEffect(() => {
        if (user && user.country) {
            const countryIdx = countries.findIndex((tmp) => tmp === user.country)

            if (countryIdx < 0) {
                return
            }

            const shortName = countriesAll[countryIdx].shortName
            const citiesTmp = shortName.toLowerCase() === 'eg' ? egGovAr : getStatesByShort(shortName)

            setCities(citiesTmp)
        }
    }, [user])

    const getFullName = () => {
        const { firstName, secondName, thirdName, lastName } = user;
        const userName = [firstName, secondName, thirdName, lastName];

        const fullName = userName.filter(name => name !== null).join(' ');
        return fullName;
    }

    return (
        <div className={styles['form-container']}>
            <div className={styles['avatar-container']}>
                <Image loading='lazy' className={styles['avatar']} src={user.avatar} />
                <div className={styles['change-avatar-container']}>
                    <Image className={styles['change-avatar']} src='/assets/full-form/icons/camera.svg' />
                </div>
                <input type='file' onChange={(e) => setNewAvatar(e.target.files[0])} accept='.png, .jpg, .jpeg' />
            </div>
            <H4>{getFullName()}</H4>
            <P type='title'>بيانات المستخدم</P> {/* Name */}
            <div className={styles['inputs-row']}>
                <OutlinedInput
                    title='الأسم الأول'
                    width='48.5%'
                    value={user.firstName}
                    setValue={(e) => setUser({ ...user, firstName: e.target.value })}
                    disabled={true}
                    error={userErrors.firstName}
                    lang='AR'
                />
                <OutlinedInput
                    title='الأسم الثاني'
                    width='48.5%'
                    value={user.secondName}
                    setValue={(e) => setUser({ ...user, secondName: e.target.value })}
                    disabled={true}
                    error={userErrors.secondName}
                    lang='AR'
                />
            </div>
            <div className={styles['inputs-row']}>
                <OutlinedInput
                    title='الأسم الثالث'
                    width='48.5%'
                    value={user.thirdName}
                    setValue={(e) => setUser({ ...user, thirdName: e.target.value })}
                    disabled={true}
                    error={userErrors.thirdName}
                    lang='AR'
                />

                <OutlinedInput
                    title='الأسم الأخير'
                    width='48.5%'
                    value={user.lastName}
                    setValue={(e) => setUser({ ...user, lastName: e.target.value })}
                    disabled={true}
                    error={userErrors.lastName}
                    lang='AR'
                />
            </div>
            {/* Phone number */}
            <div className={styles['input-container']}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <OutlinedInput
                        className={styles['input-ltr']}
                        title='رقم الهاتف'
                        value={user.phone}
                        setValue={(e) => setUser({ ...user, phone: e.target.value })}
                        handleClick={handleClick}
                        name='phone'
                        width='100%'
                        error={userErrors.phone}
                        lang='AR'
                    />
                </div>

                <div className={styles['overlay']} onClick={() => handleClick('phone')} />
            </div>
            {/* Email */}
            <div className={styles['input-container']}>
                <OutlinedInput
                    title='البريد الألكترونى'
                    value={user.email}
                    setValue={(e) => setUser({ ...user, email: e.target.value })}
                    handleClick={handleClick}
                    name='email'
                    error={userErrors.email}
                    lang='AR'
                />
                <div className={styles['overlay']} onClick={() => handleClick('email')} />
            </div>
            <>
                <P
                    type='p1'
                    other='change-password'
                    autoComplete='new-password'
                    className={styles['change-password']}
                    handleClick={() => handleClick('newPassword')}
                >
                    تغيير كلمة المرور
                </P>
            </>
            <P type='title'>معلومات شخصية</P>
            {/* Date of birth */}
            <OutlinedInput
                title={'تاريخ الميلاد'}
                type='date'
                value={user.bd}
                setValue={(e) => setUser({ ...user, bd: e.target.value })}
                error={userErrors.bd}
                lang='AR'
                max='today'
            />
            {/* Gender */}
            <div className={styles['gender-container']}>
                <P type='p2' other='title'>
                    النوع
                </P>
                <div className={styles['btns-container']}>
                    <GradientBtn
                        title='ذكر'
                        type={user.gender === '1' ? 'solidPrimary' : 'light'}
                        hover='solidPrimary'
                        width='48%'
                        action={() => setUser({ ...user, gender: '1' })}
                    />
                    <GradientBtn
                        title='انثي'
                        type={user.gender === '0' ? 'solidPrimary' : 'light'}
                        hover='solidPrimary'
                        width='48%'
                        action={() => setUser({ ...user, gender: '0' })}
                    />
                </div>
            </div>
            {userErrors.gender && (
                <P type='p2' other='error-text'>
                    * {userErrors.gender}
                </P>
            )}
            {/* country & city */}
            <div className={styles['inputs-row']}>
                <OutlinedInput
                    required
                    title={'البلد'}
                    type='select'
                    options={countries}
                    width='48.5%'
                    value={user.country}
                    setValue={(e) => setUser((prev) => ({ ...prev, country: e.target.value, city: '' }))}
                    error={userErrors.country}
                    lang='AR'
                />

                <OutlinedInput
                    title={'المحافظة'}
                    type='select'
                    options={cities}
                    width='48.5%'
                    value={user.city}
                    setValue={(e) => setUser((prev) => ({ ...user, city: e.target.value }))}
                    error={userErrors.city}
                    lang='AR'
                />
            </div>
            <OutlinedInput
                title={'الوزن'}
                value={user.weight}
                setValue={(e) => setUser({ ...user, weight: e.target.value })}
                name='weight'
                error={userErrors.weight}
                lang='AR'
                type='number'
                max={100}
            />
            <OutlinedInput
                title={'الطول'}
                value={user.height}
                setValue={(e) => setUser({ ...user, height: e.target.value })}
                name='weight'
                error={userErrors.height}
                lang='AR'
                type='number'
            />
        </div>
    )
}

export default PersonalInfo
