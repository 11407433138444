const PATHS = {
    // Public
    home: '/',
    login: '/login',
    register: '/register',
    forgetPassword: '/forget-password',
    contactUs: '/contact-us',
    doctorProfile: '/profile',
    blogs: '/blogs',
    blog: '/blog/:blogId',
    privacy: '/privacy',

    // Patient
    wallet: '/wallet',
    medicalRecord: '/medical-records',
    account: '/account',
    patientProfile: '/profile',
    chat: '/chat',
    notifications: '/notifications',
    paymentConfirm: '/paymentConfirm',
    appointments: '/appointments'
}

export default PATHS
