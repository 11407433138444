import React from 'react'
import styles from './WalletHero.module.scss'
import { Image } from 'react-bootstrap'
import { H3 } from '@curawella/curawella-components'

const WalletHero = ({ balance }) => {
    return (
        <div className={styles['wallet-hero-container']}>
            <div className={styles['wallet-hero-content']}>
                <div className={styles['current-credit']}>
                    <H3 other='title'>رصيدك الحالى</H3>
                    <H3 other='credit'>{balance / 100} ج.م</H3>
                </div>
                <Image
                    src='/assets/wallet/imgs/Wallet.png'
                    className={styles['wallet-hero-img']}
                    alt='wallet-hero'
                />
            </div>
        </div>
    )
}

export default WalletHero
