import React from 'react'
import { H4, SubHeader, Gallery, ModalView, GradientBtn } from '@curawella/curawella-components'
import Msgs from './msgs/Msgs'
import { useHistory } from 'react-router-dom'
import styles from './Chat.module.scss'

const Chat = ({
    doctor,
    chatValues,
    msg,
    setMsg,
    selectedFiles,
    setSelectedFiles,
    setImages,
    setRenderGallery,
    renderGallery,
    show,
    setShow,
    patientUid,
    uploadState,
    Images,
    setShowConfirmModal,
    handleSendMsg,
    sendMsg,
    setShowNoCreditModal,
    showNoCreditModal,
    showConfirmModal,
    shouldPayData,
    topReached,
    fillChat,
    sendLoading
}) => {
    const history = useHistory()

    return (
        <div className={styles['chat-container']}>
            <SubHeader title='الرسائل' />

            <div className={styles['chat-content']}>
                <Msgs
                    msgs={chatValues}
                    msg={msg}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={(value) => setSelectedFiles(value)}
                    sendMsg={handleSendMsg}
                    setImages={(value) => setImages(value)}
                    setRenderGallery={(value) => setRenderGallery(value)}
                    show={show}
                    setShow={(value) => setShow(value)}
                    patientUid={patientUid}
                    uploadState={uploadState}
                    shouldPayData={shouldPayData}
                    topReached={topReached}
                    fillChat={fillChat}
                    setMsg={setMsg}
                    sendLoading={sendLoading}
                />
            </div>
            {renderGallery && (
                <Gallery images={Images} closeAction={() => setRenderGallery(false)} />
            )}
            {shouldPayData === false || doctor.message === '0' ? (
                ''
            ) : (
                <ModalView
                    type='customView'
                    show={showConfirmModal}
                    setShow={() => setShowConfirmModal(false)}
                    customView={
                        <div className={styles['confirmation-modal-container']}>
                            <H4>{`تكلفة هذه الرسالة ${doctor.message} جنيه`}</H4>
                            <div className={styles['btns-row']}>
                                <GradientBtn
                                    type='redGradient'
                                    title='الغاء'
                                    action={() => setShowConfirmModal(false)}
                                />
                                <GradientBtn
                                    type='gradient'
                                    title='ارسال'
                                    action={sendLoading ? () => {} : () => sendMsg()}
                                />
                            </div>
                        </div>
                    }
                />
            )}

            <ModalView
                type='customView'
                show={showNoCreditModal}
                setShow={() => setShowNoCreditModal(false)}
                customView={
                    <div className={styles['confirmation-modal-container']}>
                        <H4>رصيدك غير كافي</H4>
                        <div className={styles['btns-row']}>
                            <GradientBtn
                                type='redGradient'
                                title='الغاء'
                                action={() => setShowNoCreditModal(false)}
                            />
                            <GradientBtn
                                type='gradient'
                                title='اشحن الرصيد'
                                action={() => history.push('/wallet')}
                            />
                        </div>
                    </div>
                }
            />
        </div>
    )
}

export default Chat
