import React, { useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'

import BlogsLanding from './blogs-landing/BlogsLanding'
import BookLanding from './book-landing/BookLanding'
import ClinicHero from './clinic-hero/ClinicHero'
import ContactUsLandingClinic from './contact-us-clinic-landing/ContactUsLandingClinic'
import { Context } from '../../../context/Context'

import styles from './HomeClinic.module.scss'
import DownloadAppClinic from './download-app-clinic/DownloadAppClinic'
import firebaseApp from '../../constants/firebaseApp'

const HomeClinic = () => {
    const bookRef = useRef(null)

    useEffect(() => {
        const analytics = firebaseApp.analytics()
        analytics.screenView(analytics.screens.home)

        window.scrollTo(0, 0)
    }, [])
    return (
        <div className={styles['home-clinic-container']}>
            <ClinicHero bookRef={bookRef} />
            <BookLanding bookRef={bookRef} Context={Context} />
            <BlogsLanding />
            <DownloadAppClinic />
            <ContactUsLandingClinic />
        </div>
    )
}

export default withRouter(HomeClinic)
