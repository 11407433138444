import React, { useEffect } from 'react'
import styles from './Blogs.module.scss'
import { H3, H4, SubHeader } from '@curawella/curawella-components'
import useFetch from '../../hooks/useFetch'
import { doctorUid } from '../../constants/AppConstants'
import firebaseApp from '../../constants/firebaseApp'

import BlogCard from './blog-card/BlogCard'
import Loading from '../../components/loading/Loading'
const Blogs = () => {
    const [blogsData, loading, error, execute] = useFetch()
    const pinned = blogsData?.results?.pinned
    const unpinned = blogsData?.results?.unpinned

    useEffect(() => {
        const analytics = firebaseApp.analytics()

        analytics.screenView(analytics.screens.blogs)
    }, [])

    useEffect(() => {
        execute('get', `v2/patient/blogs/?doctorUid=${doctorUid}`)
    }, [execute])

    useEffect(() => {
        if (error) {
            console.log('error', error)
        }
    }, [error])

    if (loading || !pinned || !unpinned) {
        return <Loading />
    }

    if (pinned.length === 0 && unpinned.length === 0) {
        return (
            <div className={styles['blogs-container']}>
                <SubHeader title='المقالات' />

                <div className={styles['blogs-content']}>
                    <H4 className={styles['no-blogs']}>لا يوجد مقالات حتى الآن</H4>
                </div>
            </div>
        )
    }

    return (
        <div className={styles['blogs-container']}>
            <SubHeader title='المقالات' />

            <div className={styles['blogs-content']}>
                {[...pinned, ...unpinned].map((blog, index) => (
                    <BlogCard blog={blog} key={blog.key} />
                ))}
            </div>
        </div>
    )
}

export default Blogs
