import React, { useContext, useState } from 'react'
import { GradientBtn, SectionTitle, ModalView, H4, P } from '@curawella/curawella-components'
import PropTypes from 'prop-types'
import { Image } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import { getWeekDay } from '../../../constants/AppConstants'
import BookCard from '../../../components/book-card/BookCard'

import styles from './BookLanding.module.scss'
import firebaseApp from '../../../constants/firebaseApp'
import EmailChecker from '../../../components/email-checker/EmailChecker'

const BookLanding = ({ bookRef, Context }) => {
    const [showModal, setShowModal] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [showContent, setShowContent] = useState(null)
    const [appointmentType, setAppointmentType] = useState(null)
    const [selectedClinicId, setSelectedClinicId] = useState(null)
    const [currentPrice, setCurrentPrice] = useState(null)
    const history = useHistory()
    const { patientUid, doctor, patient } = useContext(Context)
    const { clinics, online, onlineAvailableDays } = doctor
    const [emailAvailable, setEmailAvailable] = useState()

    const setModalData = (type, price, clinicId) => {
        setAppointmentType(type)
        setSelectedClinicId(clinicId)
        setCurrentPrice(price)
    }

    const handleShowModal = () => {
        // No user is signed in.
        if (!patientUid) {
            setShowConfirmModal(true)
            // User signed with no email
        } else if (patient.email === null) {
            setEmailAvailable(true)
            // User is signed in.
        } else {
            toggleModal(true)
        }
    }

    const toggleModal = (value) => {
        if (value === true) {
            setShowModal(true)
        } else {
            setCurrentPrice(null)
            setShowModal(false)
        }
    }

    const onSubmit = (selectedDate, selectedTime) => {
        const analytics = firebaseApp.analytics()
        analytics.logEvent(analytics.events.booking)

        history.push({
            pathname: '/paymentConfirm',
            state: {
                date: selectedDate,
                time: selectedTime,
                price: currentPrice,
                type: appointmentType,
                clinicId: selectedClinicId
            }
        })
    }

    const navigateToLogin = () => {
        history.push('/login')
    }

    const handleShowContent = (id) => setShowContent((prev) => (prev === id ? null : id))

    return (
        <div className={styles['book-landing-container']} ref={bookRef}>
            <SectionTitle title='احجز موعد' />

            <H4>حدد نوع الحجز واختار اليوم والموعد المناسب لك واحجز استشارتك الطبية الان</H4>

            <div className={styles['book-landing-content']}>
                <Image src='/assets/home-clinic/img/book.png' className={styles['book-img']} alt='book-img' loading='lazy' />
                <div className={styles['book-cards-container']}>
                    <div onClick={() => handleShowContent('online')} key='online'>
                        <div className={styles['book-card-container']}>
                            <div className={styles['icon-container']}>
                                <Image src='/assets/home-clinic/icons/video.svg' className={styles['icon']} alt='icon' />
                            </div>
                            <P type='title'>Video Call</P>
                            <Image
                                src={showContent === 'online' ? '/assets/home-clinic/icons/minus.svg' : '/assets/home-clinic/icons/add.svg'}
                                className={styles['add-icon']}
                                alt='icon'
                            />
                        </div>

                        {showContent === 'online' && (
                            <div className={styles['extend-container']}>
                                <P type='p1'>
                                    السعر: <span>{online} ج.م</span>
                                </P>
                                {onlineAvailableDays.length > 0 ? (
                                    <>
                                        <P type='p1'>
                                            الأيام المتاحه:
                                            <span>{onlineAvailableDays.map((day) => ` ,${getWeekDay[day]}`)}</span>
                                        </P>

                                        <GradientBtn
                                            type='gradient'
                                            title='احجز الأن'
                                            action={() => {
                                                setModalData('ONLINE', online)
                                                handleShowModal()
                                            }}
                                        />
                                    </>
                                ) : (
                                    <P type='p1'>لا يوجد ايام متاحه</P>
                                )}
                            </div>
                        )}
                    </div>

                    {clinics &&
                        clinics.map((clinic, index) => (
                            <div onClick={() => handleShowContent(index)} key={index}>
                                <div className={styles['book-card-container']}>
                                    <div className={styles['icon-container']}>
                                        <Image src='/assets/home-clinic/icons/clinic.svg' className={styles['icon']} alt='icon' />
                                    </div>
                                    <P type='title'>{clinic.name}</P>
                                    <Image
                                        src={
                                            showContent === index
                                                ? '/assets/home-clinic/icons/minus.svg'
                                                : '/assets/home-clinic/icons/add.svg'
                                        }
                                        className={styles['add-icon']}
                                        alt='icon'
                                    />
                                </div>

                                {showContent === index && (
                                    <div className={styles['extend-container']}>
                                        <P type='p1'>
                                            السعر: <span>{clinic.price} ج.م</span>
                                        </P>

                                        {clinic.availableDays.length > 0 ? (
                                            <>
                                                <P type='p1'>
                                                    الأيام المتاحه:
                                                    <span>{`${clinic.availableDays.map((day) => ` ${getWeekDay[day]}`)}`}</span>
                                                </P>

                                                <GradientBtn
                                                    type='gradient'
                                                    title='احجز الأن'
                                                    action={() => {
                                                        setModalData('CLINIC', clinic.price, clinic.id)
                                                        handleShowModal()
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            <P type='p1'>لا يوجد ايام متاحه</P>
                                        )}
                                    </div>
                                )}
                            </div>
                        ))}
                </div>
            </div>

            {patient && (
                <ModalView
                    type='customView'
                    customView={
                        <BookCard
                            center={true}
                            selectedAppointmentType={appointmentType}
                            selectedClinicId={selectedClinicId}
                            patientUid={patient.uid}
                            onSubmit={onSubmit}
                        />
                    }
                    title='تأكيد الحجز'
                    show={showModal}
                    setShow={(value) => toggleModal(value)}
                    btn={{ title: '' }}
                />
            )}

            <ModalView
                type='confirmation'
                show={showConfirmModal}
                setShow={(value) => setShowConfirmModal(value)}
                title='تأكيد'
                description='برجاء تسجل الدخول لكى تتمكن من حجز الموعد'
                btn={{ title: 'تسجيل الدخول', action: () => navigateToLogin() }}
                onCancel={() => setShowConfirmModal(false)}
            />

            {emailAvailable && <EmailChecker setEmailAvailable={setEmailAvailable} nextAction={handleShowModal} />}
        </div>
    )
}

export default BookLanding

BookLanding.propTypes = {
    bookRef: PropTypes.object,
    clinics: PropTypes.array,
    online: PropTypes.number,
    onlineAvailableDays: PropTypes.array
}
