import React from 'react'
import { GradientBtn, OutlinedInput } from '@curawella/curawella-components'
import styles from './CustomInputComponent.module.scss'

const CustomInputComponent = ({ type, customInputs, setCustomInputs, customInputsErrors, submitCustomUserData, loading }) => {
    if (type === 'phone') {
        return (
            <>
                <OutlinedInput
                    className={styles['input-ltr']}
                    title='رقم الهاتف'
                    value={customInputs.phone}
                    setValue={(e) => setCustomInputs({ ...customInputs, phone: e.target.value })}
                    name='email'
                    error={customInputsErrors.phone}
                    placeholder='رقم الهاتف'
                />

                <OutlinedInput
                    title='كلمة السر'
                    value={customInputs.password}
                    setValue={(e) => setCustomInputs({ ...customInputs, password: e.target.value })}
                    name='password'
                    error={customInputsErrors.password}
                    type='password'
                    placeholder='كلمة السر'
                    autoComplete='new-password'
                />
                <GradientBtn
                    title={loading ? 'جار الأرسال' : 'ارسال'}
                    type='gradient'
                    action={() => submitCustomUserData('phone')}
                    disabled={loading && true}
                />
            </>
        )
    } else if (type === 'email') {
        return (
            <>
                <OutlinedInput
                    className={styles['input-ltr']}
                    title='البريد الإلكترونى'
                    value={customInputs.email}
                    setValue={(e) => setCustomInputs({ ...customInputs, email: e.target.value })}
                    name='email'
                    error={customInputsErrors.email}
                />
                <OutlinedInput
                    title='كلمة السر'
                    value={customInputs.password}
                    setValue={(e) => setCustomInputs({ ...customInputs, password: e.target.value })}
                    name='password'
                    error={customInputsErrors.password}
                    type='password'
                    autoComplete='new-password'
                />
                <GradientBtn
                    title={loading ? 'جار الأرسال' : 'ارسال'}
                    type='gradient'
                    action={() => submitCustomUserData('email')}
                    disabled={loading && true}
                />
            </>
        )
    }
    return (
        <>
            <OutlinedInput
                title='كلمة السر القديمة'
                value={customInputs.password}
                setValue={(e) => setCustomInputs({ ...customInputs, password: e.target.value })}
                name='password'
                error={customInputsErrors.password}
                type='password'
            />
            <OutlinedInput
                title='كلمة السر الجديدة'
                value={customInputs.newPassword}
                setValue={(e) => setCustomInputs({ ...customInputs, newPassword: e.target.value })}
                name='newPassword'
                error={customInputsErrors.newPassword}
                type='password'
                autoComplete='new-password'
            />
            <GradientBtn
                title={loading ? 'جار الإرسال' : 'إرسال'}
                type='gradient'
                action={() => submitCustomUserData('newPassword')}
                disabled={loading && true}
            />
        </>
    )
}

export default CustomInputComponent
