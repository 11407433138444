/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { P, SubHeader, ModalView } from '@curawella/curawella-components'
import { useLocation, useHistory } from 'react-router-dom'
import { Image } from 'react-bootstrap'

import useFetch from '../../hooks/useFetch'
import InfoCard from './info-card/InfoCard'
import PaymentMethod from './payment-method/PaymentMethod'
import { appId, doctorUid } from '../../constants/AppConstants'
import Loading from '../../components/loading/Loading'
import styles from './PaymentConfirm.module.scss'
import firebaseApp from '../../constants/firebaseApp'

const modalType = {
    CARD: 'customViewIframeLarge',
    WALLET: 'customViewIframeLarge',
    VAL_U: 'customViewIframeLarge',
    TOKEN: 'customViewIframeLarge',
    AGGREGATOR: 'customView',
    CASH_BOX: 'customView',
    ONSITE: 'customView'
}

const paymentType = {
    ONLINE: 'مكالمة الفيديو',
    CHARGE: 'الشحن',
    CLINIC: 'العيادة',
    SERVICE: 'العمليه'
}

const payLinks = {
    CHARGE: 'v2/patient/payment/cashbox',
    SERVICE: 'v2/patient/payment/service',
    ONLINE: 'v2/patient/payment/appointment',
    CLINIC: 'v2/patient/payment/appointment'
}

const PaymentConfirm = ({ Context }) => {
    const [togglePayment, setTogglePayment] = useState('')
    const [showAddPromoModal, setShowAddPromoModal] = useState(false)
    const [promo, setPromo] = useState('')
    const [promoError, setPromoError] = useState('')
    const [paymentData, setPaymentData] = useState(null)
    const [showConfirmationModal, setShowConfirmationModal] = useState(null)
    const [currentSelectedType, setCurrentSelectedType] = useState(null)
    const [addPromoData, addPromoLoading, addPromoError, addPromoExecute] = useFetch()
    // const [promoCodesData, promoCodesLoading, promoCodesError, promoCodesExecute] = useFetch()
    const [savedCardsData, savedCardsLoading, savedCardsError, savedCardsExecute] = useFetch()
    const [calculateFeesData, calculateFeesLoading, calculateFeesError, calculateFeesExecute] = useFetch()
    const [chargeData, chargeLoading, chargeError, chargeExecute] = useFetch()
    const [selectedCard, setSelectedCard] = useState('')
    const [phoneNumber, setPhoneNumber] = useState(null)
    const [phoneError, setPhoneError] = useState('')
    const [showPaymentModal, setShowPaymentModal] = useState(null)

    const { patientUid } = useContext(Context)
    const history = useHistory()
    const location = useLocation()
    const locationData = location.state

    useEffect(() => {
        const analytics = firebaseApp.analytics()

        analytics.screenView(analytics.screens.paymentConfirm)
    }, [])

    // to open or close paymentMethods Cards
    const handleTogglePayment = (value) => {
        // value is one of paymentMethods ('TOKEN', 'WALLET', ..etc)
        if (value === togglePayment) {
            // if i want to close
            setTogglePayment('')
            setCurrentSelectedType(null)
            if (locationData.type === 'SERVICE' || locationData.type === 'CHARGE') {
                resetFeesAndTotal()
            } else {
                resetData()
            }

            // setSelectedCard('')
        } else {
            // if i want to open
            setTogglePayment(value)
            setCurrentSelectedType(value)
        }
        setSelectedCard('')
    }

    // get promoCodes
    // const getPromoCodesData = useCallback(() => {
    //     promoCodesExecute('get', `v2/patient/promocodes/${patientUid}/${doctorUid}`)
    // }, [patientUid, promoCodesExecute])

    // get savedCards "used for methodType==='TOKEN'"
    const getSavedCards = useCallback(() => {
        savedCardsExecute('get', `v2/patient/payment/cards/${patientUid}`)
    }, [patientUid, savedCardsExecute])

    // 1- SCROLL TO TOP
    // 2- GET promoCodes
    // 3- GET savedCards
    useEffect(() => {
        window.scrollTo(0, 0)
        // getPromoCodesData()
        getSavedCards()
    }, [getSavedCards])

    // execute add promoCode
    const handleAddPromo = () => {
        setPromoError(null)
        addPromoExecute('post', `v2/patient/promocodes/${patientUid}/${doctorUid}`, {
            code: promo
        })
    }

    //execute chargeCredit
    const chargeCredit = (body) => {
        console.log(locationData.type)
        const path = payLinks[locationData.type]
        chargeExecute('post', path, body)
    }

    // handle body for chargeCredit ==> submit payment
    const callChargeCredit = (value) => {
        const analytics = firebaseApp.analytics()
        analytics.logEvent(analytics.events[locationData.type][value])

        let body = { patientUid, appId, paymentMethod: value }

        if (value === 'TOKEN') {
            body = { ...body, cardToken: selectedCard }
        } else if (value === 'WALLET') {
            const error = validatePhone(phoneNumber)
            setPhoneError(error)

            if (error !== '') {
                return
            }
            body = { ...body, paymentPhoneNumber: phoneNumber }
        }

        if (locationData.type === 'CLINIC') {
            body = {
                ...body,
                appointmentType: locationData.type,
                appointmentStart: locationData.time,
                clinicId: locationData.clinicId,
                doctorUid: doctorUid
            }
        }
        if (locationData.type === 'ONLINE') {
            body = {
                ...body,
                appointmentType: locationData.type,
                appointmentStart: locationData.time,
                doctorUid: doctorUid
            }
        } else if (locationData.type === 'SERVICE') {
            body = {
                ...body,
                doctorUid,
                servicesAppointmentId: locationData.servicesAppointmentId
            }
        } else if (locationData.type === 'CHARGE') {
            body = { ...body, amount: locationData.price * 100 }
        }

        chargeCredit(body)
    }

    const handlePayWithNewCard = () => {
        setCurrentSelectedType('CARD')
        callChargeCredit('CARD')
    }

    const handleCloseModal = () => history.push('/')

    const resetData = () => {
        setPaymentData({ price: null, fees: null, total: null })

        setPhoneNumber(null)
        setSelectedCard(null)
    }
    const resetFeesAndTotal = () => {
        setPaymentData({ fees: null, total: null })
    }

    // when get response after apply promoCode
    useEffect(() => {
        if (addPromoData) {
            setShowAddPromoModal(false)
            setShowConfirmationModal(true)
            setPromo('')
            // getPromoCodesData()
        }
    }, [addPromoData])

    // to catch errors after apply promoCode
    useEffect(() => {
        if (addPromoError) {
            setPromoError('بروموكود  خاطىء')
        }
    }, [addPromoError])

    // to reset promo errors when try to apply wrong promoCode and ask to type again
    useEffect(() => {
        setPromoError('')
    }, [promo])

    // calculate fees for selected payment when change selected payment method
    useEffect(() => {
        if (currentSelectedType) {
            let path = `v2/patient/doctor/paymentFees/${doctorUid}/${locationData.type}`
            let body = { patientUid: patientUid, appId: appId, paymentMethod: currentSelectedType }

            if (locationData.type === 'CLINIC') {
                body = { ...body, clinicId: locationData.clinicId }
            } else if (locationData.type === 'CHARGE' || locationData.type === 'SERVICE') {
                path = 'v2/patient/payment/calculateFees'
                body = { paymentMethod: currentSelectedType, amount: locationData.price * 100 }
            }

            calculateFeesExecute('patch', path, body)
        }
    }, [calculateFeesExecute, currentSelectedType, locationData, patientUid])

    // when get response from calculating fees for selected payment method
    useEffect(() => {
        if (calculateFeesData) {
            let total = null
            let price = null
            let discount = null
            total = calculateFeesData.data.total / 100

            if (locationData.type === 'SERVICE' || locationData.type === 'CHARGE') {
                price = locationData.price
            } else {
                price = calculateFeesData.data.originalPrice / 100
                discount = (calculateFeesData.data.originalPrice - calculateFeesData.data.amount) / 100
            }
            setPaymentData({
                price: price,
                fees: (calculateFeesData.data.fees / 100).toString(),
                total: total.toFixed(2),
                discount
            })
        }
    }, [calculateFeesData, locationData.price, locationData.type])

    useEffect(() => {
        if (chargeData) {
            setShowPaymentModal(true)
        }
    }, [chargeData])

    // ================errors================

    // to catch errors when calculate fees for selected payment method
    useEffect(() => {
        if (calculateFeesError) {
            console.log('calculateFeesError: ', calculateFeesError)
        }
    }, [calculateFeesError])

    useEffect(() => {
        if (chargeError) {
            console.log('chargeError========: ', chargeError)
            // TODO: Handle errors
            if (chargeError.code === 'already-paid') {
                alert('Already Paid...')
            }
            if (chargeError.code === 'not-enough-balance') {
                alert('not-enough-balance')
            }
            if (chargeError.code === 'already-booked') {
                alert('already booked')
            }
        }
    }, [chargeError])

    if (addPromoLoading || savedCardsLoading) {
        return <Loading />
    }
    return (
        <div className={styles['payment-confirm-container']}>
            <SubHeader title={`تأكيد دفع ${paymentType[location.state.type]}`} />

            <div className={styles['payment-content']}>
                <div className={styles['data-container']}>
                    {locationData && <InfoCard type='appointment' data={locationData} loading={calculateFeesLoading} />}
                    <InfoCard
                        type='payment'
                        data={paymentData}
                        setShowModal={(value) => setShowAddPromoModal(value)}
                        paymentType={location.state.type}
                        loading={calculateFeesLoading}
                    />
                </div>
                <Image loading='lazy' src='/assets/payment/imgs/appointment.png' className={styles['image']} />
            </div>

            <P type='title'>اختار طريقة الدفع</P>

            <PaymentMethod
                title='بطاقات ائتمان'
                type='TOKEN'
                togglePayment={togglePayment}
                handleTogglePayment={(value) => handleTogglePayment(value)}
                savedCards={savedCardsData && savedCardsData.results}
                disabled={!selectedCard}
                selectedCard={selectedCard}
                setSelectedCard={(value) => setSelectedCard(value)}
                handleSubmitPayment={callChargeCredit}
                handlePayWithNewCard={handlePayWithNewCard}
                loading={chargeLoading}
            />
            <PaymentMethod
                title='محفظة الكترونية'
                type='WALLET'
                togglePayment={togglePayment}
                handleTogglePayment={(value) => handleTogglePayment(value)}
                disabled={!phoneNumber}
                phoneNumber={phoneNumber}
                setPhoneNumber={(e) => setPhoneNumber(e.target.value)}
                handleSubmitPayment={callChargeCredit}
                phoneError={phoneError}
                loading={chargeLoading}
            />
            <PaymentMethod
                title='مصاري او أمان'
                type='AGGREGATOR'
                togglePayment={togglePayment}
                handleTogglePayment={(value) => handleTogglePayment(value)}
                handleSubmitPayment={callChargeCredit}
                loading={chargeLoading}
            />
            {locationData.type === 'SERVICE' && locationData.price > 500 && (
                <PaymentMethod
                    title='فاليو'
                    type='VAL_U'
                    togglePayment={togglePayment}
                    handleTogglePayment={(value) => handleTogglePayment(value)}
                    handleSubmitPayment={callChargeCredit}
                    loading={chargeLoading}
                />
            )}
            {(locationData.type === 'CLINIC' || locationData.type === 'ONLINE') && (
                <PaymentMethod
                    title='محفظتي'
                    type='CASH_BOX'
                    togglePayment={togglePayment}
                    handleTogglePayment={(value) => handleTogglePayment(value)}
                    handleSubmitPayment={callChargeCredit}
                    loading={chargeLoading}
                />
            )}
            {locationData.type === 'CLINIC' && (
                <PaymentMethod
                    title='كاش عند الوصول'
                    type='ONSITE'
                    togglePayment={togglePayment}
                    handleTogglePayment={(value) => handleTogglePayment(value)}
                    handleSubmitPayment={callChargeCredit}
                    loading={chargeLoading}
                />
            )}

            {showAddPromoModal && (
                <ModalView
                    show={showAddPromoModal}
                    setShow={(value) => setShowAddPromoModal(value)}
                    type='input'
                    title='استخدام بروموكود'
                    description='ادخل البروموكود المراد استخدامه لتحصل علي الخصم'
                    input={{
                        title: 'بروموكود',
                        value: promo,
                        setValue: (value) => setPromo(value),
                        error: promoError
                    }}
                    btn={{ title: 'استخدام', action: () => handleAddPromo() }}
                />
            )}

            {showConfirmationModal && (
                <ModalView
                    show={showConfirmationModal}
                    setShow={(value) => setShowConfirmationModal(value)}
                    type='success'
                    title='مبروك'
                    description='تم اضافة البروموكود بنجاح'
                    btn={{ title: 'اغلاق', action: () => setShowConfirmationModal(false) }}
                />
            )}

            {showPaymentModal && (
                <ModalView
                    show={showPaymentModal}
                    setShow={handleCloseModal}
                    type={modalType[currentSelectedType]}
                    title='اتمام عملية الدفع'
                    customView={
                        <RenderResult
                            rechargeCreditData={chargeData}
                            selectedMethod={currentSelectedType}
                            rechargeCreditLoading={chargeLoading}
                            setShowConfirmationModal={setShowConfirmationModal}
                        />

                        // <div
                        //     className={styles['recharge-container']}
                        //     style={{
                        //         textAlign: 'right',
                        //         marginTop: '1rem',
                        //         display: 'flex',
                        //         alignItems: 'center',
                        //         justifyContent: 'center',
                        //         height: '50vh'
                        //     }}>
                        //     <Image src='/assets/payment/icons/confirmation.png' alt='congratulation' />
                        //     <RenderResult
                        //         rechargeCreditData={chargeData}
                        //         selectedMethod={currentSelectedType}
                        //         rechargeCreditLoading={chargeLoading}
                        //     />
                        //     <GradientBtn title='back to home' type='gradient' action={backHome} />
                        // </div>
                    }
                />
            )}
        </div>
    )
}

export default PaymentConfirm

const RenderResult = ({ rechargeCreditData, selectedMethod, rechargeCreditLoading, setShowConfirmationModal }) => {
    console.log('rechargeCreditLoading: ', rechargeCreditLoading)
    console.log('rechargeCreditData: ', rechargeCreditData)

    if (rechargeCreditLoading) {
        return <P type='title'>Loading...</P>
    } else if (selectedMethod === 'CARD') {
        return (
            <iframe
                className={styles.iframe}
                src={rechargeCreditData && rechargeCreditData.data.url}
                // src='/assets/payment/icons/confirmation.png'

                sandbox='allow-same-origin allow-scripts allow-forms allow-modals allow-popups allow-top-navigation'
                width='100%'
                height='875px'
            />
        )
    } else if (selectedMethod === 'AGGREGATOR' && rechargeCreditData && rechargeCreditData.data.bill_reference) {
        return (
            <div style={{ textAlign: 'center' }}>
                <p type='title' other='centered'>
                    اذهب الى اى مكان به مصارى او امان و اطلب دفع "مدفوعات متنوعه اكسبت" ثم ادفع بكود
                </p>
                <p type='h3' other='code' style={{ fontWeight: 700, fontSize: '1.5rem' }}>
                    {rechargeCreditData.data.bill_reference}
                </p>
                {/* <GradientBtn title='الرجوع للرئيسيه' type='gradient' action={backHome} /> */}
            </div>
        )
    } else if (selectedMethod === 'TOKEN') {
        return (
            <iframe
                className={styles.iframe}
                src={rechargeCreditData && rechargeCreditData.data.redirection_url}
                sandbox='allow-same-origin allow-scripts allow-forms allow-modals allow-popups allow-top-navigation'
                width='100%'
                height='700px'
            />
        )
    } else if (selectedMethod === 'WALLET') {
        return (
            <iframe
                className={styles.iframe}
                src={rechargeCreditData && rechargeCreditData.data.iframe_redirection_url}
                sandbox='allow-same-origin allow-scripts allow-forms allow-modals allow-popups allow-top-navigation'
                width='100%'
                height='700px'
            />
        )
    } else if (selectedMethod === 'VAL_U') {
        return (
            <iframe
                className={styles.iframe}
                src={rechargeCreditData && rechargeCreditData.data.url}
                sandbox='allow-same-origin allow-scripts allow-forms allow-modals allow-popups allow-top-navigation'
                width='100%'
                height='700px'
            />
        )
    } else if (selectedMethod === 'CASH_BOX') {
        return (
            <div style={{ textAlign: 'center' }}>
                <Image src='/assets/payment/icons/confirmation.png' alt='congratulation' style={{ width: '100%' }} />
                <p style={{ marginTop: '2rem', fontSize: '1.25rem' }}>مبروك تم الحجز بنجاح</p>
                {/* <GradientBtn title='الرجوع للرئيسيه' type='gradient' action={backHome} /> */}
            </div>
        )
    } else if (selectedMethod === 'ONSITE') {
        return (
            <div style={{ textAlign: 'center' }}>
                <Image src='/assets/payment/icons/confirmation.png' alt='congratulation' style={{ width: '100%' }} />
                <p style={{ marginTop: '2rem', fontSize: '1.25rem' }}>مبروك تم الحجز بنجاح و الدفع هيبقى كاش فى العيادة</p>
                {/* <GradientBtn title='الرجوع للرئيسيه' type='gradient' action={backHome} /> */}
            </div>
        )
    }
    return ''
}

function validatePhone(phoneNumber) {
    const phoneReg = /^0(11|12|10|15)[0-9]{8}$/

    if (!phoneNumber || !phoneReg.test(phoneNumber)) {
        return 'Invalid Phone Number'
    } else {
        return ''
    }
}
