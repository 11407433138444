import React from 'react'
import styles from './FAQ.module.scss'
import { Accordion, Card, Image } from 'react-bootstrap'
import { P } from '@curawella/curawella-components'

const questions = [
    {
        question: 'كيفية حجز موعد مع طبيب ؟',
        answerWeb: [
            'لحجز موعد مع الطبيب اضغط على "احجز موعد" في الصفحة الرئيسية ثم اختر العيادة المناسبة لك واضغط على "احجز الان" ثم اختر التاريخ والموعد المناسبين لك ثم اضغط " تأكيد الحجز',
            ' في حال انك لم تقم بتسجيل الدخول ستظهر شاشة التسجيل ',
            ' بعد الانتهاء من تسجيل دخولك قم باختيار وسيلة الدفع المناسبة لإتمام الحجز '
        ],
        answerApp: [
            ' لحجز موعد مع الطبيب اضغط على "احجز موعد" في الصفحة الرئيسية ثم اختر العيادة المناسبة لك واضغط على "التالي" ثم حدد التاريخ والموعد  المناسبين لك ثم اضغط "التالي" ثم حدد طريقه الدفع ثم اضغط "التالي" ثم راجع معلومات الحجز وقم بتأكيده'
        ]
    },
    {
        question: 'ما هي طرق الدفع المتاحه؟',
        answerWeb: [
            ' يمكنك الدفع عن طريق المحفظة الإلكترونية من اتصالات او فودافون او اورنج او عن طريق مصاري او امان او محفظتي او كاش عند الوصول '
        ],
        answerApp: [
            ' يمكنك الدفع عن طريق المحفظة الإلكترونية من اتصالات او فودافون او اورنج او عن طريق مصاري او امان او محفظتي او كاش عند الوصول '
        ]
    },
    {
        question: 'كيفيه استخدام البرومو كود؟',
        answerWeb: [
            'لاستخدام البرومو كود  اختر "محفظتي" في القائمة اعلى الصفحة ثم ادخل الكود واضغط "استخدام',
            'او عند حجز موعد مع الطبيب في صفحه تأكيد الحجز اضغط على زر "استخدام بروموكود" ثم ادخل الكود ثم اضغط "استخدام'
        ],

        answerApp: [
            'لاستخدام البرومو كود  اختر  "برومو كود" في الصفحة الرئيسية ثم ادخل الكود واضغط "استخدام',
            'او عند حجز موعد مع الطبيب في صفحه الدفع ادخل الكود ثم اضغط "استخدام'
        ]
    },
    {
        question: 'كيف اشحن رصيدي؟',
        answerWeb: ['لشحن الرصيد اختر "محفظتي" من القائمة اعلى الصفحة ثم اكتب المبلغ واضغط "اشحن" ثم اختر طريقه الدفع'],
        answerApp: ['لشحن الرصيد اختر "محفظتي" ثم اضغط على "اشحن" ثم ادخل المبلغ واضغط "اشحن" ثم اختر طريقه الدفع']
    },
    {
        question: 'كيفيه اضافه / حذف بطاقه الائتمان؟',
        answerWeb: [
            ':لإضافة بطاقه الائتمان',
            'ذهب الى "الملف الشخصي" ثم "بطاقات الائتمان المسجلة" اسفل الصفحة ثم اضغط على "اضافه بطاقه جديده" ثم ادخل بيانات البطاقة',
            ':لحذف بطاقه الائتمان',
            'اذهب الى "الملف الشخصي" ثم "بطاقات الائتمان المسجلة" اسفل الصفحة ثم اضغط على سله المهملات بجانب البطاقة المراد حذفها'
        ],
        answerApp: [
            ':لإضافة بطاقه الائتمان',
            'ذهب الى "محفظتي" ثم "بطاقات الائتمان المسجلة" ثم اضغط على "اضافه بطاقه جديده" ثم ادخل بيانات البطاقة',
            ':لحذف بطاقه الائتمان',
            'اذهب الى "محفظتي" ثم "بطاقات الائتمان المسجلة" ثم اضغط على سله المهملات بجانب البطاقة المراد حذفها'
        ]
    },
    {
        question: 'كيف اغير كلمه المرور الخاصة بي؟',
        answerWeb: [
            'لتغيير كلمه المرور الخاصة بك اذهب الى ملفك الشخصي ومنه اضغط على تغيير كلمه المرور" ثم ادخل كلمه المرور القديمة والجديدة تم اضغط "ارسال"'
        ],

        answerApp: [
            'لتغيير كلمه المرور الخاصة بك اذهب الى ملفك الشخصي ومنه اضغط على حسابي" ثم اضغط على "تعديل" بجانب معلومات المستخدم ثم اختر "تغيير" كلمه المرور" ثم ادخل كلمه المرور القديمة والجديدة تم اضغط "ارسال'
        ]
    },
    {
        question: 'كيف اغير موعد قمت بحجزه سابقا؟',
        answerWeb: [
            'لتغيير موعد قمت بحجزه اختر "المواعيد" من القائمة اعلى الصفحة تم اضغط على "اعاده جدوله" للميعاد الذى تريد تغييره ثم اختر الميعاد الجديد من المواعيد المتاحة'
        ],
        answerApp: [
            'لتغيير موعد قمت بحجزه اختر "المواعيد" من الصفحة الرئيسية تم اضغط على "اعاده جدوله" للميعاد الذى تريد تغييره ثم اختر الميعاد الجديد من المواعيد المتاحة'
        ]
    },
    {
        question: 'كيف الغى موعد قمت بحجزه سابقا؟',
        answerWeb: ['الإلغاء موعد قمت بحجزه  اختر "المواعيد" من القائمة اعلى الصفحة تم اضغط على "الغاء الموعد" للميعاد الذى تريد الغاءه'],
        answerApp: ['للإلغاء موعد قمت بحجزه  اختر "المواعيد" من الصفحة الرئيسية تم اضغط على "الغاء الموعد" للميعاد الذى تريد الغاءه']
    },
    {
        question: 'كيف اضيف ملفات اشعه وتحاليل الى سجلي الطبي؟',
        answerWeb: ['لإضافة ملفات اشعه وتحاليل الى سجلك الطبي اختر "السجل الطبي" من القائمة اعلى الصفحة ثم اضفط "رفغ الملف " '],

        answerApp: [
            'لإضافة ملفات اشعه وتحاليل الى سجلك الطبي اختر "السجل الطبي" من الصفحه الرئيسيه ثم اختر السجل الصحيح ثم اضفط "رفغ الملف " '
        ]
    }
]
const FAQ = () => {
    return (
        <div className={styles['FAQ-content']}>
            <Image src='/assets/contact-us/imgs/FAQ.png' alt='FAQ-img' className={styles['faq-img']} loading='lazy' />
            <div className={styles['accordion-container']}>
                <P type='title' other='title'>
                    بعض الأسئلة الشائعة التي نتلقاها. اذا لم تجد إجابة لإستفسارك تواصل معنا
                </P>
                <Accordion className={styles['accordion']}>
                    {questions.map((question, index) => (
                        <Card className={styles['card-container']} key={index}>
                            <Accordion.Toggle as={Card.Header} eventKey={index + 1} className={styles['card-header']}>
                                <p type='p1'>{question.question}</p>
                                <div className={styles['faq-img-container']}>
                                    <Image
                                        src='/assets/contact-us/icons/arrow.svg'
                                        alt='FAQ-img'
                                        className={styles['faq-img']}
                                        loading='lazy'
                                    />
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={index + 1} className={styles['card-content']}>
                                <Card.Body>
                                    <Answer web={question.answerWeb} app={question.answerApp} />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                </Accordion>
            </div>
        </div>
    )
}

export default FAQ

const Answer = ({ web, app }) => {
    return (
        <div className={styles['answer-container']}>
            <div className={styles['answer-content']}>
                {/* <P type='p2' other='view'>
                    الموقع
                </P> */}
                {web.map((answer, index) => (
                    <P type='p3' other='content'>
                        {answer}
                    </P>
                ))}
            </div>
            {/* <div className={styles['answer-content']}>
                <P type='p2' other='view'>
                    التطبيق
                </P>
                {app.map((answer, index) => (
                    <P key={index} type='p3' other='content'>
                        {answer}
                    </P>
                ))}
            </div> */}
        </div>
    )
}
