import React, { useCallback, useContext, useEffect, useState } from 'react'
import { H4, SubHeader } from '@curawella/curawella-components'
import useFetch from '../../hooks/useFetch'

import PropTypes from 'prop-types'
import ListOfAppointments from './ListOfAppointments'
import Loading from '../../components/loading/Loading'
import firebaseApp from '../../constants/firebaseApp'

import styles from './Appointments.module.scss'

const Appointments = ({ custom, Context }) => {
    Appointments.propTypes = {
        custom: PropTypes.bool
    }
    const [period, setPeriod] = useState('next')
    const [UpcData, setUpcData] = useState([])
    const [HisData, setHisData] = useState([])

    const [getAppointmentsData, getAppointmentsLoading, getAppointmentsError, getAppointmentsExecute] = useFetch()

    const { patientUid, doctor } = useContext(Context)

    useEffect(() => {
        const analytics = firebaseApp.analytics()

        analytics.screenView(analytics.screens.appointments)
    }, [])

    const getAppointments = useCallback(() => {
        getAppointmentsExecute('get', `v2/patient/appointments/${patientUid}?doctorUid=${doctor.uid}`)
    }, [doctor.uid, getAppointmentsExecute, patientUid])

    useEffect(() => {
        getAppointments()
    }, [getAppointments])

    useEffect(() => {
        if (getAppointmentsData) {
            setUpcData(getAppointmentsData.data.upcoming)
            setHisData(getAppointmentsData.data.history)
        }
    }, [getAppointmentsData])

    useEffect(() => {
        if (getAppointmentsError) {
            console.log('error while get AppointmentList', getAppointmentsError)
        }
    }, [getAppointmentsError])

    if (getAppointmentsLoading) {
        return <Loading />
    }

    return (
        <div className={styles['appointments-container']}>
            <SubHeader title='المواعيد' />

            <div className={styles['appointments-content']}>
                <div className={styles['next-prev-container']}>
                    <H4 handleClick={() => setPeriod('next')} other={`${period === 'next' ? 'active' : ''}`}>
                        القادمة
                    </H4>
                    <H4 handleClick={() => setPeriod('prev')} other={`${period === 'prev' ? 'active' : ''}`}>
                        السابقة
                    </H4>
                </div>
                {period === 'next' && (
                    <>
                        <ListOfAppointments
                            title='العمليات'
                            icon='/assets/appointment/icons/surgeries.svg'
                            data={UpcData.upcomingServices}
                            period={period}
                            type='service'
                            custom={custom}
                            noDataTitle='لا يوجد عمليات قادمة'
                            loading={getAppointmentsLoading}
                        />
                        <ListOfAppointments
                            title='المواعيد'
                            icon='/assets/appointment/icons/calendar.svg'
                            data={UpcData.upcomingAppointments}
                            period={period}
                            type='appointment'
                            custom={custom}
                            noDataTitle='لا يوجد مواعيد قادمة'
                            loading={getAppointmentsLoading}
                            getAppointments={getAppointments}
                        />
                    </>
                )}
                {period === 'prev' && (
                    <ListOfAppointments
                        data={HisData}
                        period={period}
                        type='history'
                        custom={custom}
                        noDataTitle='لا يوجد مواعيد سابقة '
                        loading={getAppointmentsLoading}
                    />
                )}
            </div>
        </div>
    )
}

export default Appointments
