import React, { useContext, useEffect, useState } from 'react'
import styles from './ContactUs.module.scss'
import { Image } from 'react-bootstrap'
import FAQ from './faq/FAQ'
import { appId } from '../../constants/AppConstants'
import { ModalView, P, SubHeader } from '@curawella/curawella-components'
import ContactUsForm from './contactus-form/ContactUsForm'
import useFetch from '../../hooks/useFetch'
import firebaseApp from '../../constants/firebaseApp'

const ContactUs = ({ Context }) => {
    const [data, setData] = useState({ name: null, phone: null, subject: null, body: null })
    const [errors, setErrors] = useState({ name: '', phone: '', subject: '', body: '' })
    const [contactUsData, contactUsLoading, contactUsError, contactUsExecute] = useFetch()
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const { patientUid } = useContext(Context)

    const submitForm = () => {
        const { subject, body } = data
        const { isValid, errors: errs } = validateData(data, errors, !patientUid)

        if (patientUid) {
            setErrors(errs)

            if (!isValid) {
                return
            }

            contactUsExecute('post', 'v2/user/contactus', {
                uid: patientUid,
                appId,
                subject,
                body
            })
        } else {
            const { name, phone } = data

            setErrors(errs)

            if (!isValid) {
                return
            }

            contactUsExecute('post', 'v2/user/contactus/public', {
                name,
                phone,
                appId,
                subject,
                body
            })
        }
    }

    useEffect(() => {
        if (contactUsData && contactUsData.success) {
            setData({ name: null, phone: null, subject: null, body: null })
            setShowSuccessModal(true)
        }
    }, [contactUsData])

    useEffect(() => {
        if (contactUsError) {
            console.log('contactUsError: ', contactUsError)
        }
    }, [contactUsError])

    useEffect(() => {
        const analytics = firebaseApp.analytics()
        analytics.screenView(analytics.screens.ContactUs)
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={styles['contact-us-container']}>
            <SubHeader title='تواصل معنا' />
            <div className={styles['contact-us-content']}>
                <FAQ />
                <P type='title' other='title'>
                    أرسل لنا رسالة بخصوص المشكلة التي تواجهك وسنقوم بالرد عليك قريباً
                </P>
                <ContactUsForm
                    data={data}
                    setData={setData}
                    onSubmit={submitForm}
                    errors={errors}
                    setErrors={setErrors}
                    loading={contactUsLoading}
                    isAuthenticated={patientUid ? true : false}
                />
            </div>
            <div className={styles['contact-us-items-container']}>
                <div className={styles['contact-us-item']}>
                    <div className={styles['icon-container']}>
                        <Image src='/assets/contact-us/icons/phone.svg' alt='phone-icon' loading='lazy' />
                    </div>
                    <div className={styles['data-container']}>
                        <P type='title' other='title'>
                            رقم الهاتف
                        </P>
                        <P type='title' other='data'>
                            01024465585
                        </P>
                    </div>
                </div>
                <div className={styles['contact-us-item']}>
                    <div className={styles['icon-container']}>
                        <Image src='/assets/contact-us/icons/mail.svg' alt='phone-icon' loading='lazy' />
                    </div>
                    <div className={styles['data-container']}>
                        <P type='title' other='title'>
                            البريد الإلكتروني
                        </P>
                        <P type='title' other='data'>
                            support@curawella.com
                        </P>
                    </div>
                </div>
            </div>
            {showSuccessModal === true ? (
                <ModalView
                    show={showSuccessModal}
                    setShow={(value) => setShowSuccessModal(value)}
                    type='success'
                    title='تأكيد'
                    description='تم تنفيذ طلبك بنجاح'
                    btn={{ title: 'اغلاق', action: () => setShowSuccessModal(false) }}
                />
            ) : (
                ''
            )}
        </div>
    )
}

export default ContactUs

function validateData(data, errors, isPublic) {
    const { name, phone, subject, body } = data
    let isValid = true
    const newErrors = { name: '', phone: '', subject: '', body: '' }

    if (isPublic) {
        if (!name || name.trim() === '') {
            newErrors.name = 'مطلوب'
            isValid = false
        }

        if (!phone || phone.trim() === '') {
            newErrors.phone = 'رقم هاتف غير صحيح'
            isValid = false
        }
    }

    if (!subject || subject.trim() === '') {
        newErrors.subject = 'مطلوب'
        isValid = false
    }

    if (!body || body.trim() === '') {
        newErrors.body = 'مطلوب'
        isValid = false
    }

    for (const key in errors) {
        if (Object.hasOwnProperty.call(errors, key)) {
            if (errors[key] !== '') {
                isValid = false
            }
        }
    }

    return { isValid, errors: newErrors }
}
