import React from 'react'
import { H4, P } from '@curawella/curawella-components'
import { Image } from 'react-bootstrap'
import StarRatings from 'react-star-ratings'
import PropTypes from 'prop-types'

import { getDrLevelAr } from '../../../constants/AppConstants'

import styles from './DoctorInfoCard.module.scss'

const DoctorInfoCard = ({ doctor }) => {
    DoctorInfoCard.propTypes = {
        doctor: PropTypes.object
    }

    return (
        <div className={styles['doctor-info-card-container']}>
            <div className={styles['avatar-div']}>
                <Image
                    loading='lazy'
                    alt='level'
                    src={doctor.avatar}
                    className={styles['doctor-info-card-avatar']}
                />
            </div>

            <H4>{`دكتور ${doctor.firstNameAr} ${doctor.lastNameAr}`}</H4>
            <P type='p2'>
                <span style={{ direction: 'ltr' }}>
                    <StarRatings
                        starRatedColor='#FFD500'
                        rating={doctor.rate}
                        starDimension='21px'
                        starSpacing='0'
                    />
                </span>
                ({doctor.rate})
            </P>
            <P type='p1'>
                <Image
                    loading='lazy'
                    alt='level'
                    src='/assets/doctor-profile/icons/certificate.svg'
                    className={styles['doctor-info-card-icon']}
                />
                <span>الدرجة العلمية: </span>
                {getDrLevelAr[doctor.level]}
            </P>
            <P type='p1'>
                <Image
                    loading='lazy'
                    alt='level'
                    src='/assets/doctor-profile/icons/specialization.svg'
                    className={styles['doctor-info-card-icon']}
                />
                <span>التخصص : </span>
                {doctor.specialtyNameAr}
            </P>
            <RenderLinks doctor={doctor} />

            <P type='p1' other='description'>
                {doctor.aboutSummary}
            </P>
        </div>
    )
}

export default DoctorInfoCard

const RenderLinks = ({ doctor }) => {
    const arr = ['playStoreLink', 'appStoreLink', 'facebookLink', 'websiteLink']
    const images = {
        playStoreLink: '/assets/doctor-profile/icons/googlePlay.svg',
        appStoreLink: '/assets/doctor-profile/icons/appStore.svg',
        facebookLink: '/assets/doctor-profile/icons/facebook.svg',
        websiteLink: '/assets/doctor-profile/icons/web-link.png'
    }

    const result = []

    for (const key in doctor) {
        const link = doctor[key]

        if (arr.includes(key)) {
            result.push({ link: link, img: images[key] })
        }
    }

    return (
        <div className={styles['links-container']}>
            {result.map(
                (item, index) =>
                    item.link && (
                        <a href={item.link} key={index}>
                            <Image alt='google-icon' src={item.img} />
                        </a>
                    )
            )}
        </div>
    )
}
