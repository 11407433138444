import React from 'react'
import PropTypes from 'prop-types'
import styles from './NotificationCard.module.scss'
import { Image } from 'react-bootstrap'
import { P } from '@curawella/curawella-components'
import { getDate, getTime } from '../../../helpers/dateTime'

const NOTIFICATIONS_TYPES = {
    REMINDER: 'REMINDER',
    APPOINTMENT_BOOKING: 'APPOINTMENT_BOOKING',
    APPOINTMENT_CANCELED: 'APPOINTMENT_CANCELED',
    SERVICE_ASSIGNED: 'SERVICE_ASSIGNED',
    SERVICE_CANCELED: 'SERVICE_CANCELED',
    PROMOCODE_APPLIED: 'PROMOCODE_APPLIED',
    BLOG_ADDED: 'BLOG_ADDED',
    MESSAGE: 'MESSAGE'
}

const NOTIFICATIONS_TITLE_TYPES = {
    REMINDER: 'لديك موعد',
    APPOINTMENT_BOOKING: 'تم حجز موعد',
    APPOINTMENT_CANCELED: 'تم إلغاء موعد',
    SERVICE_ASSIGNED: 'تم حجز عملية',
    SERVICE_CANCELED: 'تم إلغاء عملية',
    PROMOCODE_APPLIED: 'تم تطبيق بروموكود',
    BLOG_ADDED: 'تم إضافة مقال',
    MESSAGE: 'رسالة جديدة'
}

const NotificationCard = ({ type, description, time }) => {
    return (
        <div className={styles['notification-card-container']}>
            <RenderIcon type={type} />
            <div className={styles['data']}>
                <P type='p1' other='title'>
                    {NOTIFICATIONS_TITLE_TYPES[type]}
                </P>
                <P type='p2' other='description'>
                    {description}
                </P>
                <P type='p2' other='time'>
                    {`${getDate(time)} الساعة ${getTime(time)}`}
                </P>
            </div>
        </div>
    )
}

export default NotificationCard

const RenderIcon = ({ type }) => {
    switch (type) {
        case NOTIFICATIONS_TYPES.APPOINTMENT_BOOKING:
        case NOTIFICATIONS_TYPES.REMINDER:
        case NOTIFICATIONS_TYPES.SERVICE_ASSIGNED:
            return (
                <Image
                    src='/assets/notifcation/icons/notification.svg'
                    alt='notify'
                    className={`${styles['icon']} ${styles['notify']}`}
                    loading='lazy'
                />
            )
        case NOTIFICATIONS_TYPES.MESSAGE:
            return (
                <Image
                    src='/assets/notifcation/icons/chat.svg'
                    alt='notify'
                    className={`${styles['icon']} ${styles['chat']}`}
                    loading='lazy'
                />
            )
        case NOTIFICATIONS_TYPES.APPOINTMENT_CANCELED:
        case NOTIFICATIONS_TYPES.SERVICE_CANCELED:
            return (
                <Image
                    src='/assets/notifcation/icons/cancel.svg'
                    alt='notify'
                    className={`${styles['icon']} ${styles['cancel']}`}
                    loading='lazy'
                />
            )
        case NOTIFICATIONS_TYPES.BLOG_ADDED:
        case NOTIFICATIONS_TYPES.PROMOCODE_APPLIED:
            return (
                <Image
                    src='/assets/notifcation/icons/confirm.svg'
                    alt='notify'
                    className={`${styles['icon']} ${styles['confirm']}`}
                    loading='lazy'
                />
            )

        default:
            return (
                <Image
                    src='/assets/notifcation/icons/notification.svg'
                    alt='notify'
                    className={`${styles['icon']} ${styles['notify']}`}
                    loading='lazy'
                />
            )
    }
}
NotificationCard.propTypes = {
    type: PropTypes.oneOf(Object.values(NOTIFICATIONS_TYPES)),
    description: PropTypes.string,
    time: PropTypes.string
}
