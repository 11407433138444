import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styles from './Footer.module.scss'
import { Image } from 'react-bootstrap'
import { Brand } from '@curawella/curawella-components'
import { P } from '@curawella/curawella-components'
import NavLinks from '../nav-links/NavLinks'
import firebaseApp from '../../constants/firebaseApp'

const images = {
    playStoreLink: '/assets/footer/logos/googlePlay.svg',
    appStoreLink: '/assets/footer/logos/appStore.svg',
    facebookLink: '/assets/footer/logos/facebook.svg',
    websiteLink: '/assets/footer/logos/web-link.png'
}
const customLinks = [
    { title: 'عن الدكتور', link: '/profile' },
    { title: 'محفظتي', link: '/wallet' },
    { title: 'المواعيد', link: '/appointments' },
    { title: 'السجل الطبي', link: '/medical-records' },
    { title: 'اتصل بنا', link: '/contact-us' },
    { title: 'الخصوصية', link: '/privacy' }
]
const notAuthLinks = [
    { title: 'عن الدكتور', link: '/profile' },
    { title: 'المقالات', link: '/blogs' },
    { title: 'اتصل بنا', link: '/contact-us' },
    { title: 'الخصوصية', link: '/privacy' }
]

const Footer = ({ custom, app, Context }) => {
    const currentLocation = window.location.pathname
    const { doctor, patient } = useContext(Context)
    const arr = ['playStoreLink', 'appStoreLink', 'facebookLink', 'websiteLink']

    const handleAnalytics = (type) => {
        const analytics = firebaseApp.analytics()
        if (type === 'vcliniq') {
            analytics.logEvent(analytics.events.Vcliniq)
        } else {
            analytics.logEvent(analytics.events.Curawella)
        }
    }

    const result = []
    for (const key in doctor) {
        const link = doctor[key]

        if (arr.includes(key)) {
            result.push({ link: link, img: images[key] })
        }
    }
    if (currentLocation === '/404') {
        return null
    }
    return (
        <div className={styles['footer-container']}>
            <Brand custom={custom} title={app && app.title} logo={app && app.logo} />
            <div className={styles['nav-links-container-footer']}>
                <NavLinks links={!patient ? notAuthLinks : customLinks} type='footer' />
            </div>
            <div className={styles['social-logos-container']}>
                {result.map(
                    (item, index) =>
                        item.link && (
                            <a href={item.link} key={index}>
                                <Image src={item.img} loading='lazy' className={styles['social-logo']} alt='social-icon' />
                            </a>
                        )
                )}
                {/* <a href='https://www.facebook.com/Curawella.Healthcare' target='_blanc'>
                    <Image src={fbLogo} loading='lazy' className={styles['social-logo']} alt='social-icon' />
                </a>
                <a href='https://www.linkedin.com/company/curawella' target='_blanc'>
                    <Image src={LinkedInLogo} loading='lazy' className={styles['social-logo']} alt='social-icon' />
                </a> */}
            </div>
            <hr className={styles['hr']} />

            <div className={styles.curawella}>
                <P type='p2'>{'جميع الحقوق محفوظة'}</P>
                <a href='https://curawella.com' target={'_blank'} onClick={() => handleAnalytics('curawella')}>
                    <img src='/assets/logos/curawella.png' alt='curawella.com' />
                </a>

                <a href='https://vcliniq.app' target={'_blank'} onClick={() => handleAnalytics('vcliniq')}>
                    <img src='/assets/logos/VCWide.png' alt='vcliniq.app' />
                </a>
            </div>
        </div>
    )
}

export default Footer

Footer.propTypes = {
    custom: PropTypes.bool,
    app: PropTypes.object
}
