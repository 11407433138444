import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Footer, Navbar } from '../lib'
import EmailChecker from '../lib/components/email-checker/EmailChecker'
import Loading from '../lib/components/loading/Loading'
import { Context } from '../module/context/Context'

type CommonRouterProps = {
    children: any
    initialPath: string
    isAuthenticated: boolean
    emailAvailable: boolean | undefined
    custom: boolean
    doctorName: string | undefined
    doctorLogo: string | undefined
    setEmailAvailable: any
}
/**
 *  @param {React.ReactElement} children
 *  @param {boolean} isAuthenticated = false
 *  @param {boolean} emailAvailable = false
 *  @param {boolean} custom = false
 *  @param {string} doctorName
 *  @param {string} doctorLogo
 *  @param {string} initialPath
 *  @param {any} setEmailAvailable
 *  @returns
 */
const CommonRouter = ({
    children,
    isAuthenticated = false,
    custom = false,
    doctorName,
    doctorLogo,
    initialPath,
    emailAvailable = false,
    setEmailAvailable = () => {
        return
    }
}: CommonRouterProps) => {
    return (
        <Suspense fallback={<Loading />}>
            <div className='app-container'>
                <Navbar isAuthenticated={isAuthenticated} custom={custom} app={{ title: doctorName, logo: doctorLogo }} />
                <Switch>
                    {children}
                    <Route component={() => <Redirect to={'/'} />} />
                    <Redirect to={initialPath} />
                </Switch>
                {emailAvailable && <EmailChecker setEmailAvailable={setEmailAvailable} nextAction={undefined} />}
                <Footer custom={true} app={{ title: doctorName, logo: doctorLogo }} Context={Context} />
            </div>
        </Suspense>
    )
}

export default CommonRouter
