import React, { useCallback, useContext, useEffect, useState } from 'react'
import styles from './Wallet.module.scss'
import { P, SubHeader, ModalView } from '@curawella/curawella-components'
import PastTransactions from './past-transactions/PastTransactions'
import WalletAction from './wallet-action/WalletAction'
import WalletHero from './wallet-hero/WalletHero'
import { doctorUid } from '../../constants/AppConstants'
import firebaseApp from '../../constants/firebaseApp'
import CurrentPromo from './current-promo/currentPromo'
import { useHistory } from 'react-router-dom'
import Loading from '../../components/loading/Loading'
import useFetch from '../../hooks/useFetch'
import EmailChecker from '../../components/email-checker/EmailChecker'

const Wallet = ({ Context }) => {
    const [promo, setPromo] = useState('')
    const [promoError, setPromoError] = useState('')
    const [credit, setCredit] = useState('')
    const [creditError, setCreditError] = useState('')
    const [showConfirmationModal, setShowConfirmationModal] = useState(null)
    const [cashboxData, cashboxLoading, cashboxError, cashboxExecute] = useFetch()
    const [promoCodesData, promoCodesLoading, promoCodesError, promoCodesExecute] = useFetch()
    const [addPromoData, addPromoLoading, addPromoError, addPromoExecute] = useFetch()
    const [emailAvailable, setEmailAvailable] = useState()

    const { patientUid, patient } = useContext(Context)
    const history = useHistory()
    const hasPromo = true

    useEffect(() => {
        const analytics = firebaseApp.analytics()

        analytics.screenView(analytics.screens.wallet)
    }, [])

    const getPromoCodesData = useCallback(() => {
        promoCodesExecute('get', `v2/patient/promocodes/${patientUid}/${doctorUid}`)
    }, [patientUid, promoCodesExecute])

    const addPromo = () => {
        addPromoExecute('post', `v2/patient/promocodes/${patientUid}/${doctorUid}`, { code: promo })
    }

    const navigateToPayment = () => {
        if (patientUid && patient.email === null) {
            setEmailAvailable(true)
        } else {
            history.push({
                pathname: '/paymentConfirm',
                state: {
                    price: parseFloat(credit),
                    type: 'CHARGE'
                }
            })
        }
    }

    useEffect(() => {
        cashboxExecute('get', `v2/patient/cashbox/${patientUid}`)
        getPromoCodesData()
    }, [cashboxExecute, getPromoCodesData, patientUid])

    // when get response after apply promoCode
    useEffect(() => {
        if (addPromoData) {
            setShowConfirmationModal(true)
            setPromo('')
            getPromoCodesData()
        }
    }, [addPromoData, getPromoCodesData])

    // to catch errors after apply promoCode
    useEffect(() => {
        if (addPromoError) {
            setPromoError('بروموكود  خاطىء')
        }
    }, [addPromoError])

    if (cashboxLoading || promoCodesLoading) {
        return <Loading />
    }

    return (
        <div className={styles['wallet-container']}>
            <SubHeader title='محفظتى' />

            <WalletHero balance={cashboxData && cashboxData.data.balance} />
            <div className={styles['actions-container']}>
                <div className={`${styles['action-item']} ${styles['charge-item-container']}`}>
                    <WalletAction
                        title={
                            <>
                                شحن الرصيد
                                <P type='p1' other='replace-promo'>
                                    إدخال مبلغ الشحن
                                </P>
                            </>
                        }
                        placeHolder='مبلغ الشحن'
                        btnTitle='اشحن'
                        value={credit}
                        setValue={(e) => {
                            const { value } = e.target
                            setCredit(value)
                            setCreditError(validateCredit(value))
                        }}
                        error={creditError}
                        btnAction={navigateToPayment}
                        btnDisable={!credit || creditError}
                    />
                </div>

                <div className={`${styles['action-item']} ${styles['promo-item-container']}`}>
                    {promoCodesData && <CurrentPromo promoCodesData={promoCodesData} />}
                    <WalletAction
                        title={enterPromo(hasPromo)}
                        placeHolder='بروموكود'
                        btnTitle='إستخدام'
                        value={promo}
                        setValue={(e) => {
                            const { value } = e.target
                            setPromo(value)
                            const ss = validatePromo(value)
                            setPromoError(ss)
                        }}
                        btnAction={addPromo}
                        error={promoError}
                        btnDisable={!promo || !!promoError || addPromoLoading}
                    />
                </div>
            </div>

            {cashboxData && cashboxData.data.transactions.length > 0 ? (
                <PastTransactions pastTransactionsData={cashboxData.data.transactions} />
            ) : (
                <P type='p1' other='date' className={styles['no-transactions']}>
                    لا يوجد عمليات سابقة
                </P>
            )}

            {showConfirmationModal && (
                <ModalView
                    show={showConfirmationModal}
                    setShow={(value) => setShowConfirmationModal(value)}
                    type='success'
                    title='مبروك'
                    description='تم إضافة البروموكود بنجاح'
                    btn={{ title: 'اغلاق', action: () => setShowConfirmationModal(false) }}
                />
            )}
            {emailAvailable && <EmailChecker setEmailAvailable={setEmailAvailable} nextAction={navigateToPayment} />}
        </div>
    )
}

export { Wallet }

const enterPromo = (hasPromo) =>
    hasPromo ? (
        <>
            إستبدال البروموكود
            <P type='p1' other='replace-promo'>
                إدخال بروموكود جديد بديلا للبروموكود الحالي
            </P>
        </>
    ) : (
        <>
            إدخال بروموكود
            <P type='p1' other='replace-promo'>
                إدخال بروموكود جديد
            </P>
        </>
    )

function validateCredit(value) {
    if (isNaN(value)) {
        return 'أدخل أرقام فقط'
    } else if (value < 20) {
        return 'أقل قيمة للشحن ٢٠ جنيه'
    } else if (value > 1000) {
        return 'أقصى قيمة للشحن ١٠٠٠ جنيه'
    } else {
        return ''
    }
}

function validatePromo(value) {
    if (!/^[(\u0621-\u064Aa-zA-Z]{3,45}$/.test(value)) {
        return 'غير صحيح'
    } else {
        return ''
    }
}
