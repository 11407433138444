import { useCallback, useState } from 'react'
import axios from 'axios'
import firebaseApp from '../constants/firebaseApp'
import { API } from '../constants/AppConstants'

function useFetch() {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(null)
    const [error, setError] = useState(null)

    const execute = useCallback(async (method, url, body) => {
        setLoading(true)
        setData(null)
        setError(null)

        const headers = { 'Content-Type': 'application/json', 'auth-token': '' }

        const fbUser = firebaseApp.auth().currentUser
        if (fbUser) {
            headers['auth-token'] = await fbUser.getIdToken()
        }

        axios({ url: `${API}/${url}`, method, data: body, headers })
            .then((res) => {
                setLoading(false)
                const { data: d } = res
                if (d) {
                    setData(d)
                } else {
                    setData(res)
                }
            })
            .catch((err) => {
                setLoading(false)
                if (err.response && err.response.data) {
                    setError(err.response.data)
                } else if (err.response) {
                    setError(err.response)
                } else {
                    setError(err)
                }
            })
    }, [])

    return [data, loading, error, execute]
}

export default useFetch
