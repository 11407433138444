import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ModalView, SubHeader } from '@curawella/curawella-components'

import DoctorInfoCard from './doctor-info-card/DoctorInfoCard'
import DoctorVideoCallCard from './doctor-video-call-card/DoctorVideoCallCard'
import DoctorClinicCard from './doctor-clinic-card/DoctorClinicCard'
import DoctorMessagesCard from './doctor-messages-card/DoctorMessagesCard'
import DoctorAbout from './doctor-about/DoctorAbout'
import DoctorServices from './doctor-service/DoctorServices'

import styles from './DoctorProfile.module.scss'
import Loading from '../../components/loading/Loading'
import BookCard from '../../components/book-card/BookCard'
import firebaseApp from '../../constants/firebaseApp'
import EmailChecker from '../../components/email-checker/EmailChecker'

const DoctorProfile = ({ Context }) => {
    const [showModal, setShowModal] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [appointmentType, setAppointmentType] = useState(null)
    const [selectedClinicId, setSelectedClinicId] = useState(null)
    const [emailAvailable, setEmailAvailable] = useState()

    // price for selected clinic or online
    const [currentPrice, setCurrentPrice] = useState(null)
    const { patientUid, doctor, patient } = useContext(Context)

    const history = useHistory()

    useEffect(() => {
        window.scrollTo(0, 0)

        const analytics = firebaseApp.analytics()
        analytics.screenView(analytics.screens.doctorProfile)
    }, [])

    const setModalData = (type, price, clinicId) => {
        setAppointmentType(type)
        setSelectedClinicId(clinicId)
        setCurrentPrice(price)
    }

    const handleShowModal = () => {
        // No user is signed in.
        if (!patientUid) {
            setShowConfirmModal(true)
            // User signed with no email
        } else if (patient.email === null) {
            setEmailAvailable(true)
            // User is signed in.
        } else {
            toggleModal(true)
        }
    }

    const toggleModal = (value) => {
        if (value === true) {
            setShowModal(true)
        } else {
            setCurrentPrice(null)
            setShowModal(false)
        }
    }

    const onSubmit = (date, time) => {
        const analytics = firebaseApp.analytics()
        analytics.logEvent(analytics.events.booking)

        history.push({
            pathname: '/paymentConfirm',
            state: { date, time, price: currentPrice, type: appointmentType, clinicId: selectedClinicId }
        })
    }
    const navigateToLogin = () => {
        history.push('/login')
    }

    console.log(doctor)
    if (!doctor || !doctor.clinics) {
        return <Loading />
    }

    return (
        <div className={styles['doctor-profile-container']}>
            {doctor.firstNameAr !== undefined && <SubHeader title={`دكتور ${doctor.firstNameAr} ${doctor.lastNameAr}`} />}

            <div className={styles['doctor-profile-content']}>
                <div className={`${styles['div']} ${styles['div1']}`}>
                    <DoctorInfoCard doctor={doctor} />
                </div>
                <div className={`${styles['div']} ${styles['div2']}`}>
                    <DoctorAbout doctor={doctor} />
                </div>
                <div className={`${styles['div']} ${styles['div3']}`}>
                    <DoctorServices doctor={doctor} />
                </div>
                {/* <div className={`${styles['div']} ${styles['div4']}`}>
                        <DoctorReviews doctor={doctor} />
                    </div> */}
                <div className={`${styles['div']} ${styles['div4']}`}>
                    <DoctorVideoCallCard
                        price={doctor.online}
                        days={doctor.onlineAvailableDays}
                        handleShowModal={(type, price, clinicId) => {
                            setModalData(type, price, clinicId)
                            handleShowModal()
                        }}
                    />
                </div>
                <div className={`${styles['div']} ${styles['div5']}`}>
                    {doctor.clinics.map((clinic) => (
                        <DoctorClinicCard
                            key={`clinic-${clinic.id}`}
                            clinic={clinic}
                            handleShowModal={(type, price, clinicId) => {
                                setModalData(type, price, clinicId)
                                handleShowModal()
                            }}
                        />
                    ))}
                </div>

                <div className={`${styles['div']} ${styles['div6']}`}>
                    <DoctorMessagesCard openRegisterModal={patientUid ? () => history.push('/chat') : () => setShowConfirmModal(true)} />
                </div>
            </div>

            <ModalView
                type='customView'
                customView={
                    <BookCard
                        center={true}
                        selectedAppointmentType={appointmentType}
                        selectedClinicId={selectedClinicId}
                        onSubmit={onSubmit}
                        patientUid={patientUid}
                    />
                }
                title='تأكيد الحجز'
                show={showModal}
                setShow={(value) => toggleModal(value)}
                btn={{ title: '' }}
            />

            <ModalView
                type='confirmation'
                show={showConfirmModal}
                setShow={(value) => setShowConfirmModal(value)}
                title='تأكيد'
                description='برجاء تسجل الدخول لكى تتمكن من حجز موعد او التواصل مع الطبيب'
                btn={{ title: 'تسجيل الدخول', action: () => navigateToLogin() }}
                onCancel={() => setShowConfirmModal(false)}
            />
            {emailAvailable && <EmailChecker skippable={false} setEmailAvailable={setEmailAvailable} nextAction={handleShowModal} />}
        </div>
    )
}

export default DoctorProfile
