/* eslint-disable no-useless-escape */
const Regex = {
    clinicPhone:
        /^(0(3|68|97|82|57|64|95|50|48|66|65|62|69|55|88|13|45|84|47|46|86|92|96|93|40|55|13)[0-9]{7}|02[0-9]{8}|0(11|12|10|15)[0-9]{8})$/,
    isoTime: /^[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z)$/,
    durationDay: /^(0|1|2|3|4|5|6)$/,
    clinicName: /^[a-zA-Z ]{3,15}$/,
    clinicAddress: /^[\s\S]{3,500}$/,
    numbers: /^[0-9]+$/,
    link: /^((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-:]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/,
    cellphone: /^0(11|12|10|15)[0-9]{8}$/,
    email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]{2,}.[A-Z]{2,4}$/i,
    date: /^(19|20)[0-9][0-9][-\\\\/. ](0[1-9]|1[012])[-\\\\/. ](0[1-9]|[12][0-9]|3[01])$/
}

export default Regex
