import React, { useEffect, useRef } from 'react'
import styles from './MedicalRecordCustomCard.module.scss'
import { P } from '@curawella/curawella-components'
import { getDate } from '../../../helpers/dateTime'
import MedicalRecordDetails from '../medical-record-details/MedicalRecordDetails'

const MedicalRecordCustomCard = ({ data, setActiveCard, activeId }) => {
    const active = activeId === data.id
    const ref = useRef(null)

    useEffect(() => {
        if (active && ref.current !== null) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
        }
    }, [active, activeId])

    return (
        <div className={styles.container} ref={ref}>
            <div
                className={`${styles['card']}  ${active ? styles['active'] : ''}`}
                onClick={setActiveCard}
            >
                {/* <div className={styles['icon-container']}>
                <Image loading='lazy' alt='icon' src={medicalIcon} className={styles['icon']} />
            </div> */}
                <div className={styles['dr-data']}>
                    <P type='p1' other='type'>
                        {data.diagnosis}
                    </P>
                    <P type='p1' other='date'>
                        {getDate(data.timeStamp)}
                    </P>
                </div>
            </div>

            {active && (
                <div className={styles.details}>
                    <MedicalRecordDetails activeCard={data.id} />
                </div>
            )}
        </div>
    )
}

export default MedicalRecordCustomCard
