import React from 'react'
import styles from './DoctorServiceCard.module.scss'
import { P, Gallery } from '@curawella/curawella-components'
import PropTypes from 'prop-types'
import { useState } from 'react'

const DoctorServiceCard = ({ service }) => {
    const [galleryImages, setGalleryImages] = useState([])

    if (!service) {
        return ''
    }

    const RenderGallery = () => {
        const arr = []
        console.log('service', service)
        service.images.forEach((Image) => {
            arr.push({
                original: Image.url,
                id: Image.id
            })
        })

        setGalleryImages(arr)
    }

    const handelClose = () => setGalleryImages([])

    return (
        <div className={styles['doctor-service-card-container']}>
            <P type='p2' other='serviceTitle' className={styles['title']}>
                {service.nameAr}
            </P>
            <P type='p3' other='price' className={styles['price']}>
                {service.price}
            </P>
            <P type='p2' other='content' className={`${styles['content']}`}>
                {service.detailsAr}
            </P>
            <div className={styles['images-card-container']}>
                {service.images.map((item, index) => (
                    <img
                        key={`service-${service.id}-img-${index}`}
                        className={styles['img']}
                        src={item.url}
                        alt={'service img'}
                        onClick={() => RenderGallery(item)}
                    />
                ))}
                {/* {<ImageCard render={RenderGallery} data={service.images} />} */}
            </div>
            {galleryImages.length > 0 && (
                <Gallery images={galleryImages} closeAction={handelClose} canRemove={false} />
            )}
        </div>
    )
}

export default DoctorServiceCard

const ImageCard = ({ data, render }) => {
    const renderThumb = (file) => {
        return <img className={styles['img']} src={file.url} alt='' onClick={() => render(file)} />
    }

    return data.map((item, index) => (
        <img
            key={`service-img-${index}`}
            className={styles['img']}
            src={item.url}
            alt=''
            onClick={() => render(item)}
        />
    ))

    // if (data.length === 0) {
    //     return ''
    // }

    // if (data.length < 2) {
    //     return <div className={styles['images-container']}>{renderThumb(data[0])}</div>
    // }
    // if (data.length === 2) {
    //     return (
    //         <>
    //             {renderThumb(data[0])}
    //             <div className={styles['rest-of-images-container']} onClick={() => render(data[0])}>
    //                 {renderThumb(data[1])}
    //             </div>
    //         </>
    //     )
    // }
    // if (data.length > 2) {
    //     return (
    //         <>
    //             {renderThumb(data[0])}
    //             <div className={styles['rest-of-images-container']} onClick={() => render(data[0])}>
    //                 <span>{data.length - 2}+</span>
    //                 {renderThumb(data[1])}
    //             </div>
    //         </>
    //     )
    // }
}

DoctorServiceCard.propTypes = {
    service: PropTypes.object
}
