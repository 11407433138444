import React from 'react'
import PropTypes from 'prop-types'
import styles from './PastTransactionCard.module.scss'
import { Image } from 'react-bootstrap'
import { P } from '@curawella/curawella-components'

import { getDate } from '../../../helpers/dateTime'

// const TRANSACTIONS_TYPES = {
//     FINISHED: 'FINISHED',
//     PAID: 'PAID',
//     NOT_PAID: 'NOT_PAID',
//     WAIT: 'WAIT'
// }
const getTransactionTitle = {
    APPOINTMENT: 'حجز موعد كشف',
    REVERSE: 'إعادة مبلغ',
    RECHARGE: 'شحن رصيد',
    MESSAGE: 'تكلفة رسالة'
}

const PastTransactionCard = ({ transactionType, date, price, drName }) => {
    PastTransactionCard.propTypes = {
        transactionType: PropTypes.any,
        date: PropTypes.string,
        price: PropTypes.string,
        drName: PropTypes.string
    }

    return (
        <div className={styles['past-transaction-card-container']}>
            <RenderCardIcon transactionType={transactionType} />
            <div className={styles['past-transaction-data']}>
                <P type='p1' other='title'>
                    {getTransactionTitle[transactionType]}
                </P>
                {transactionType === 'APPOINTMENT' && (
                    <P type='p1' other='dr-name'>
                        {drName}
                    </P>
                )}
                <P type='p1' other='date'>
                    {getDate(date)}
                </P>
            </div>

            <P type='p1' other='price'>
                {`${price / 100}`} ج.م
            </P>
        </div>
    )
}

export default PastTransactionCard

const RenderCardIcon = ({ transactionType }) => {
    if (transactionType === 'APPOINTMENT') {
        return (
            <Image
                src='/assets/wallet/icons/book.svg'
                loading='lazy'
                alt='transaction-type'
                className={styles['icon']}
            />
        )
    } else if (transactionType === 'RECHARGE')
        return (
            <Image
                src='/assets/wallet/icons/charge.svg'
                loading='lazy'
                alt='transaction-type'
                className={styles['icon']}
            />
        )
    else {
        return (
            <Image
                src='/assets/wallet/icons/reverse.svg'
                loading='lazy'
                alt='transaction-type'
                className={styles['icon']}
            />
        )
    }
}
