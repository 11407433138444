import React, { useState } from 'react'
import PropTypes from 'prop-types'

export const Context = React.createContext({})

const ContextProvider = ({ children }) => {
    ContextProvider.propTypes = {
        children: PropTypes.object
    }
    const [doctor, setDoctor] = useState({})
    const [patient, setPatient] = useState(undefined)
    const [ip, setIP] = useState('')

    return (
        <Context.Provider
            value={{
                doctor,
                setDoctor,
                patient,
                setPatient,
                patientUid: patient ? patient.uid : null,
                ip,
                setIP
            }}
        >
            {children}
        </Context.Provider>
    )
}

export default ContextProvider
