import React from 'react'
import styles from './DoctorMessagesCard.module.scss'
import CardTitle from '../card-title/CardTitle'
import { P, GradientBtn } from '@curawella/curawella-components'

const DoctorMessagesCard = ({ openRegisterModal }) => {
    return (
        <div className={styles['doctor-messages-card']}>
            <CardTitle title='رسالة' icon='/assets/doctor-profile/icons/chat.svg' />
            <div className={styles.content}>
                <P type='p1'>تواصل مع الدكتور من خلال الرسائل</P>
                <GradientBtn
                    title='ارسال'
                    align='left'
                    action={openRegisterModal}
                    type='gradient'
                />
            </div>
        </div>
    )
}

export default DoctorMessagesCard
