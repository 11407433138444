import React from 'react'
import PropTypes from 'prop-types'
import styles from './PaymentMethod.module.scss'
import { Image } from 'react-bootstrap'
import { P, GradientBtn, OutlinedInput } from '@curawella/curawella-components'
import CardItem from '../../../components/card-item/CardItem'

const PaymentMethod = ({
    title,
    type,
    togglePayment,
    handleTogglePayment,
    savedCards,
    disabled,
    selectedCard,
    setSelectedCard,
    phoneNumber,
    phoneError,
    setPhoneNumber,
    handleSubmitPayment,
    handlePayWithNewCard,
    loading
}) => {
    PaymentMethod.propTypes = {
        title: PropTypes.string,
        type: PropTypes.string,
        togglePayment: PropTypes.string,
        handleTogglePayment: PropTypes.func,
        savedCards: PropTypes.array,
        disabled: PropTypes.bool,
        selectedCard: PropTypes.string,
        setSelectedCard: PropTypes.func,
        phoneNumber: PropTypes.string,
        setPhoneNumber: PropTypes.func,
        handleSubmitPayment: PropTypes.func,
        handlePayWithNewCard: PropTypes.func,
        loading: PropTypes.any
    }
    // console.log('type: ', type);
    return (
        <>
            <div className={`${styles['payment-method-container']}  ${togglePayment === type && styles['active']}`}>
                <div className={`${styles['payment-method-content']}`} onClick={() => handleTogglePayment(type)}>
                    <P type='p1'>{title}</P>
                    <div className={styles['icons-container']}>
                        <RenderIcons type={type} />
                    </div>
                </div>

                {togglePayment === type && (
                    <div className={styles['saved-cards-container']}>
                        <RenderPaymentDetails
                            type={type}
                            savedCards={savedCards}
                            disabled={disabled}
                            selectedCard={selectedCard}
                            setSelectedCard={setSelectedCard}
                            phoneNumber={phoneNumber}
                            phoneError={phoneError}
                            setPhoneNumber={setPhoneNumber}
                            handlePayWithNewCard={handlePayWithNewCard}
                            loading={loading}
                        />
                        {(!savedCards || (savedCards && savedCards.length > 0)) && (
                            <GradientBtn
                                title={loading ? 'برجاء الأنتظار...' : 'ادفع'}
                                type='gradient'
                                disabled={disabled || loading}
                                action={() => handleSubmitPayment(type)}
                            />
                        )}
                    </div>
                )}
            </div>
        </>
    )
}

export default PaymentMethod

const RenderPaymentDetails = ({
    type,
    savedCards,
    selectedCard,
    setSelectedCard,
    phoneNumber,
    phoneError,
    setPhoneNumber,
    handlePayWithNewCard,
    loading
}) => {
    if (type === 'TOKEN') {
        return (
            <>
                {savedCards &&
                    savedCards.map((card, index) => (
                        <CardItem
                            card={card}
                            canRemove={false}
                            key={index}
                            hover={true}
                            action={() => setSelectedCard(card.token)}
                            active={selectedCard === card.token ? true : false}
                        />
                    ))}

                <GradientBtn
                    title={loading ? 'برجاء الأنتظار...' : 'الدفع ببطاقة جديدة'}
                    type='solidWhite'
                    hover='light'
                    align='right'
                    className={styles['add-card-btn']}
                    action={handlePayWithNewCard}
                    disabled={loading}
                />
            </>
        )
    } else if (type === 'WALLET') {
        return (
            <>
                <P type='p1' other='description'>
                    ادخل رقم الهاتف وسيظهر لك رقم المحفظة بعد تأكيد طريقة الدفع بالضغط علي زر ادفع
                </P>

                <OutlinedInput title='رقم الهاتف' error={phoneError} value={phoneNumber} setValue={setPhoneNumber} />
            </>
        )
    } else if (type === 'AGGREGATOR') {
        return (
            <P type='p1' other='description'>
                سيظهر لك رقم أمان او مصاري بعد تأكيد طريقة الدفع بالضغط علي زر ادفع
            </P>
        )
    } else if (type === 'VAL_U') {
        return (
            <P type='p1' other='description'>
                سيظهر لك رقم دفع فاليو بعد تأكيد طريقة الدفع بالضغط علي زر ادفع
            </P>
        )
    } else if (type === 'CASH_BOX') {
        return (
            <P type='p1' other='description'>
                سيتم الدفع من خلال الرصيد المتواجد في محفظتي بعد تأكيد طريقة الدفع بالضغط علي زر ادفع
            </P>
        )
    } else if (type === 'ONSITE') {
        return (
            <P type='p1' other='description'>
                يمكنك الدفع كاش عند وصولك الي عيادة الدكتور بعد تأكيد طريقة الدفع بالضغط علي زر ادفع
            </P>
        )
    }
}

const RenderIcons = ({ type }) => {
    if (type === 'TOKEN' || type === 'CARD') {
        return (
            <>
                <Image src='/assets/payment/icons/visa.svg' alt='visa-icon' className={styles['icon']} />
                <Image src='/assets/payment/icons/master.svg' alt='master-icon' className={styles['icon']} />
            </>
        )
    } else if (type === 'AGGREGATOR') {
        return (
            <>
                <Image src='/assets/payment/icons/masary.jpg' alt='masary-icon' className={styles['icon']} />
                <Image src='/assets/payment/icons/aman.png' alt='aman-icon' className={styles['icon']} />
            </>
        )
    } else if (type === 'WALLET') {
        return (
            <>
                <Image src='/assets/payment/icons/vodafone.png' alt='vodafone-icon' className={styles['icon']} />
                <Image src='/assets/payment/icons/etisalat.png' alt='etisalat-icon' className={styles['icon']} />
                <Image src='/assets/payment/icons/orange.png' alt='orange-icon' className={styles['icon']} />
            </>
        )
    } else if (type === 'VAL_U') {
        return <Image src='/assets/payment/icons/valu.png' alt='valu-icon' className={styles['icon']} />
    } else if (type === 'CASH_BOX') {
        return <Image src='/assets/payment/icons/wallet.svg' alt='wallet-icon' className={styles['icon']} />
    } else if (type === 'ONSITE') {
        return <Image src='/assets/payment/icons/cash.svg' alt='wallet-icon' className={styles['icon']} />
    }
    return ''
}
