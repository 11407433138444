import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './BlogsLanding.module.scss'
import { Link } from 'react-router-dom'
import { GradientBtn, SectionTitle } from '@curawella/curawella-components'
import BlogCard from '../../blogs/blog-card/BlogCard'
import { doctorUid } from '../../../constants/AppConstants'
import useFetch from '../../../hooks/useFetch'

const BlogsLanding = () => {
    const [blogsData, blogsLoading, blogsError, blogsExecute] = useFetch()
    let blogs = blogsData?.results
    if (blogs && blogs.pinned) {
        blogs = [...blogs.pinned, ...blogs.unpinned]
    }

    useEffect(() => {
        blogsExecute('get', `v2/patient/blogs/?doctorUid=${doctorUid}&quantity=3`)
    }, [blogsExecute])

    useEffect(() => {
        if (blogsError) {
            console.log('blogsError', blogsError)
        }
    }, [blogsError])

    if (!blogs || blogsLoading || blogs.length === 0) {
        return ''
    }

    return (
        <div className={styles['blogs-landing-container']}>
            <SectionTitle title='مقالات' />
            <div className={styles['blogs-cards-container']}>
                {blogs.map((blog, index) => (
                    <BlogCard blog={blog} key={index} />
                ))}
            </div>
            <Link to='/blogs'>
                <GradientBtn title='عرض الكل' type='gradient' className={styles['view-all-btn']} />
            </Link>
        </div>
    )
}

export default BlogsLanding

BlogsLanding.propTypes = {
    blogs: PropTypes.object
}
