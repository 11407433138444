import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import { doctorLogo, doctorName } from '../constants'
import { Context } from '../context/Context'
import CommonRouter from './common'
import PATHS from './paths'
const HomeClinic = lazy(() => import('../lib/pages/home-clinic/HomeClinic'))
const Login = lazy(() => import('../lib/pages/login/Login'))
const Registration = lazy(() => import('../lib/pages/registration/Registration'))
const ForgetPassword = lazy(() => import('../lib/pages/forget-password/ForgetPassword'))
const ContactUs = lazy(() => import('../lib/pages/contact-us/ContactUs'))
const Blogs = lazy(() => import('../lib/pages/blogs/Blogs'))
const Blog = lazy(() => import('../lib/pages/blog/Blog'))
const DoctorProfile = lazy(() => import('../lib/pages/doctor-profile/DoctorProfile'))
const Privacy = lazy(() => import('../lib/pages/privacy/Privacy'))
const Loading = lazy(() => import('../lib/components/loading/Loading'))


type PublicRouterProps = {
    initialPath: string
    context: React.Context<{}>
}
/**
 *
 * @param {{
 * initialPath: string
 * }} Props
 * @returns
 */
const PublicRouter = ({ initialPath }: PublicRouterProps) => {
    return (
        <CommonRouter
            setEmailAvailable={() => {}}
            initialPath={initialPath}
            isAuthenticated={false}
            custom={true}
            doctorName={doctorName}
            doctorLogo={doctorLogo}
            emailAvailable={false}
        >
            <Route exact path='PATHS.LOADING' component={() => <Loading />} />
            <Route exact path={PATHS.home} component={() => <HomeClinic />} />
            <Route exact path={PATHS.login} component={Login} />
            <Route exact path={PATHS.register} component={Registration} />
            <Route exact path={PATHS.forgetPassword} component={ForgetPassword} />
            <Route exact path={PATHS.contactUs} component={() => <ContactUs Context={Context} />} />
            <Route path={PATHS.doctorProfile} component={() => <DoctorProfile Context={Context} />} />
            <Route exact path={PATHS.blogs} component={Blogs} />
            <Route path={PATHS.blog} component={Blog} />
            <Route path={PATHS.privacy} component={Privacy} />
        </CommonRouter>
    )
}
export default PublicRouter
