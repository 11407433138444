import React from 'react'
import { P } from '@curawella/curawella-components'
import styles from './currentPromo.module.scss'
import { getDate } from '../../../helpers/dateTime'
const CurrentPromo = ({ promoCodesData }) => {
    if (!promoCodesData.results.active) {
        return ''
    }
    console.log('promoCodesData', promoCodesData)
    return (
        <div className={styles['current-promo-container']}>
            <P type='title' other='title'>
                البروموكود الحالي
            </P>
            <P type='p1' other='percent'>
                الخصم:
                {promoCodesData && promoCodesData.results.type === 'TOTAL' ? (
                    <span> سيتم دفع {promoCodesData && promoCodesData.results.amount} فقط </span>
                ) : promoCodesData.results.type === 'PERCENTAGE' ? (
                    <span>
                        بنسبة {promoCodesData && promoCodesData.results.amount}% من سعر حجز الموعد
                    </span>
                ) : promoCodesData.results.type === 'FIXED' ? (
                    <span>
                        سيتم خصم {promoCodesData && promoCodesData.results.amount} من سعر حجز الموعد{' '}
                    </span>
                ) : (
                    ''
                )}
                {/* <span> بنسبة {promoCodesData && promoCodesData.results.amount}% من سعر حجز الموعد</span> */}
            </P>
            <P type='p1' other='expire'>
                تاريخ الانتهاء:
                <span>{getDate(promoCodesData.results.deadlineTimestamp)}</span>
            </P>
            <P type='p1' other='number'>
                متاح للاستخدام: <span> {promoCodesData.results.maxPatientReservations} مرات</span>
            </P>
        </div>
    )
}

export default CurrentPromo
