// import { modalAlert } from '@curawella/admin-package'
import { BackendCall } from 'react-usefetch-models'
import { API } from '../../constants'
import firebaseApp from '../constants/firebaseApp'

//  API Backend base url
const baseApiUrl = API

class Backend {
    // config!: BackendCall
    authToken = ''
    config

    constructor() {
        this.initializeBackend(undefined)
    }

    updateAuthToken() {
        // this.authToken = authToken
        this.initializeBackend()
    }

    async initializeBackend(authToken) {
        // Default Headers containing headers to be set by default to any request unless it overwritten
        const defaultHeaders = { 'Content-Type': 'application/json' }

        const fbUser = firebaseApp.auth().currentUser

        if (fbUser !== null) {
            defaultHeaders['auth-token'] = await fbUser.getIdToken()
        } else {
            defaultHeaders['auth-token'] = undefined
        }

        if (!baseApiUrl) {
            throw new Error('Backend API NOT FOUND')
        }

        /**
         * Function to be executed after the success and do whatever you want
         * @param {any} res
         */
        async function defaultSuccessFunction(res) {
            return new Promise((resolve, reject) => {
                let d
                if (res.success) {
                    console.log('success: ', res)
                    if (res.data) {
                        d = res.data
                    } else if (res.results) {
                        d = res.results
                    } else {
                        d = res
                    }
                    resolve(d)
                } else {
                    console.log('failed: ', res.code)
                    reject(res.code)
                    // throw new Error(code);
                }
            })
        }

        /**
         * Function to be executed after the catching the error and do whatever you want
         * @param {any} err the error as a first and only parameter
         * @return {any} Whatever u want to be in the rejected state
         */
        async function defaultErrorFunction(err) {
            // if (err === 'UNAUTHORIZED') {
            //     modalAlert({
            //         type: 'ERROR',
            //         title: 'Unauthorized',
            //         description:
            //             'You are unauthorized to use this functionality, please check your authorization with your service provider'
            //     })
            // } else if (err === 'SESSION_EXPIRED') {
            //     modalAlert({
            //         type: 'ERROR',
            //         title: 'Session expired',
            //         description: 'Your session has expired and you must login again',
            //         confirmButton: { text: 'Login', action: () => location.reload() }
            //     })
            // }

            return err
        }

        this.config = new BackendCall(
            baseApiUrl,
            defaultHeaders,
            defaultSuccessFunction,
            defaultErrorFunction
            // {promiseReject, promiseResolve}
        )
    }
}

export default new Backend()
