import React, { useState } from 'react'
import PropTypes from 'prop-types'

export const Context = React.createContext({})

const ContextProvider = ({ children }) => {
    ContextProvider.propTypes = {
        children: PropTypes.object
    }
    const [doctor, setDoctor] = useState({})
    const [patientUid, setPatientUid] = useState(null)


    return (
        <Context.Provider
            value={{
                doctor: doctor,
                setDoctor: setDoctor,
                patientUid: patientUid,
                setPatientUid: setPatientUid
            }}>
            {children}
        </Context.Provider>
    )
}

export default ContextProvider
