import React from 'react'
import { P } from '@curawella/curawella-components'
import PropTypes from 'prop-types'
import styles from './CurrentState.module.scss'

const CurrentState = ({ data }) => {
    CurrentState.propTypes = {
        data: PropTypes.object
    }
    return (
        <div className={styles['current-state-container']}>
            <div className={styles['dr-data']}>
                <P type='p1' other='dr-name'>
                    {`${data.firstNameAr} ${data.lastNameAr} `}
                </P>
                <P type='p2' other='dr-level'>
                    {data.level} - {data.specialtyAr}
                </P>
            </div>
            <P type='p1' other='date'>
                {data.date}
            </P>
        </div>
    )
}

export default CurrentState
