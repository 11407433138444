import backendConfig from './backendConfig'

class Notification {
    static getNotification() {
        return backendConfig.config.getData({
            endPoint: 'v2/user/notification/:uid'
        })
    }
}

export default Notification
