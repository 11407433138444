import React, { useCallback, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'
import imageCompression from 'browser-image-compression'
import { P, GradientBtn, ModalView, SubHeader } from '@curawella/curawella-components'
import moment from 'moment'

import Loading from '../../components/loading/Loading'
import useFetch from '../../hooks/useFetch'
import CustomInputComponent from './CustomInputComponent'
import { appId } from '../../constants/AppConstants'

import styles from './PatientProfile.module.scss'
import PersonalInfo from './personal-info/PersonalInfo'
import firebaseApp from '../../constants/firebaseApp'
import Regex from '../../constants/Regex'
import CardItem from '../../components/card-item/CardItem'

const options = { maxSizeMB: 1, maxWidthOrHeight: 1920, useWebWorker: true }
const logOut = (
    <>
        <SVG src='/assets/patient-profile/icons/logout.svg' className={styles['logout-icon']} /> تسجيل الخروج
    </>
)

const PatientProfile = ({ custom, Context }) => {
    PatientProfile.propTypes = {
        custom: PropTypes.bool
    }
    const [user, setUser] = useState({})
    const [userErrors, setUserErrors] = useState({
        bd: '',
        country: '',
        city: '',
        gender: '',
        weight: '',
        height: ''
    })

    const [savedCards, setSavedCards] = useState([])
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [showInputModal, setShowInputModal] = useState(false)
    const [showAddSavedCardModal, setShowAddSavedCardModal] = useState(false)
    const [customInputs, setCustomInputs] = useState({})
    const [customInputsErrors, setCustomInputsErrors] = useState({
        phone: null,
        email: null,
        newPassword: null,
        password: null
    })
    const [modalType, setModalType] = useState(null)

    const [userData, userLoading, userError, userExecute] = useFetch()
    const [savedCardsData, savedCardsLoading, savedCardsError, savedCardsExecute] = useFetch()
    const [deleteSavedCardsData, deleteSavedCardsLoading, deleteSavedCardsError, deleteSavedCardsExecute] = useFetch()
    const [updateUserData, updateUserLoading, updateUserError, updateUserExecute] = useFetch()
    const [updateUserCustomData, updateUserCustomLoading, updateUserCustomError, updateUserCustomExecute] = useFetch()
    const [addSavedCardData, addSavedCardLoading, addSavedCardError, addSavedCardExecute] = useFetch()
    const [updateAvatarData, updateAvatarLoading, updateAvatarError, updateAvatarExecute] = useFetch()
    const [addSavedCardUrl, setAddSavedCardUrl] = useState(null)
    const [showConfirmationModal, setShowConfirmationModal] = useState(null)
    const [activeCard, setActiveCard] = useState(null)
    const [newAvatar, setNewAvatar] = useState(null)

    const { patientUid } = useContext(Context)

    useEffect(() => {
        const analytics = firebaseApp.analytics()
        analytics.screenView(analytics.screens.patientProfile)
    }, [])

    const getUserData = useCallback(() => {
        userExecute('get', `v2/patient/profile/${patientUid}`)
    }, [patientUid, userExecute])

    const getSavedCards = useCallback(() => {
        savedCardsExecute('get', `v2/patient/payment/cards/${patientUid}`)
    }, [patientUid, savedCardsExecute])

    const addSavedCard = () => {
        const analytics = firebaseApp.analytics()
        analytics.logEvent(analytics.events.addCard)

        addSavedCardExecute('post', `v2/patient/payment/cards/${patientUid}`, { appId: appId })
    }

    useEffect(() => {
        if (patientUid) {
            getUserData()
            getSavedCards()
        }
    }, [getSavedCards, getUserData, patientUid])

    useEffect(() => {
        if (userData && !userError) {
            console.log('user data:  ', userData.results)
            setUser(userData.results)
        }
    }, [userData, userError])

    useEffect(() => {
        if (savedCardsData && !savedCardsError) {
            setSavedCards(savedCardsData.results)
            console.log('saved cards data:  ', savedCardsData)
        }
    }, [savedCardsData, savedCardsError])

    useEffect(() => {
        if (updateUserData) {
            setShowSuccessModal(true)
            getUserData()
        }
    }, [updateUserData, getUserData])

    useEffect(() => {
        if (updateUserError) {
            console.log('error while save patient profile ', updateUserError.response)
        }
    }, [updateUserError])

    useEffect(() => {
        if (updateUserCustomData && !updateUserCustomError) {
            console.log('updateUserCustomData: ', updateUserCustomData)
            toggleCustomUserDataModal(false)
            setShowSuccessModal(true)
            getUserData()
        }
    }, [updateUserCustomData, getUserData, updateUserCustomError])

    useEffect(() => {
        if (updateUserCustomError) {
            const errorCode = updateUserCustomError.code
            let error = {}
            if (errorCode === 'auth/invalid-email') {
                error = { email: 'بريد الإكترونى غير صحيح' }
            } else if (errorCode === 'auth/email-already-exists') {
                error = { email: 'البريد الإلكتروني مستخدم' }
            } else if (errorCode === 'auth/invalid-phone-number') {
                error = { phone: 'رقم هاتف غير صحيح' }
            } else if (errorCode === 'auth/phone-number-already-exists') {
                error = { phone: 'رقم هاتف مستخدم من قبل' }
            } else if (errorCode === 'auth/wrong-password') {
                error = { password: 'كلمة السر غير صحيحة' }
            } else if (errorCode === 'auth/invalid-password') {
                error = { newPassword: 'كلمة السر يجب ان تكون على الاقل 6 أحرف او أرقام' }
            }

            setCustomInputsErrors(error)
        }
    }, [updateUserCustomError])

    useEffect(() => {
        if (deleteSavedCardsData && !deleteSavedCardsError) {
            setShowSuccessModal(true)
            toggleConfirmationModal(false)
            getUserData()
            getSavedCards()
        }
    }, [deleteSavedCardsData, deleteSavedCardsError, getSavedCards, getUserData])

    useEffect(() => {
        console.log('addSavedCardData: ', addSavedCardData)
        if (addSavedCardData) {
            setAddSavedCardUrl(addSavedCardData.data.url)
            setShowAddSavedCardModal(true)
        }
    }, [addSavedCardData])

    const handleLogout = () => {
        const analytics = firebaseApp.analytics()
        analytics.logEvent(analytics.events.logout)

        firebaseApp
            .auth()
            .signOut()
            .catch((err) => console.log('error while logout...', err))
    }
    const deleteCard = () => {
        const analytics = firebaseApp.analytics()
        analytics.logEvent(analytics.events.removeCard)

        deleteSavedCardsExecute('delete', `v2/patient/payment/cards/${patientUid}/${activeCard}`)
    }

    const checkCustomValues = (valueToBeChecked) => {
        let check = true
        let errors = { phone: null, email: null, newPassword: null, password: null }

        if (valueToBeChecked === 'phone' && !customInputs.phone) {
            errors = { ...errors, phone: 'مطلوب' }
            check = false
        } else if (valueToBeChecked === 'email' && !customInputs.email) {
            errors = { ...errors, email: 'مطلوب' }
            check = false
        } else if (valueToBeChecked === 'newPassword' && !customInputs.newPassword) {
            errors = { ...errors, newPassword: 'مطلوب' }
            check = false
        }
        if (!customInputs.password) {
            errors = { ...errors, password: 'مطلوب' }
            check = false
        }

        setCustomInputsErrors(errors)
        return check
    }

    const submitUserData = () => {
        const { gender, country, city, bd, height, weight } = user
        const { isValid, errors } = validateInputs(country, city, bd, height, weight, false)
        setUserErrors(errors)

        if (!isValid) {
            return
        }

        const body = {
            gender: gender === '1' ? true : false,
            bd: bd ? bd : undefined,
            country,
            city: city && city !== 'اختار' ? city : undefined,
            height: height ? height : undefined,
            weight: weight ? weight : undefined
        }

        updateUserExecute('put', `v2/patient/profile/${patientUid}`, body)
    }

    const submitCustomUserData = (valueToBeChecked) => {
        if (checkCustomValues(valueToBeChecked)) {
            const type = valueToBeChecked === 'newPassword' ? 'password' : valueToBeChecked

            updateUserCustomExecute('put', `v2/patient/profile/${patientUid}/${type}`, {
                [valueToBeChecked]: customInputs[valueToBeChecked],
                password: customInputs.password
            })
        }
    }

    const handleInputClick = (name) => {
        const valuesRequireModal = ['phone', 'email', 'newPassword']
        if (valuesRequireModal.includes(name)) {
            setModalType(name)
            setCustomInputs({ phone: user.phone, email: user.email })

            toggleCustomUserDataModal(true)
        }
    }

    const toggleCustomUserDataModal = (value) => {
        if (value === true) {
            setShowInputModal(true)
        } else {
            setShowInputModal(false)
            setCustomInputsErrors({
                phone: null,
                email: null,
                newPassword: null,
                password: null
            })

            setCustomInputs({})
        }
    }

    const toggleConfirmationModal = (value, cardID) => {
        if (value) {
            setActiveCard(cardID)
        } else {
            setActiveCard(null)
        }

        setShowConfirmationModal(value)
    }

    const upload = useCallback(
        (file) => {
            return new Promise((resolve, reject) => {
                const storageRef = firebaseApp.storage().avatar(patientUid)
                const uploadTask = storageRef.put(file)

                uploadTask.on(
                    'state_changed',
                    () => {},
                    (error) => reject('error in firebase upload: ', error.message),
                    () => uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => resolve(downloadURL))
                )
            })
        },
        [patientUid]
    )

    const updateAvatar = useCallback(async () => {
        try {
            imageCompression(newAvatar, options)
                .then((compressed) => upload(compressed))
                .then((url) => updateAvatarExecute('put', `v2/patient/profile/${patientUid}/avatar`, { avatar: url }))
        } catch {
            setUser((prev) => ({ ...prev, avatar: prev.oldAvatar }))
        }
    }, [newAvatar, upload, updateAvatarExecute, patientUid])

    useEffect(() => {
        if (updateAvatarData) {
            console.log('updateAvatarData: ', updateAvatarData)
            getUserData()
            setNewAvatar(null)
        }
    }, [updateAvatarData, getUserData])

    useEffect(() => {
        if (updateAvatarError) {
            console.log('******error while update patient avatar****** ', updateAvatarError)
        }
    }, [updateAvatarError])

    useEffect(() => {
        if (newAvatar) {
            setUser((prev) => ({
                ...prev,
                oldAvatar: prev.avatar,
                avatar: '/assets/patient-profile/icons/loading.svg'
            }))
            updateAvatar()
        }
    }, [newAvatar, updateAvatar])

    const handleAnalytics = () => {
        const analytics = firebaseApp.analytics()
        analytics.logEvent(analytics.events.becomeADoctor)
    }

    if (userLoading || updateUserLoading || updateAvatarLoading) {
        return <Loading />
    }
    return (
        <div className={styles['patient-profile-container']}>
            <SubHeader title='الملف الشخصي' />

            <div className={styles['patient-profile-content']}>
                <div className={styles['data']}>
                    <PersonalInfo
                        user={user && user}
                        type='patientProfile'
                        setUser={(x) => setUser(x)}
                        userErrors={userErrors && userErrors}
                        handleClick={handleInputClick}
                        newAvatar={newAvatar}
                        setNewAvatar={setNewAvatar}
                    />
                    <GradientBtn className={styles.save} title='حفظ التعديلات' type='gradient' action={submitUserData} />
                </div>
                <RenderRest
                    custom={custom}
                    savedCards={savedCards}
                    deleteCard={toggleConfirmationModal}
                    addSavedCard={addSavedCard}
                    addSavedCardLoading={addSavedCardLoading}
                />
                <a href='https://vcliniq.app' target='_blank' onClick={handleAnalytics}>
                    <GradientBtn title='اصبح احد اطبائنا' type='gradient' hover='gradient' />
                </a>
                <GradientBtn title={logOut} type='lightRed' hover='redGradient' action={() => handleLogout()} />
            </div>

            <ModalView
                show={showConfirmationModal}
                setShow={(value) => toggleConfirmationModal(value)}
                type='confirmation'
                title='تأكيد'
                description='متأكد من انك تريد حذف البطاقة ؟'
                btn={{
                    title: deleteSavedCardsLoading ? 'جار الأرسال' : 'حذف',
                    action: () => deleteCard(),
                    disabled: deleteSavedCardsLoading ? true : false
                }}
                onCancel={() => toggleConfirmationModal(false)}
            />

            {showSuccessModal && (
                <ModalView
                    show={showSuccessModal}
                    setShow={(value) => setShowSuccessModal(value)}
                    type='success'
                    title='تأكيد'
                    description='تم تنفيذ طلبك بنجاح'
                    btn={{ title: 'اغلاق', action: () => setShowSuccessModal(false) }}
                />
            )}

            {showInputModal && (
                <ModalView
                    show={showInputModal}
                    setShow={(value) => toggleCustomUserDataModal(value)}
                    type='custom'
                    title='تعديل البيانات'
                    description='من فضلك قم بكتابة كل البيانات'
                    btn={{ title: 'ارسال', action: () => toggleCustomUserDataModal(false) }}
                    customView={
                        <CustomInputComponent
                            customInputs={customInputs}
                            setCustomInputs={setCustomInputs}
                            customInputsErrors={customInputsErrors}
                            submitCustomUserData={submitCustomUserData}
                            type={modalType}
                            loading={updateUserCustomLoading}
                        />
                    }
                />
            )}

            {showAddSavedCardModal && (
                <ModalView
                    show={showAddSavedCardModal}
                    setShow={(value) => setShowAddSavedCardModal(value)}
                    type='customViewIframeLarge'
                    title='اضافة بطاقة جديدة'
                    btn={{ title: 'ارسال', action: () => toggleCustomUserDataModal(false) }}
                    customView={
                        <iframe
                            className={styles.iframe}
                            src={addSavedCardUrl}
                            sandbox='allow-same-origin allow-scripts allow-forms allow-modals allow-popups allow-top-navigation'
                            width='100%'
                            height='875px'
                        />
                    }
                />
            )}
        </div>
    )
}

export default PatientProfile

const RenderRest = ({ custom, savedCards, deleteCard, addSavedCard, addSavedCardLoading }) => {
    if (custom) {
        return (
            <div className={`${styles['rest-container']} ${styles['center']}`}>
                <div className={styles['saved-cards-container']}>
                    <P type='title' other='title'>
                        بطاقات الائتمان المسجلة
                    </P>

                    {savedCards.length > 0 ? (
                        savedCards.map((card, index) => <CardItem card={card} canRemove={true} key={index} deleteCard={deleteCard} />)
                    ) : (
                        <P type='p1'>لا يوجد بطاقات مسجلة فى حسابك</P>
                    )}

                    <GradientBtn
                        title={addSavedCardLoading ? 'برجاء الأنتظار...' : '+ إضافة بطاقة جديدة'}
                        type='outLinedPrimary'
                        hover='gradient'
                        align='right'
                        action={addSavedCard}
                        disabled={addSavedCardLoading ? true : false}
                    />
                </div>
            </div>
        )
    }
    return (
        <div className={styles['rest-container']}>
            <div className={styles['fav-doctors-container']}>
                <P type='title' other='title'>
                    اطبائى المفضلين
                </P>
                {/* to do when doctor have a reviews */}
                {/* 
                <div className={styles['fav-doctors-cards-container']}>
                    {favDoctors.map((doctor, index) => (
                        <div className={styles['fav-doctors-card-container']} key={index}>
                            <Image loading='lazy' className={styles['avatar']} src={avatar} />
                            <P type='title' other='dr-name'>
                                د/ {doctor.name}
                            </P>
                            <P type='p1' other='level'>
                                {doctor.level} - {doctor.specialty}
                            </P>
                            <P type='p2' other='reviews'>
                                <Image
                                    src={starIcon}
                                    loading='lazy'
                                    alt='star'
                                    className={styles['star-icon']}
                                />
                                ({doctor.rate}) - {doctor.reviews} تقيم
                            </P>
                        </div>
                    ))}
                </div> */}
            </div>
            <div className={styles['saved-cards-container']}>
                <P type='title' other='title'>
                    بطاقات الائتمان المسجلة
                </P>

                {savedCards.map((card, index) => (
                    <CardItem card={card} canRemove={true} key={index} hover={false} />
                ))}

                <GradientBtn
                    title={addSavedCardLoading ? 'برجاء الأنتظار...' : '+ إضافة بطاقة جديدة'}
                    type='outLinedPrimary'
                    hover='gradient'
                    align='right'
                    action={addSavedCard}
                    disabled={addSavedCardLoading ? true : false}
                />
            </div>
        </div>
    )
}

/**
 *
 * @param {string} country
 * @param {string} city
 * @param {string} bd
 * @param {string} height
 * @param {string} weight
 * @returns {{isValid: boolean, errors: Object}}
 */
function validateInputs(country, city, bd, height, weight) {
    let isValid = true
    const errors = { country: '', city: '', bd: '', height: '', weight: '' }

    if (bd && !Regex.date.test(bd)) {
        errors.bd = 'تاريخ الميلاد غير صحيح'
        isValid = false
    } else if (bd && moment().isBefore(moment(bd))) {
        errors.bd = 'لا يمكن تحديد تاريخ الميلاد في المستقبل'
        isValid = false
    }

    if (!country || country.localeCompare('اختار') === 0) {
        errors.country = 'اختر الدولة'
        isValid = false
    }

    if (height && (height > 250 || height < 30)) {
        errors.height = 'أدخل طول صحيح'
        isValid = false
    }

    if (weight && (weight > 250 || weight < 10)) {
        errors.weight = 'أدخل وزن صحيح'
        isValid = false
    }

    return { isValid, errors }
}
