import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './PastTransactions.module.scss'
import PastTransactionCard from './PastTransactionCard'
import Pagination from '../../../components/pagination/pagination'
const PastTransactions = ({ pastTransactionsData }) => {
    const [pages, setPages] = useState({})

    if (!pastTransactionsData) {
        return ''
    }

    return (
        <div className={styles['past-transactions-container']}>
            <h3>العمليات السابقة</h3>
            <div className={styles['past-transactions-content']}>
                {pastTransactionsData.slice(pages.min, pages.max).map((transaction, index) => (
                    <PastTransactionCard
                        key={index}
                        transactionType={transaction.type}
                        drName={`${transaction.firstName} ${transaction.lastName}`}
                        date={transaction.paymentTime}
                        price={transaction.amount}
                    />
                ))}

                <Pagination setPages={setPages} data={pastTransactionsData} />
            </div>
        </div>
    )
}
PastTransactions.propTypes = {
    pastTransactionsData: PropTypes.array
}

export default PastTransactions
