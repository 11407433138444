import React from 'react'
import PropTypes from 'prop-types'
import styles from './NavLinks.module.scss'
import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const NavLinks = ({ links, margin, activePath, whiteLinks, type }) => {
    return (
        <Nav className={`${styles.nav}  ${margin} `} activeKey={activePath}>
            {links.map((link, index) => (
                <Nav.Link
                    key={index}
                    as={Link}
                    to={link.link}
                    className={`${whiteLinks ? styles['white'] : ''} ${
                        activePath === link.link ? styles['active'] : ''
                    }`}
                    eventKey={index}
                >
                    {link.title}
                </Nav.Link>
            ))}
        </Nav>
    )
}

export default NavLinks

NavLinks.propTypes = {
    links: PropTypes.array,
    margin: PropTypes.string,
    activePath: PropTypes.string,
    whiteLinks: PropTypes.bool,
    type: PropTypes.string
}
